




































































































































import _ from 'lodash';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { v4 as uuidv4 } from 'uuid';
import { CommentType } from '@/types/CommentType';
import {
    ALLOWED_IMAGES_TYPES,
    AmplitudeEvent,
    BusEvent,
    LOCAL_STORAGE_DRAFT_KEY,
} from '@/constants';
import { Issue, Project } from '@/models';
import { eventBus } from '@/services/eventBus';
import { amplitudeLog, isMobile } from '@/services';
import { shortenString } from '@/services/Strings';
import IconSvg24 from '@/components/common/icon/IconSvg24.vue';
import ImageViewer from '@/components/project/issueTracker/ImageViewer.vue';
import WsTextarea from '@/components/common/WsTextarea.vue';

const draftRow = 'text';
const draftUuidRow = 'uuid';

const AttachType = {
    null: null,
    files: 0,
    photo360: 1,
};

const Capture = {
    null: null,
    environment: 'environment',
};

@Component({
    components: {
        IconSvg24,
        WsTextarea,
        ImageViewer,
    },
})

export default class IssueCommentAdd extends Vue {
    public readonly AttachType = AttachType;
    public readonly shortenString = shortenString;

    public message = '';
    public isOpenAttachMenu = false;
    public attachType: null | number = AttachType.null;
    public defaultAccept = '*';
    public capture: null | string = Capture.null;
    public isMobile = isMobile();
    public isShowPreloadDialog = false;
    public preloadImageCommentIndex: any = {};
    public preloadImageComments: any[] = [];
    public drafts: any = {};
    public fileInput: any = null;

    public readonly FormTooltipDelay = 500;

    get projectId(): number {
        return Number(this.$route.params.projectId);
    }

    get currentProject(): Project {
        return this.$store.getters.projectById(this.projectId);
    }

    get permissions() {
        return this.currentProject.permissions;
    }

    get selectedIssue(): Issue {
        return this.$store.getters.selectedIssueByProjectId(this.projectId);
    }

    get userData() {
        return this.$store.getters.userData;
    }

    get isSendFormEnabled() {
        return this.allowCreateComment && !this.formDisablingReason;
    }

    get formDisablingReason(): string {
        const commentSendFormDisablingReason = this.$store.getters.commentSendFormDisablingReason;

        if (commentSendFormDisablingReason) {
            return commentSendFormDisablingReason;
        }

        if (!this.allowCreateComment) {
            return 'notAllowed';
        }

        return '';
    }

    get allowCreateComment() {
        if (!this.selectedIssue) {
            return false;
        }

        return this.selectedIssue.hasPermissions(this.userData.email, this.permissions.createComment);
    }

    get accept() {
        if (this.capture === Capture.environment || this.attachType === AttachType.photo360) {
            return 'image/*';
        }
        return this.defaultAccept;
    }

    get files() {
        return this.fileInput ? this.fileInput.filter(Boolean) : [];
    }

    @Watch('selectedIssue', { immediate: true, deep: true })
    public onSelectedIssueChanged(value: Issue) {
        if (this.selectedIssue) {
            if (value && this.drafts[this.projectId] && this.drafts[this.projectId][this.selectedIssue.id]) {
                this.message = this.drafts[this.projectId][this.selectedIssue.id][draftRow];
            } else {
                this.message = '';
            }
        }

        this.fileInput = null;
    }

    @Watch('fileInput')
    public onFileInputChange(newValue: any, oldValue: any) {
        this.preloadImageComments = [];

        if (_.isEmpty(this.files)) {
            return;
        }

        this.files.forEach((file: any) => {
            if (ALLOWED_IMAGES_TYPES.includes(file.type)) {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => {
                    this.preloadImageComments.push({
                        filename: file.name,
                        preview: {
                            base64: reader.result,
                        },
                        uuid: uuidv4(),
                        is360: this.attachType === AttachType.photo360,
                    });

                };
            }
        });

        setTimeout(() => {
            if (newValue?.length > oldValue?.length) {
                if (this.preloadImageComments.length) {
                    this.preloadImageCommentIndex = 0;
                    this.isShowPreloadDialog = true;
                }
            }
        }, 300);
    }

    public mounted() {
        const savedDrafts = localStorage.getItem(LOCAL_STORAGE_DRAFT_KEY);
        this.drafts =  savedDrafts ? JSON.parse(savedDrafts) : {
            [this.projectId]: {},
        };
    }

    public changeAttachType(type: any) {
        this.attachType = type;
        this.isOpenAttachMenu = false;
        this.activateFileInput();
    }

    public isImageFile(file: any) {
        return ['image/jpeg', 'image/png', 'image/jpg'].includes(file.type);
    }

    public openPreview(file: any) {
       if (this.isImageFile(file)) {
           this.preloadImageComments.forEach((comment, index) => {
               if (comment.filename === file.name) {
                   this.preloadImageCommentIndex = index;
               }
           });
           this.isShowPreloadDialog = true;
       }
    }

    public clickOutsideAttachMenu() {
        this.isOpenAttachMenu = !this.isOpenAttachMenu; // doesn't work if put inline in the template
    }

    public openAttachMenu() {
        if (!this.isOpenAttachMenu) {
            this.isOpenAttachMenu = true;
        }
    }

    public onMessageChanged(value: string) {
        if (value.split('')[value.length - 1] === '\n') {
            return;
        }

        value = value.trim();
        setTimeout(() => {
            this.setCurrentIssueDraft(value ?? null);
        }, 300);
    }

    public setCurrentIssueDraft(value: string | null) {
        const drafts = this.drafts[this.projectId] || {};
        drafts[this.selectedIssue.id] =  {
            [draftRow]: value,
            [draftUuidRow]: this.selectedIssue.uuid,
        };

        if (!drafts[this.selectedIssue.id][draftRow]) {
            delete drafts[this.selectedIssue.id];
        }

        this.drafts[this.projectId] = drafts;
        this.$store.commit('setDraftIssues', { projectId: this.projectId, issues: Object.keys(drafts) });
        localStorage.setItem(LOCAL_STORAGE_DRAFT_KEY, JSON.stringify(this.drafts));
    }

    public resetIssueDraft() {
        this.setCurrentIssueDraft(null);
    }

    public setNoCapture() {
        this.capture = Capture.null;
    }

    public activateFileInput() {
        setTimeout(() => {
            (this.$refs['file-input'] as any).$refs.input.click();
        });
    }

    public openMobileCamera() {
        this.capture = Capture.environment;
        this.activateFileInput();
    }

    public startWriteComment() {
        eventBus.$emit(BusEvent.cancelIssueEdit);
    }

    public onFileInput(file: any) {
        this.fileInput = file;
    }

    public onEnter(e: KeyboardEvent) {
        if (e.shiftKey) {
            return;
        }

        if (e.ctrlKey || e.metaKey) { // Because in Revizto app Ctrl+Enter adds a new line
            this.message += '\n';
            return;
        }

        this.onSend();
    }

    public onSend() {
        if (!this.allowCreateComment) {
            return;
        }

        // When an user press just "enter" in empty input we should disable send and just adds a new line
        if (!this.fileInput && !this.message.trim()) {
            return;
        }

        const commentsToSend: any[] = [];

        if (this.message) {
            commentsToSend.push(this.message);
            this.message = '';
        }

        if (this.fileInput) {
            this.fileInput.commentType = CommentType.File;

            if (this.attachType === AttachType.photo360) {
                this.fileInput[0].is360 = true;
            }
            commentsToSend.push(this.fileInput);
            this.fileInput = null;
        }

        this.$store.dispatch('sendCommentsForIssue', {
            projectId: this.projectId,
            issueUuid: this.selectedIssue.uuid,
            email: this.userData.email,
            comments: commentsToSend,
        }).then(() => {
            let type;
            commentsToSend.forEach((comment: any) => {
                if (_.isString(comment)) {
                    type = 'text';
                } else {
                    type = comment.is360 ? '360photo' : 'file';
                }
                amplitudeLog(AmplitudeEvent.itChatSendMessage, { type });
            });
        });

        this.setCurrentIssueDraft(null);
        this.attachType = AttachType.null;
        this.resetIssueDraft();
        this.setNoCapture();
    }

    public sendFromPreview() {
        this.onSend();
        this.isShowPreloadDialog = false;
    }

    public closePreviewHandler() {
        this.isShowPreloadDialog = false;
    }

    public showNextImage() {
        this.preloadImageCommentIndex = this.preloadImageCommentIndex === (this.preloadImageComments.length - 1) ? 0 : this.preloadImageCommentIndex + 1;
    }

    public showPrevImage() {
        this.preloadImageCommentIndex = this.preloadImageCommentIndex === 0 ? (this.preloadImageComments.length - 1) : this.preloadImageCommentIndex - 1;
    }

    public removeFile(index: number) {
        const files = _.cloneDeep(this.fileInput);
        files.splice(index, 1);
        this.fileInput = files;
    }
}
