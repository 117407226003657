import Vue from 'vue';
// @ts-ignore
import settings from 'reviztoSettings';
import VueCookies from 'vue-cookies';
Vue.use(VueCookies);

export class CookieHelper {
    public static set(key: string, value: string) {
        Vue.$cookies.set(
            key,
            value,
            '365d',
            undefined,
            '.' + settings.baseHost,
            settings.workspaceUrl.includes('https://') ? true : undefined,
        );
    }

    public static setTrue(key: string) {
        CookieHelper.set(key, 'true');
    }

    public static isTrue(key: string) {
        return CookieHelper.get(key) === 'true';
    }

    public static get(key: string) {
        return Vue.$cookies.get(key);
    }

    public static remove(key: string) {
        Vue.$cookies.remove(key, undefined, settings.baseHost);
    }
}
