









































































import { Component, Emit, Prop, Watch } from 'vue-property-decorator';
import { MultiEditIssueFieldPayload } from '@/types/MultiEditIssueFieldPayload';
import { RouterNames, TAGS_MAX_LENGTH } from '@/constants';
import { Project } from '@/models';
import { getCommaForListItem } from '@/services/GetCommaForListItem';
import { DialogEditTagsMixin } from '@/mixins';
import WsButton from '@/components/common/WsButton.vue';
import WsCombobox from '@/components/common/WsCombobox.vue';
import WsDialog from '@/components/common/WsDialog.vue';

@Component({
    components: {
        WsButton,
        WsCombobox,
        WsDialog,
    },
})
export default class DialogMultiEditTags extends DialogEditTagsMixin {
    @Prop({ default: () => [] }) public tagsEntries!: Array<{
        count: number;
        tag: string;
    }>;
    @Prop() public issueCount!: number;
    @Prop({ default: () => [] }) public items!: string[];
    @Prop({ type: Boolean, default: false }) public allowCreateTag!: boolean;
    @Prop({ type: Boolean, default: false }) public isShowManageTags!: boolean;

    public addTags: string[] = [];
    public removeTags: string[] = [];
    public readonly getCommaForListItem = getCommaForListItem;
    public readonly TAGS_MAX_LENGTH = TAGS_MAX_LENGTH;

    get projectId(): number {
        return Number(this.$route.params.projectId);
    }
    get project(): Project {
        return this.$store.getters.projectById(this.projectId);
    }

    get isDisableSave() {
        return this.isCheckDisableSave(this.addTags);
    }

    @Emit('save')
    public save(): MultiEditIssueFieldPayload {
        return {
            add: this.addTags,
            remove: this.removeTags,
        };
    }

    @Emit()
    public cancel() {
        return;
    }

    @Emit()
    public input(value: string[]) {
        return value;
    }

    @Watch('localValue')
    public watchLocalValue(newValue: string[]) {
        this.input(newValue);
    }

    public goToManageTags() {
        this.$router.push({
            name: RouterNames.IssueSettings,
            params: {
                language: this.$route.params.language,
                projectId: this.$route.params.projectId,
                licenseId: this.$route.params.licenseId,
            },
            hash: '#tags',
        });
    }
}
