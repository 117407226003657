































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Color } from '@/constants';
import { sanitizeHtml } from '@/services';
import IconSvg16 from '@/components/common/icon/IconSvg16.vue';

@Component({
    components: {
        IconSvg16,
    },
})
export default class WsAlert extends Vue {
    @Prop({ required: false, type: Boolean }) public danger!: boolean;
    @Prop({ required: false, type: Boolean }) public success!: boolean;
    @Prop({ required: false, type: Boolean }) public warning!: boolean;
    @Prop({ required: false, type: Boolean }) public small!: boolean;
    @Prop({ required: false, type: String }) public title!: string;
    @Prop({ required: false, type: String }) public text!: string;

    public readonly Color = Color;
    public readonly sanitizeHtml = sanitizeHtml;

    get slotPassed() {
        return this.$slots.default ? Boolean(this.$slots.default![0].text?.trim().length) : false;
    }

    get iconColor() {
        if (this.danger) {
            return Color.primaryRed;
        }

        if (this.warning) {
            return Color.primaryYellow;
        }

        if (this.success) {
            return Color.successLight;
        }

        return Color.accent100;
    }
}
