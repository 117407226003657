import _ from 'lodash';
// @ts-ignore
import VueCookies from 'vue-cookies/vue-cookies.js';
import { IRegion } from '@/types/IRegion';
import { Dict } from '@/types/Dict';
import { RegionEnum, RESPONSE } from '@/constants';
import http from '@/api/http';
import authHttp from '@/api/authHttp';
import { RegionApi } from '@/api';
import { Region } from '@/models';
import { CookieHelper } from '@/services';

interface IRegionServiceData {
    api: {
        host: string,
        port: number,
        protocol: string,
    };
    notifier: {
        host: string,
        port: number,
        protocol: string,
    };
}

const promise = {
    regions: null as null | Promise<IRegion[]>,
};

export default {
    state: {
        regions: [] as Region[],
        regionId: VueCookies.get('region') as string || RegionEnum.auto,
        isLoadingRegions: false,
        regionServiceData: {
            api: {
                host: '',
                port: 443,
                protocol: '',
            },
            notifier: {
                host: '',
                port: 443,
                protocol: 'wss',
            },
            id: '',
        },
        isOnline: false,
    },
    getters: {
        regionsList(state: any): Region[] {
            return state.regions;
        },
        pureRegions(state: any): Region[] {
            return _.reject(state.regions, { id: RegionEnum.auto });
        },
        pureRegionsIds(_state: any, getters: any) {
            return _.map(getters.pureRegions, 'id');
        },
        regionId(state: any): string {
            return state.regionId;
        },
        currentRegion(state: any, getters: any): Region | undefined {
            return getters.regionById(getters.regionId);
        },
        regionsByIdObj(state: any): Dict<Region> {
            return _.keyBy(state.regions, 'id');
        },
        regionById(state: any, getters: any): (regionId: string) => Region | undefined {
            return (regionId) => getters.regionsByIdObj[regionId];
        },
        regionName(state: any, getters: any) {
            const region = getters.regionById(getters.regionId);
            return region?.normalizedName || '';
        },
        isLoadingRegions(state: any): boolean {
            return state.isLoadingRegions;
        },
        notifierUrl(state: any): string {
            return state.regionServiceData.notifier.host;
        },
        isOnline(state: any): string {
            return state.isOnline;
        },
        otherRegions(state: any, getters: any): Region[] {
            return getters.regionsList.filter((region: Region) => ![getters.regionId, RegionEnum.auto].includes(region.id));
        },
    },
    mutations: {
        setRegionsList(state: any, regions: IRegion[]) {
            state.regions = regions.map(Region.instantiate);
            http.setRegionsList(state.regions);
            authHttp.setRegionsList(state.regions);
        },
        setRegionId(state: any, regionId: any) {
            if (typeof regionId !== 'string') {
                throw Error('Incorrect regionId type');
            }
            state.regionId = regionId;
            CookieHelper.set('region', regionId);
            http.setRegionsId(state.regionId);
            authHttp.setRegionsId(state.regionId);
        },
        setIsLoadingRegions(state: any, value: boolean) {
            state.isLoadingRegions = value;
        },
        setRegionServiceData(state: any, data: IRegionServiceData) {
            state.regionServiceData = data;
        },
        setIsOnline(state: any, value: boolean) {
            state.isOnline = value;
        },
    },
    actions: {
        downloadRegions({ state, commit, dispatch }: any): Promise<IRegion[]> {
            if (promise.regions) {
                return promise.regions;
            }

            if (state.regions.length) {
                return Promise.resolve(state.regions);
            }

            commit('setIsLoadingRegions', true);
            promise.regions = RegionApi.loadRegions().then((response: any) => {
                commit('setRegionsList', response.data);
                dispatch('pingInternetConnection');
                return response.data;
            }).finally(() => {
                promise.regions = null;
                commit('setIsLoadingRegions', false);
            });

            return promise.regions;
        },
        downloadRegion({ getters, commit }: any, regionPayload: any): Promise<IRegion> {
            const { regionId } = getters;
            return new Promise((resolve, reject) => {
                if (regionId !== RegionEnum.auto) {
                    commit('setRegionId', regionId);
                    resolve(getters.regionById(regionId));
                } else {
                    RegionApi.postUserRegion(regionPayload).then((response: any) => {
                        commit('setRegionId', response);
                        resolve(getters.regionById(response));
                    }).catch((error: any) => {
                        if (error.result === RESPONSE.INVALID_DATA) {
                            commit('setAuthError', error.data);
                            reject(error.data);
                        } else {
                            commit('setAuthError', error.message);
                            reject(error.message);
                        }
                    });
                }
            });
        },
        async downloadRegionServiceData({ commit }: any): Promise<any> {
            try {
                const response = await RegionApi.getRegionData();
                commit('setRegionServiceData', response.data);
            } catch {
                throw new Error('Error at download region data');
            }
        },
        pingInternetConnection({ rootGetters, commit, dispatch }: any) {
            RegionApi.loadRegions().then(() => {
                commit('setIsOnline', true);
                if (rootGetters.isAuthenticate) {
                    dispatch('connectNotifier', { root: true });
                }
            }).catch(() => {
                // todo [WEB-5662] это не работает как задумывалось, потому что запрос кэшируется браузером
                commit('setIsOnline', false);
            }).finally(() => {
                setTimeout(() => {
                    dispatch('pingInternetConnection');
                }, 30000);
            });
        },
    },
};
