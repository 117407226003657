












































import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { INotificationAction } from '@/types/INotifier';
import { ButtonVariant } from '@/constants';
import { NotificationType } from '@/constants/Notifier/NotificationType';
import { sanitizeHtml } from '@/services/';
import WsCloseButton from '@/components/common/WsCloseButton.vue';
import WsButton from '@/components/common/WsButton.vue';
import IconSvg16 from '@/components/common/icon/IconSvg16.vue';

const notificationTypeDefault = NotificationType.INFO;

const notificationIcon = {
    [NotificationType.INFO]: 'info-new',
    [NotificationType.WARNING]: 'error',
    [NotificationType.SUCCESS]: 'checked',
    [NotificationType.ERROR]: 'error',
    [NotificationType.CONFIRMATION]: 'confirmation',
};

const notificationTitle = {
    [NotificationType.INFO]: 'Info',
    [NotificationType.WARNING]: 'Warning',
    [NotificationType.SUCCESS]: 'Success',
    [NotificationType.ERROR]: 'Error',
    [NotificationType.CONFIRMATION]: 'Confirmation',
};

@Component({
    components: {
        IconSvg16,
        WsButton,
        WsCloseButton,
    },
})
export default class WsNotification extends Vue {
    @Prop({ default: NotificationType.INFO }) type!: NotificationType;
    @Prop({ type: String, default: '' }) title!: string;
    @Prop({ type: String }) text!: string;
    @Prop({ type: Boolean, default: false }) isReloadLink!: boolean;
    @Prop() onCloseAction!: () => void;
    @Prop({ default: () => [] }) actions!: INotificationAction[];

    public readonly sanitizeHtml = sanitizeHtml;
    public readonly ButtonVariant = ButtonVariant;

    get icon() {
        return notificationIcon[this.type] || notificationIcon[notificationTypeDefault];
    }

    get titleInternal() {
        return this.title || notificationTitle[this.type] || notificationTitle[notificationTypeDefault];
    }

    public getButtonVariant(action: INotificationAction) {
        return action?.variant || ButtonVariant.TERTIARY;
    }

    @Emit()
    public close() {
        if (this.onCloseAction) {
            this.onCloseAction();
        }
    }

    public onActionClick(callback: () => void, needToClose = true) {
        callback();

        if (needToClose) {
            this.close();
        }
    }

    public reload() {
        window.location.reload();
    }
}
