




























































































































import _ from 'lodash';
import axios from 'axios';
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import { DateFormat, PreviewSize, PreviewSizeArray, RouterNames, SortDirections, TimeFormat } from '@/constants';
import { FormCheckbox, FormSelect, FormSort, FormSortField, FormSortItem, Project } from '@/models';
import { saveFile, shallowDeboolifyObject } from '@/services';
import ChartSettingsInlineButton from '@/components/chart/ChartSettingsInlineButton.vue';
import WsDialog from '@/components/common/WsDialog.vue';
import WsSelect from '@/components/common/WsSelect.vue';
import WsButton from '@/components/common/WsButton.vue';
import WsCheckbox from '@/components/common/WsCheckbox.vue';

const customFieldsList = [
    'type',
    'tags',
    'watchers',
    'clash_level',
    'public',
    'sheet',
    'clash_test',
    'clash_discipline',
    'clash_distance',
    'clash_distance_m',
    'clash_distance_mm',
    'clash_category',
    'clash_source_file',
    'clashGrid',
    'clash_grid_x',
    'clash_grid_y',
    'clash_room',
    'clash_space',
    'clash_area',
    'clash_zone',
];

const ButtonTypes = Object.freeze({
    plus: 'plus',
    close: 'close',
});

@Component({
    components: {
        ChartSettingsInlineButton,
        WsButton,
        WsCheckbox,
        WsDialog,
        WsSelect,
    },
})
export default class IssuesPrintToPdfModal extends Vue {
    @Prop({ default: () => [] }) public issuesUuids!: string[];

    public readonly RouterNames = RouterNames;
    public readonly ButtonTypes = ButtonTypes;
    public readonly MaxCountSorts = 3;
    public readonly PreviewSizeArray = PreviewSizeArray;

    public readonly debouncedLoadPdf = _.debounce(this.loadIssuesPdf, 500);

    public printPdfForm: any = {
        title: 'A title',
        uuids: this.issuesUuids,
        format: 2, // only value 2 is possible, meaning pdf — https://revizto.atlassian.net/browse/WEB-8711
        includeUserComments: false,
        includeAttachments: false,
        includeFieldChanges: false,
        includeMarkupChanges: false,
        previewSize: PreviewSize.SMALL_IMAGES,
        dateFormat: DateFormat['DD/MM/YYYY'],
        timeFormat: TimeFormat['24h'],
        pdfSort: [{ direction: SortDirections.asc, field: 'id' }],
    };
    public isLoadingPdf: boolean = false;
    public loadIssuesPdfCount = 0;
    public isPdfPrintError: boolean = false;
    public issuesPdfUrl: string = '';
    public isLoadingSaveToPdf: boolean = false;
    public issuesPdfName: string = '';

    get projectId(): number {
        return Number(this.$route.params.projectId);
    }

    get project(): Project {
        return this.$store.getters.projectById(this.projectId);
    }

    get sortFieldsList(): FormSortField[] {
        return [
            { name: 'id' },
            { name: 'tags', additionalField: this.project.issueTags },
            { name: 'title' },
            { name: 'public' },
            { name: 'sheet' },
            { name: 'clash_test' },
            { name: 'customStatus' },
            { name: 'customType' },
            { name: 'assignee' },
            { name: 'reporter' },
            { name: 'priority' },
            { name: 'stamp' },
            { name: 'stamp_color' },
            { name: 'created' },
            { name: 'deadline' },
            { name: 'clash_level' },
            { name: 'clash_room' },
            { name: 'clash_space' },
            { name: 'clash_area' },
            { name: 'clash_zone' },
        ].map(FormSortField.instantiate);
    }

    get allPdfPrintSettings() {
        return {
            includeUserComments: new FormCheckbox({
                name: 'includeUserComments',
                translationCode: 'IssueTracker.print.includeUserComments',
                value: this.printPdfForm.includeUserComments,
            }),
            includeAttachments: new FormCheckbox({
                name: 'includeAttachments',
                translationCode: 'IssueTracker.print.includeAttachments',
                value: this.printPdfForm.includeAttachments,
            }),
            includeFieldChanges: new FormCheckbox({
                name: 'includeFieldChanges',
                translationCode: 'IssueTracker.print.includeFieldChanges',
                value: this.printPdfForm.includeFieldChanges,
            }),
            includeMarkupChanges: new FormCheckbox({
                name: 'includeMarkupChanges',
                translationCode: 'IssueTracker.print.includeMarkupChanges',
                value: this.printPdfForm.includeMarkupChanges,
            }),
            customFields: new FormSelect({
                name: 'customFields',
                label: 'IssueTracker.print.additionalFields',
                translationCode: 'DeliveryOptions.fields',
                options: customFieldsList,
                multiple: true,
                value: this.printPdfForm.customFields,
            }),
            pdfSort: new FormSort({
                name: 'pdfSort',
                label: 'DeliveryOptions.orderByLabel',
                options: this.sortFieldsList,
                directions: SortDirections,
                sorts: Object.values(this.sortFieldsList.reduce((acc, next) => {
                    if (this.printPdfForm.pdfSort.find((sort: any) => {
                        if (next.name === sort.field) {
                            next.directionValue = sort.direction;
                            next.values = sort.values;
                            return next;
                        }
                    })) {
                        acc[next.name] = new FormSortItem({ field: next, direction: next.directionValue });
                    }
                    return acc;
                }, { [this.sortFieldsList[0].name]: new FormSortItem({ field: this.sortFieldsList[0] }) })),
            }),
            timeFormat: new FormSelect({
                name: 'timeFormat',
                label: 'TimeFormat.label',
                translationCode: 'TimeFormat',
                options: TimeFormat,
                value: this.printPdfForm.timeFormat,
            }),
            dateFormat: new FormSelect({
                name: 'dateFormat',
                label: 'DateFormat.label',
                translationCode: 'DateFormat',
                options: DateFormat,
                value: this.printPdfForm.dateFormat,
            }),
        };
    }

    get pdfPrintSettings() {
        return [
            this.allPdfPrintSettings.includeUserComments,
            this.allPdfPrintSettings.includeAttachments,
            this.allPdfPrintSettings.includeFieldChanges,
            this.allPdfPrintSettings.includeMarkupChanges,
        ];
    }

    get customFields() {
        const customFields = _.cloneDeep(this.allPdfPrintSettings.customFields);
        const options = Object.keys(customFields.options);
        return { ...customFields, options };
    }

    get pdfSort() {
        return this.allPdfPrintSettings.pdfSort;
    }

    get buttonTypesArr() { // type of button(s) (close vs plus) to the right of the selects
        const max = 3;
        const numSorts = this.printPdfForm.pdfSort.length;
        switch (numSorts) {
            case max:
                return Array(max).fill(ButtonTypes.close);
            case 1:
                return [ButtonTypes.plus];
            default:
                return Array(numSorts).fill(ButtonTypes.close).concat(ButtonTypes.plus);
        }
    }

    get freeOptions() {
        return this.pdfSort.options.filter((option) => !this.isSelectedOption(option.name));
    }

    get additionalOrderFields() {
        const optionsWithAdditionalFields = this.pdfSort.options.filter((option) => option.additionalField);
        return (this.printPdfForm.pdfSort || []).map(({ field }: any) => {
            const option = optionsWithAdditionalFields.find((el) => el.name === field);
            if (!option) {
                return null;
            }
            return option.additionalField;
        });
    }

    get dateTimeSettings() {
        return [
            this.allPdfPrintSettings.timeFormat,
            this.allPdfPrintSettings.dateFormat,
        ];
    }

    @Watch('printPdfForm', { immediate: true, deep: true })
    public printPdfFormChanged() {
        this.debouncedLoadPdf();
    }

    @Emit('close')
    public emitClose() {
        return;
    }

    public async loadIssuesPdf() {
        const localCount = ++this.loadIssuesPdfCount;
        this.isLoadingPdf = true;

        let url;
        try {
            url = await this.getIssuesPdfUrl();
        } catch {
            this.isPdfPrintError = true;
            this.issuesPdfUrl = '';
            this.isLoadingPdf = false;
            this.isLoadingSaveToPdf = false;
            return;
        }
        if (localCount !== this.loadIssuesPdfCount) {
            return;
        }

        try {
            const { data }: { data: Blob } = await axios({
                url,
                method: 'GET',
                responseType: 'blob',
            });
            this.issuesPdfUrl = URL.createObjectURL(data);
        } catch {
            this.isPdfPrintError = true;
            this.issuesPdfUrl = '';
        } finally {
            this.isLoadingSaveToPdf = false;
            this.isLoadingPdf = false;
        }
    }

    public async getIssuesPdfUrl() {
        const form = shallowDeboolifyObject(this.printPdfForm);
        const { url } = await this.$store.dispatch('postIssuesToPdf', {
            projectId: this.projectId,
            params: {
                format: 2,
                ..._.omit(form, 'pdfSort'),
                reportSort: form.pdfSort,
            },
        });
        this.issuesPdfName = url.replace(/^.*[\\/]/, '');
        return url;
    }

    public availableSortVariants(idx: number) {
        const occupiedItemsNames = this.printPdfForm.pdfSort
            .filter((el: any, i: number) => i !== idx)
            .map((el: any) => el.field);

        return this.pdfSort.options.filter((option) => !occupiedItemsNames.includes(option.name)).map((item) => {
            return {
                ...item,
                text: this.$t('DeliveryOptions.fields.' + item.name),
            };
        });
    }

    public addOrder() {
        const newSort = this.freeOptions[0];
        this.printPdfForm.pdfSort.push({
            field: newSort.name,
            direction: SortDirections.asc,
        });
    }

    public isSelectedOption(optionName: string) {
        return this.printPdfForm.pdfSort.map((option: any) => option.field).includes(optionName);
    }

    public removeOrder(idx: number) {
        this.printPdfForm.pdfSort = this.printPdfForm.pdfSort.filter((el: any, i: number) => i !== idx);
    }

    public saveIssuesToPdf() {
        this.isLoadingSaveToPdf = true;

        const interval = setInterval(() => {
            if (this.isPdfPrintError) {
                clearInterval(interval);
                return;
            }
            if (this.isLoadingPdf) {
                return;
            }

            clearInterval(interval);
            saveFile(this.issuesPdfUrl, this.issuesPdfName);
            this.isLoadingSaveToPdf = false;
            this.emitClose();
        }, 200);
    }
}
