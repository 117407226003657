import _ from 'lodash';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { unitOfTime } from 'moment';
import { IIssueFilter } from '@/types/IIssueFilter';
import { Dict } from '@/types/Dict';
import {
    assigneeAddFieldNotSetKey,
    BrokenFilterEnum,
    CREATED_ON_MODE_GUID,
    currentUserKey,
    Direction,
    enumDisciplineTypeNormalizeFn,
    IssueClashDiscipline,
    IssueCreatedFrom,
    IssueFilterExpr,
    IssuePriorityList,
    IssuesFilterType,
    IssueTypes,
    numberToDiscipline,
    ProcoreRFIStatus,
    ProcoreRFIType,
    StampColorsPaletteRGBEnum,
    TimeUnit,
    userNotSetKey,
    ValueNotSet,
    ValueNotSetMainSheetTags,
} from '@/constants';
import { ANY_STAMP_UUID } from '@/constants/Stamps';
import { FieldVariants, ProjectMember } from '@/models';
import {
    FromProtobuf,
    getUtcMidday,
    IssueFilterSetRule,
    makeShiftForAbsoluteDates,
    RuleTypeEnum,
    timestampToServer,
    ToProtobuf,
} from '@/services';
import { IssueTrackerFilterValue } from '@/services/issueTracker/IssueTrackerFilterValue';
import { IssueTrackerFilterByDeadlineValue } from '@/services/issueTracker/IssueTrackerFilterByDeadlineValue';
import { IssueTrackerFilterByDateValue } from '@/services/issueTracker/IssueTrackerFilterByDateValue';
import { IssueTrackerFilterByDurationValue } from '@/services/issueTracker/IssueTrackerFilterByDurationValue';

const noTagKey = '_No_Tag_Key_';
const currentSheetKey = '_Current_Sheet_Key_';

function getFieldVariants(fieldVariants: FieldVariants, projectTeam: ProjectMember[]) {
    const assigneeCompanies = projectTeam.map(({ company }: ProjectMember) => company);
    const assigneeDepartments = projectTeam.map(({ department }: ProjectMember) => department);
    const assigneeLocations = projectTeam.map(({ location }: ProjectMember) => location);
    return { ...fieldVariants, assigneeCompanies, assigneeDepartments, assigneeLocations };
}

export class IssuesFilter implements IIssueFilter {
    public type: string;
    public uuid: string;
    public expr: number;
    public value: any | any[];
    public exprOptions: number[];
    public valueOptions: any[];

    constructor(filter: any = {}) {
        this.type = filter.type || IssuesFilterType.assignee;
        this.uuid = filter.uuid || uuidv4();
        this.expr = filter.expr || null;
        this.value = filter.value || [];
        this.exprOptions = filter.exprOptions || [];
        this.valueOptions = filter.valueOptions || [];
    }

    /**
     * Метод проверки валидности параметров фильтра, необходимых для бекэнда
     * @returns {boolean} - false, если не задан type или expr, или пустое поле value
     */
    public isValidParams(): boolean {
        return (!!this.type) && (!!this.expr) && (!!this.value) && (Array.isArray(this.value) ? this.value.length > 0 : true);
    }

    public convertToPanelFilter() {
        const modifier = this.expr;
        const selections = this.value;
        return new IssueTrackerFilterValue(this.type, modifier, selections);
    }

    /**
     * Метод получения параметров фильтра, необходимых для бекэнда
     */
    public getParams(): any {
        return {
            uuid: this.uuid,
            type: this.type,
            expr: this.expr,
            value: this.value,
        };
    }
}

export class IssuesFilterInputType extends IssuesFilter {
    constructor(filter: any = {}) {
        super(filter);
        this.exprOptions = [
            IssueFilterExpr.INCLUDE,
            IssueFilterExpr.NOT_INCLUDE,
        ];
        this.expr = filter.expr || this.exprOptions[0];
        this.value = filter.value || '';
    }
}
class IssuesFilterInclusionType extends IssuesFilter {
    constructor(filter: any = {}) {
        super(filter);
        this.exprOptions = [
            IssueFilterExpr.IN,
            IssueFilterExpr.NOT_IN,
        ];
        this.expr = filter.expr || this.exprOptions[0];
    }
}
class IssuesFilterPriority extends IssuesFilterInclusionType {
    constructor(filter: any = {}) {
        super(filter);
        this.valueOptions = IssuePriorityList.map((value: any) => {
            return { name: 'ws_lang.Dashboard.chart.settings.filter.priority[value]', value: String(value) };
        });
    }
}
class IssuesFilterIssuesType extends IssuesFilterInclusionType {
    constructor(filter: any = {}) {
        super(filter);
        this.valueOptions = Object.entries(IssueTypes).map(([, value]) => {
            return { name: 'ws_lang.Dashboard.chart.settings.filter.type[value]', value: String(value) };
        });
    }
}
class IssuesFilterProcoreType extends IssuesFilterInclusionType {
    constructor(filter: any) {
        super(filter);
        this.value = (filter.value || []).map((val: number) => String(val));
        this.valueOptions = Object.entries(ProcoreRFIType).map(([, value]) => {
            return { name: 'ws_lang.Dashboard.chart.settings.filter.procoreType[value]', value: String(value) };
        });
    }
}
class IssuesFilterProcoreStatus extends IssuesFilterInclusionType {
    constructor(filter: any) {
        super(filter);
        this.value = (filter.value || []).map((val: number) => String(val));
        this.valueOptions = Object.entries(ProcoreRFIStatus).map(([, value]) => {
            return { name: 'ws_lang.Dashboard.chart.settings.filter.procoreStatus[value]', value: String(value) };
        });
    }
}
class IssuesFilterIssuesCreatedFrom extends IssuesFilterInclusionType {
    constructor(filter: any = {}) {
        const valueMapper = (val: string | number) => {
            switch (val) {
                case '2D':
                case '2':
                case 2:
                    return 2;
                case '3D':
                case '3':
                case 3:
                    return 3;
                case 'None':
                case '0':
                case 0:
                    return 0;
            }
        };
        const mappedFilter = {
            ...filter,
            value: (filter.value || []).map(valueMapper),
        };
        super(mappedFilter);
        this.valueOptions = Object.entries(IssueCreatedFrom).map(([, value]) => {
            return { name: 'ws_lang.Dashboard.chart.settings.filter.type[value]', value: String(value) };
        });
    }

    public getParams() {
        const parentParams = super.getParams();
        // отправка значения которое ждёт бек
        parentParams.type = 'binding';
        return parentParams;
    }
}

class IssuesFilterInclusionExtendType extends IssuesFilter {
    constructor(filter: any) {
        super(filter);
        this.exprOptions = [
            IssueFilterExpr.HAS_ONE_IN,
            IssueFilterExpr.HAS_ALL_IN,
            IssueFilterExpr.HAS_NOT_ALL_IN,
        ];
        this.expr = filter.expr || this.exprOptions[0];
    }
}
class IssuesFilterClashDiscipline extends IssuesFilterInclusionExtendType {
    constructor(filter: any = {}) {
        super(filter);
        this.valueOptions = Object.entries(IssueClashDiscipline).map(([key]) => {
            return { name: 'ws_lang.Dashboard.chart.settings.filter.clashDisciplineType[value]', value: key };
        });
    }
}

export class IssuesFilterConditionDateType extends IssuesFilter {
    public readonly exprOptions = [
        IssueFilterExpr.LESS_THAN,
        IssueFilterExpr.MORE_THAN,
        IssueFilterExpr.EQUALS,
        IssueFilterExpr.INTERVAL,
    ];

    public timeUnitList: number[];
    public timeUnit: number;
    public pairValue: any;
    public pairTimeUnit: number;

    constructor(filter: any = {}) {
        super(filter);
        this.expr = this.expr || IssueFilterExpr.LESS_THAN;
        this.timeUnitList = Object.values(TimeUnit);
        this.timeUnit = filter.timeUnit || this.timeUnitList[1];
        this.value = filter.timeUnit === TimeUnit.EXACT_DATE ? new Date(filter.value * 1000) : filter.value || 7;
        this.pairTimeUnit = filter.pairTimeUnit || this.timeUnitList[1];
        this.pairValue = filter.pairTimeUnit === TimeUnit.EXACT_DATE ? new Date(filter.pairValue * 1000) : filter.pairValue || 7;
    }

    public getParams() {
        let pairValue: any = undefined;
        let pairTimeUnit: any = undefined;

        if (this.expr === IssueFilterExpr.INTERVAL) {
            // We should send pairValue only for INTERVAL expression.
            pairValue = (this.pairValue instanceof Date) ? timestampToServer(Number(this.pairValue)) : this.pairValue;
            pairTimeUnit = this.pairTimeUnit;
        }

        return {
            uuid: this.uuid,
            type: this.type,
            expr: this.expr,
            value: (this.value instanceof Date) ? timestampToServer(Number(this.value)) : this.value,
            timeUnit: this.timeUnit,
            pairValue,
            pairTimeUnit,
        };
    }
}
class IssuesFilterDuration extends IssuesFilter {
    public readonly timeUnitList = [
        TimeUnit.DAYS,
        TimeUnit.WEEKS,
        TimeUnit.MONTHS,
        TimeUnit.YEARS,
    ];
    public readonly exprOptions = [
        IssueFilterExpr.LESS_THAN,
        IssueFilterExpr.MORE_THAN,
        IssueFilterExpr.EQUALS,
    ];
    public dateFilterType: any;
    public date: any;

    constructor(filter: any = {}) {
        super({ type: IssuesFilterType.duration  });
        let data: any = {};
        try {
           data = JSON.parse(filter['Items']);
        } catch (err) {
           data = {};
        }
        this.dateFilterType = data.type;
        this.date = data.date || { days: 1, units: TimeUnit.DAYS };
    }

    public convertToPanelFilter(): any {
        return new IssueTrackerFilterByDurationValue(this.dateFilterType, this.date.value, this.date.units);
    }
}

class IssueTrackerFilterByDate extends IssuesFilter {
    public days: any;
    public includeNow: any;
    public date: any;
    public date2: any;
    public dateFilterType: any;

    constructor(filterType: string, rule: any) {
        const ruleObj: any = JSON.parse(rule.Items[0]);
        const { type, days, includeNow, date, date2 } = ruleObj;
        super({ type: filterType });
        this.dateFilterType = type;
        this.days = days;
        this.includeNow = includeNow;
        this.date = date;
        this.date2 = date2;
    }

    public convertToPanelFilter(): any {
        switch (this.type) {
            case IssuesFilterType.created:
            case IssuesFilterType.closed:
                return new IssueTrackerFilterByDateValue(this.type, this.dateFilterType, this.date, this.date2, this.days);
            case IssuesFilterType.duration:
                return new IssueTrackerFilterByDurationValue(this.dateFilterType, this.date.value, this.date.units);
            case IssuesFilterType.deadline:
                return new IssueTrackerFilterByDeadlineValue(this.type, this.dateFilterType, this.date, this.date2, this.days, this.includeNow);
        }
    }
}

export class IssueTrackerFilterDeadline extends IssueTrackerFilterByDate {
    constructor(rule: any) {
        super(IssuesFilterType.deadline, rule);
    }
}

export class IssueTrackerFilterCreated extends IssueTrackerFilterByDate {
    constructor(rule: any) {
       super(IssuesFilterType.created, rule);
    }
}

export class IssueTrackerFilterClosed extends IssueTrackerFilterByDate {
    constructor(rule: any) {
       super(IssuesFilterType.closed, rule);
    }
}

export class IssueTrackerFilterDeletedAt extends IssueTrackerFilterByDate {
    constructor(rule: any) {
        super(IssuesFilterType.deletedAt, rule);
    }
}

const DateFilterRuleType = {
    NotSet: 0,
    BeforeNow: 1,
    MoreThanNDaysAgo: 2,
    WithinLastNDays: 3,
    InNDaysFromNow: 4,

    CustomEqual: 5,
    CustomLess: 6,
    CustomLessOrEqual: 7,
    CustomMore: 8,
    CustomMoreOrEqual: 9,
    CustomBetween: 10,
};

const TimeUnitStrings: Dict<unitOfTime.DurationConstructor> = {
    [TimeUnit.DAYS]: 'day',
    [TimeUnit.WEEKS]: 'week',
    [TimeUnit.MONTHS]: 'month',
    [TimeUnit.YEARS]: 'year',
};

export class IssuesFilterDate extends IssuesFilter {
    public directionList: any[];
    public timeUnitList: any[];
    public direction: any;
    public timeUnit: any;
    public pairValue: any;
    public pairTimeUnit: number;
    public pairDirection: any;

    constructor(filter: any = {}) {
        super(filter);
        this.directionList = Object.values(Direction);
        this.timeUnitList = Object.values(TimeUnit);
        this.exprOptions = [
            IssueFilterExpr.MORE_THAN,
            IssueFilterExpr.LESS_THAN,
            IssueFilterExpr.EQUALS,
            IssueFilterExpr.INTERVAL,
            IssueFilterExpr.NOT_SET,
        ];
        this.expr = filter.expr || this.exprOptions[1];
        this.direction = filter.direction || this.directionList[0];
        this.pairDirection = filter.pairDirection || this.directionList[0];
        this.timeUnit = filter.timeUnit || this.timeUnitList[1];
        this.value = filter.timeUnit === TimeUnit.EXACT_DATE ? new Date(filter.value * 1000) : filter.value || 7;
        this.pairTimeUnit = filter.pairTimeUnit || this.timeUnitList[1];
        this.pairValue = filter.pairTimeUnit === TimeUnit.EXACT_DATE ? new Date(filter.pairValue * 1000) : filter.pairValue  || 7;

    }

    public static dateFilterDeadline({ type, days, includeNow, date, date2 }: any): { [key: string]: any } {
        switch (type) {
            case DateFilterRuleType.NotSet:
                return { expr: IssueFilterExpr.NOT_SET };
            case DateFilterRuleType.BeforeNow: {
                const expr = IssueFilterExpr.LESS_THAN;
                const value = moment().unix();
                const timeUnit = TimeUnit.TODAY;
                return { expr, value, timeUnit, direction: Direction.AHEAD };
            }
            case DateFilterRuleType.MoreThanNDaysAgo: {
                const expr = IssueFilterExpr.LESS_THAN;
                const value = moment().subtract(days, 'd').unix();
                const timeUnit = TimeUnit.EXACT_DATE;
                return { expr, value, timeUnit, direction: Direction.AGO };
            }
            case DateFilterRuleType.InNDaysFromNow: {
                if (includeNow) {
                    const expr = IssueFilterExpr.LESS_THAN;
                    const value = days;
                    const timeUnit = TimeUnit.DAYS;
                    const pairTimeUnit = TimeUnit.DAYS;
                    return { expr, value, timeUnit, pairValue: days, pairTimeUnit, direction: Direction.AHEAD, pairDirection: Direction.AHEAD };
                } else {
                    const expr = IssueFilterExpr.INTERVAL;
                    const value = 0;
                    const timeUnit = TimeUnit.DAYS;
                    const pairValue = days;
                    const pairTimeUnit = TimeUnit.DAYS;
                    return { expr, value, timeUnit, pairValue, pairTimeUnit, direction: Direction.AGO, pairDirection: Direction.AHEAD };
                }
            }
            case DateFilterRuleType.CustomEqual:
                return { expr: IssueFilterExpr.EQUALS, ...convertDate(date) };
            case DateFilterRuleType.CustomLess:
                return { expr: IssueFilterExpr.LESS_THAN, ...convertDate(date) };
            case DateFilterRuleType.CustomLessOrEqual:
                return { expr: IssueFilterExpr.LESS_OR_EQUAL_THAN, ...convertDate(date) };
            case DateFilterRuleType.CustomMore:
                return { expr: IssueFilterExpr.MORE_THAN, ...convertDate(date) };
            case DateFilterRuleType.CustomMoreOrEqual:
                return { expr: IssueFilterExpr.MORE_OR_EQUAL_THAN, ...convertDate(date) };
            case DateFilterRuleType.CustomBetween: {
                const { value: pairValue, direction: pairDirection, timeUnit: pairTimeUnit } = convertDate(date2);
                return { expr: IssueFilterExpr.INTERVAL, ...convertDate(date), pairValue, pairDirection, pairTimeUnit };
            }
        }

        function convertDate(dateObj: any) {
            const shiftForAbsoluteDates = makeShiftForAbsoluteDates();
            const { isRelative, value, units } = dateObj;
            if (!isRelative) {
                return {
                    timeUnit: TimeUnit.EXACT_DATE,
                    value: moment(value, 'DD-MM-YYYY').add(shiftForAbsoluteDates, 'second').unix(),
                    direction: Direction.AHEAD,
                };
            }
            const timeUnit = [
                TimeUnit.DAYS,
                TimeUnit.WEEKS,
                TimeUnit.MONTHS,
                TimeUnit.YEARS,
            ][units];
            const direction = Math.sign(dateObj.value) === 1 ? Direction.AHEAD : Direction.AGO;
            const valueAbs = Math.abs(dateObj.value);
            return { timeUnit, direction, value: valueAbs };
        }
        return {};
    }
    public static dateFilter({ type, days, date, date2 }: any): { [key: string]: any } {
        switch (type) {
            case DateFilterRuleType.MoreThanNDaysAgo: {
                const expr = IssueFilterExpr.LESS_THAN;
                const value = moment().subtract(days, 'd').unix();
                const timeUnit = TimeUnit.EXACT_DATE;
                return { expr, value, timeUnit };
            }
            case DateFilterRuleType.WithinLastNDays: {
                const expr = IssueFilterExpr.INTERVAL;
                const value = moment().subtract(days, 'd').unix();
                const timeUnit = TimeUnit.EXACT_DATE;
                const pairValue = moment().unix();
                const pairTimeUnit = TimeUnit.EXACT_DATE;
                return { expr, value, timeUnit, pairValue, pairTimeUnit };
            }
            case DateFilterRuleType.CustomEqual:
                return { expr: IssueFilterExpr.EQUALS, ...convertDate(date) };
            case DateFilterRuleType.CustomLess:
                return { expr: IssueFilterExpr.LESS_THAN, ...convertDate(date) };
            case DateFilterRuleType.CustomLessOrEqual:
                return { expr: IssueFilterExpr.LESS_OR_EQUAL_THAN, ...convertDate(date) };
            case DateFilterRuleType.CustomMore:
                return { expr: IssueFilterExpr.MORE_THAN, ...convertDate(date) };
            case DateFilterRuleType.CustomMoreOrEqual:
                return { expr: IssueFilterExpr.MORE_OR_EQUAL_THAN, ...convertDate(date) };
            case DateFilterRuleType.CustomBetween: {
                const { value: pairValue, timeUnit: pairTimeUnit } = convertDate(date2);
                return { expr: IssueFilterExpr.INTERVAL, ...convertDate(date), pairValue, pairTimeUnit };
            }
        }

        function convertDate(dateObj: any) {
            const shiftForAbsoluteDates = makeShiftForAbsoluteDates();
            const { isRelative, value, units } = dateObj;
            if (!isRelative) {
                return {
                    timeUnit: TimeUnit.EXACT_DATE,
                    value: moment(value, 'DD-MM-YYYY').add(shiftForAbsoluteDates, 'second').unix(),
                };
            }
            const timeUnit = [
                TimeUnit.DAYS,
                TimeUnit.WEEKS,
                TimeUnit.MONTHS,
                TimeUnit.YEARS,
            ][units];
            const direction = Math.sign(dateObj.value) === 1 ? Direction.AHEAD : Direction.AGO;
            const valueAbs = dateObj.value >= 0 ? 0 : Math.abs(dateObj.value);
            if (direction === Direction.AGO) {
                return { timeUnit, value: valueAbs };
            } else {
                return {
                    timeUnit: TimeUnit.EXACT_DATE,
                    value: getUtcMidday().add(valueAbs, TimeUnitStrings[timeUnit]).unix(),
                };
            }
        }
        return {};
    }

    public isValidParams() {
        if (!this.type && !this.expr) {
            return false;
        }
        if (this.expr === IssueFilterExpr.NOT_SET) {
            return true;
        } else {
            return (!!this.value) && (Array.isArray(this.value) ? this.value.length > 0 : true);
        }
    }

    public getParams() {
        let pairValue: any = undefined;
        let pairTimeUnit: any = undefined;
        let pairDirection: any = undefined;

        if (this.expr === IssueFilterExpr.INTERVAL) {
            // We should send pairValue only for INTERVAL expression.
            pairValue = (this.pairValue instanceof Date) ? timestampToServer(Number(this.pairValue)) : this.pairValue;
            pairTimeUnit = this.pairTimeUnit;
            pairDirection = this.pairDirection;
        }

        return {
            uuid: this.uuid,
            type: this.type,
            expr: this.expr,
            value: (this.value instanceof Date) ? timestampToServer(Number(this.value)) : this.value,
            timeUnit: this.timeUnit,
            direction: this.direction,
            pairValue,
            pairTimeUnit,
            pairDirection,
        };
    }
}

export class IssuesFilterConstructor {
    public static fromProtobuf(rule: IssueFilterSetRule, projectFieldVariants: FieldVariants, projectMembers: ProjectMember[]) {
        const fieldVariants = getFieldVariants(projectFieldVariants, projectMembers);

        // Don't remove rules! Only extend! Because old rules should be supported for backward compatibility with desktop app
        switch (rule.RuleType) {
            case RuleTypeEnum.ByType:
                return {
                    filter: new IssuesFilterIssuesType({
                        type: IssuesFilterType.type, // legacy IssuesFilterType.type, supported here for backward compatibility with app
                        expr: FromProtobuf.convertExclusiveExpression(rule.FilterSet),
                        value: rule.Items,
                    }),
                    broken: BrokenFilterEnum.brokenOnFrontend,
                };
            case RuleTypeEnum.ByAssignee: {
                const legalValues = fieldVariants.assignees.concat([currentUserKey, userNotSetKey]);
                // Assignee and Reporter Not Set should be done in https://revizto.atlassian.net/browse/WEB-9179
                const missing = _.difference(rule.Items, legalValues);
                return {
                    filter: new IssuesFilterInclusionType({
                        type: IssuesFilterType.assignee,
                        expr: FromProtobuf.convertExclusiveExpression(rule.FilterSet),
                        value: rule.Items,
                    }),
                    // broken: missing.length ? BrokenFilterEnum.brokenInApp : BrokenFilterEnum.notBroken,
                    broken: BrokenFilterEnum.notBroken, // temporary
                    missing,
                };
            }
            case RuleTypeEnum.ByReporter: {
                const legalValues = fieldVariants.reporters.concat([currentUserKey, userNotSetKey]);
                const missing = _.difference(rule.Items, legalValues);
                return {
                    filter: new IssuesFilterInclusionType({
                        type: IssuesFilterType.reporter,
                        expr: FromProtobuf.convertExclusiveExpression(rule.FilterSet),
                        value: rule.Items,
                    }),
                    // broken: missing.length ? BrokenFilterEnum.brokenInApp : BrokenFilterEnum.notBroken,
                    broken: BrokenFilterEnum.notBroken, // temporary
                    missing,
                };
            }
            case RuleTypeEnum.ByWatchers: {
                const legalValues = fieldVariants.assignees.concat([currentUserKey, userNotSetKey]);
                const missing = _.difference(rule.Items, legalValues);
                return {
                    filter: new IssuesFilterInclusionType({
                        type: IssuesFilterType.watchers,
                        expr: FromProtobuf.convertNonExclusiveExpression(rule.FilterSet),
                        value: rule.Items,
                    }),
                    // broken: missing.length ? BrokenFilterEnum.brokenInApp : BrokenFilterEnum.notBroken,
                    broken: BrokenFilterEnum.notBroken, // temporary
                    missing,
                };
            }
            case RuleTypeEnum.ByPriority: {
                const legalValues = [0, 1, 2, 3, 4, 5].map(String);
                const missing = _.difference(rule.Items, legalValues);
                return {
                    filter: new IssuesFilterPriority({
                        type: IssuesFilterType.priority,
                        expr: FromProtobuf.convertExclusiveExpression(rule.FilterSet),
                        value: rule.Items.map(FromProtobuf.numberToPriority),
                    }),
                    broken: missing.length ? BrokenFilterEnum.brokenInApp : BrokenFilterEnum.notBroken,
                    missing,
                };
            }
            case RuleTypeEnum.ByStatus: {
                const legalValues = [0, 1, 2, 3].map(String);
                const missing = _.difference(rule.Items, legalValues);
                return {
                    filter: new IssuesFilterInclusionType({
                        type: IssuesFilterType.status,
                        expr: FromProtobuf.convertExclusiveExpression(rule.FilterSet),
                        value: rule.Items.map(FromProtobuf.numberToStatus),
                    }),
                    broken: missing.length ? BrokenFilterEnum.brokenInApp : BrokenFilterEnum.notBroken,
                    missing,
                };
            }
            case RuleTypeEnum.ByTag: {
                const legalValues = fieldVariants.tags.concat(noTagKey);
                const missing = _.difference(rule.Items, legalValues);
                const value = rule.Items.map((item: string) => item === noTagKey ? ValueNotSet : item);
                return {
                    filter: new IssuesFilterInclusionType({
                        type: IssuesFilterType.tags,
                        expr: FromProtobuf.convertNonExclusiveExpression(rule.FilterSet),
                        value,
                    }),
                    broken: missing.length ? BrokenFilterEnum.brokenInApp : BrokenFilterEnum.notBroken,
                    missing,
                };
            }
            case RuleTypeEnum.BySheets: {
                const fieldVariantsByExternalUuid = _.keyBy(fieldVariants.mainSheets, 'externalUuid');
                const isCreatedOn = rule.Items.includes(CREATED_ON_MODE_GUID);
                const value = rule.Items
                    .filter((externalUuid: string) => externalUuid !== CREATED_ON_MODE_GUID)
                    .map((externalUuid: string) => fieldVariantsByExternalUuid[externalUuid]?.uuid || externalUuid);
                const missing = _.difference(value, fieldVariants.mainSheets.map(({ uuid }: any) => uuid));
                const hasCurrentSheet = value.includes(currentSheetKey);
                let broken;
                if (!isCreatedOn || hasCurrentSheet) {
                    broken = BrokenFilterEnum.brokenOnFrontend;
                } else if (missing.length) {
                    broken = BrokenFilterEnum.brokenInApp;
                } else {
                    broken = BrokenFilterEnum.notBroken;
                }
                return {
                    filter: new IssuesFilterInclusionType({
                        type: IssuesFilterType.mainSheet,
                        expr: FromProtobuf.convertExclusiveExpression(rule.FilterSet),
                        value,
                    }),
                    broken,
                    missing,
                };
            }
            case RuleTypeEnum.BySheetTags: {
                const legalValues = fieldVariants.mainSheetTags;
                    // .concat(ValueNotSetMainSheetTags); // this should be added when the task is done: https://revizto.atlassian.net/browse/WEB-9194
                const isCreatedOn = rule.Items.includes(CREATED_ON_MODE_GUID);
                const missing = _.difference(rule.Items, legalValues);
                const isMissingNotSet = missing.includes(ValueNotSetMainSheetTags); // this should be removed
                let broken;
                if (!isCreatedOn && isMissingNotSet) {
                    broken = BrokenFilterEnum.brokenOnFrontend;
                } else if (missing.length) {
                    broken = BrokenFilterEnum.brokenInApp;
                } else {
                    broken = BrokenFilterEnum.notBroken;
                }
                const value = rule.Items.filter((externalUuid: string) => externalUuid !== CREATED_ON_MODE_GUID);
                return {
                    filter: new IssuesFilterInclusionType({
                        type: IssuesFilterType.mainSheetTag,
                        expr: FromProtobuf.convertNonExclusiveExpression(rule.FilterSet),
                        value,
                    }),
                    broken,
                    missing,
                };
            }
            case RuleTypeEnum.ByDiscipline: {
                const legalItems = [
                    'None',
                    'Architecture',
                    'Architectural',
                    'Structure',
                    'Structural',
                    'Mechanical',
                    'Electrical',
                    'Pipes',
                    'Plumbing',
                    'Mask',
                    'Coordination',
                ];
                const missing = _.difference(rule.Items, legalItems);
                return {
                    filter: new IssuesFilterClashDiscipline({
                        type: IssuesFilterType.clashDiscipline,
                        expr: FromProtobuf.convertNonExclusiveExpression(rule.FilterSet),
                        value: rule.Items.map(enumDisciplineTypeNormalizeFn),
                    }),
                    broken: missing.length ? BrokenFilterEnum.brokenInApp : BrokenFilterEnum.notBroken,
                    missing,
                };
            }
            case RuleTypeEnum.ByCategory: {
                const legalItems = fieldVariants.clashCategories;
                const missing = _.difference(rule.Items, legalItems);
                return {
                    filter: new IssuesFilterInclusionType({
                        type: IssuesFilterType.clashCategory,
                        expr: FromProtobuf.convertNonExclusiveExpression(rule.FilterSet),
                        value: rule.Items,
                    }),
                    broken: missing.length ? BrokenFilterEnum.brokenInApp : BrokenFilterEnum.notBroken,
                    missing,
                };
            }
            case RuleTypeEnum.ByLevel: {
                const legalItems = fieldVariants.clashLevels;
                const missing = _.difference(rule.Items, legalItems);
                return {
                    filter: new IssuesFilterInclusionType({
                        type: IssuesFilterType.clashLevel,
                        expr: FromProtobuf.convertNonExclusiveExpression(rule.FilterSet),
                        value: rule.Items,
                    }),
                    broken: missing.length ? BrokenFilterEnum.brokenInApp : BrokenFilterEnum.notBroken,
                    missing,
                };
            }
            case RuleTypeEnum.BySourceFile: {
                const legalItems = fieldVariants.clashSourceFiles;
                // fieldVariants don't have all the variants on the web, this will be fixed in https://revizto.atlassian.net/browse/WEB-8753
                // As for now, the filter is 'broken'
                const missing = _.difference(rule.Items, legalItems);
                return {
                    filter: new IssuesFilterInclusionType({
                        type: IssuesFilterType.clashSourceFile,
                        expr: FromProtobuf.convertNonExclusiveExpression(rule.FilterSet),
                        value: rule.Items,
                    }),
                    broken: missing.length ? BrokenFilterEnum.brokenOnFrontend : BrokenFilterEnum.notBroken,
                    missing,
                };
            }
            case RuleTypeEnum.ByTest: {
                const legalItems = fieldVariants.clashTests;
                const missing = _.difference(rule.Items, legalItems);
                return {
                    filter: new IssuesFilterInclusionType({
                        type: IssuesFilterType.clashTest,
                        expr: FromProtobuf.convertNonExclusiveExpression(rule.FilterSet),
                        value: rule.Items,
                    }),
                    broken: missing.length ? BrokenFilterEnum.brokenInApp : BrokenFilterEnum.notBroken,
                    missing,
                };
            }
            case RuleTypeEnum.ByGridX: {
                const legalItems = fieldVariants.clashGridXs;
                const missing = _.difference(rule.Items, legalItems);
                return {
                    filter: new IssuesFilterInclusionType({
                        type: IssuesFilterType.clashGridX,
                        expr: FromProtobuf.convertNonExclusiveExpression(rule.FilterSet),
                        value: rule.Items,
                    }),
                    broken: missing.length ? BrokenFilterEnum.brokenInApp : BrokenFilterEnum.notBroken,
                    missing,
                };
            }
            case RuleTypeEnum.ByGridY: {
                const legalItems = fieldVariants.clashGridYs;
                const missing = _.difference(rule.Items, legalItems);
                return {
                    filter: new IssuesFilterInclusionType({
                        type: IssuesFilterType.clashGridY,
                        expr: FromProtobuf.convertNonExclusiveExpression(rule.FilterSet),
                        value: rule.Items,
                    }),
                    broken: missing.length ? BrokenFilterEnum.brokenInApp : BrokenFilterEnum.notBroken,
                    missing,
                };
            }
            case RuleTypeEnum.ByType2:
            case RuleTypeEnum.ByType3: {
                const legalTypes = ['Regular', 'Clash'];
                const missing = _.difference(rule.Items, legalTypes);
                const fixedRuleItems = rule.Items.map((type: string) => (IssueTypes as any)[type.toLowerCase()]);
                return {
                    filter: new IssuesFilterIssuesType({
                        type: 'type', // legacy IssuesFilterType.type
                        expr: FromProtobuf.convertExclusiveExpression(rule.FilterSet),
                        value: fixedRuleItems,
                    }),
                    broken: missing.length ? BrokenFilterEnum.brokenInApp : BrokenFilterEnum.notBroken,
                    missing,
                };
            }
            case RuleTypeEnum.ByCreatedFrom: {
                const validValues = ['2D', '3D', 'None'];
                const isValid = rule.Items.every((type: string) => validValues.includes(type));
                const missing = rule.Items.filter((type: string) => !validValues.includes(type));
                return {
                    filter: new IssuesFilterIssuesCreatedFrom({
                        type: IssuesFilterType.binding,
                        expr: FromProtobuf.convertExclusiveExpression(rule.FilterSet),
                        value: rule.Items,
                    }),
                    broken: isValid ? BrokenFilterEnum.notBroken : BrokenFilterEnum.brokenInApp,
                    missing,
                };
            }
            case RuleTypeEnum.ByStampAbbrr:
            case RuleTypeEnum.ByStamp2: {
                const legalItems = [
                    ...fieldVariants.stamps.map(({ abbreviation }: any) => abbreviation),
                    ANY_STAMP_UUID,
                ];
                const missing = _.difference(rule.Items, legalItems);
                let broken;
                if (missing.length) {
                    broken = BrokenFilterEnum.brokenInApp;
                } else {
                    broken = BrokenFilterEnum.notBroken;
                }
                return {
                    filter: new IssuesFilterInclusionType({
                        type: IssuesFilterType.stampAbbr,
                        expr: FromProtobuf.convertExclusiveExpression(rule.FilterSet),
                        value: rule.Items,
                    }),
                    broken,
                    missing,
                };
            }
            case RuleTypeEnum.ByStampCategory: {
                const legalItems = fieldVariants.stampCategories.map(({ title }: any) => title).concat('No Category');
                const missing = _.difference(rule.Items, legalItems);
                const fieldVariantsObj = _.keyBy(fieldVariants.stampCategories, 'title');
                const fixedRuleItems = rule.Items.map((item: string) => {
                    return item === 'No Category' ? 'noCategory' : fieldVariantsObj[item]?.uuid;
                });
                return {
                    filter: new IssuesFilterInclusionType({
                        type: IssuesFilterType.stampCategory,
                        expr: FromProtobuf.convertExclusiveExpression(rule.FilterSet),
                        value: fixedRuleItems,
                    }),
                    broken: missing.length ? BrokenFilterEnum.brokenInApp : BrokenFilterEnum.notBroken,
                    missing,
                };
            }
            case RuleTypeEnum.ByStampCategoryByTitle: {
                // don't see this filter either in the app or on the frontend
                return {
                    filter: new IssuesFilterInclusionType({
                        type: IssuesFilterType.stampCategoryByTitle,
                        expr: FromProtobuf.convertExclusiveExpression(rule.FilterSet),
                        value: rule.Items,
                    }),
                    broken: BrokenFilterEnum.brokenOnFrontend,
                };
            }
            case RuleTypeEnum.ByStampColor: {
                const legalValues = Object.values(StampColorsPaletteRGBEnum).filter(Number.isInteger).map(String);
                const missing = _.difference(rule.Items, legalValues);
                return {
                    filter: new IssuesFilterInclusionType({
                        type: IssuesFilterType.stampColor,
                        expr: FromProtobuf.convertExclusiveExpression(rule.FilterSet),
                        value: rule.Items,
                    }),
                    broken: missing.length > 0 ? BrokenFilterEnum.brokenInApp : BrokenFilterEnum.notBroken,
                    missing,
                };
            }
            case RuleTypeEnum.ByAssigneeCompany: {
                const legalValues = fieldVariants.assigneeCompanies.concat(assigneeAddFieldNotSetKey);
                const missing = _.difference(rule.Items, legalValues);
                const value = rule.Items.map((item: string) => {
                    return item === assigneeAddFieldNotSetKey ? ValueNotSet : item;
                });
                return {
                    filter: new IssuesFilterInclusionType({
                        type: IssuesFilterType.assigneeCompany,
                        expr: FromProtobuf.convertExclusiveExpression(rule.FilterSet),
                        value,
                    }),
                    broken: missing.length > 0 ? BrokenFilterEnum.brokenInApp : BrokenFilterEnum.notBroken,
                    missing,
                };
            }
            case RuleTypeEnum.ByAssigneeDepartment: {
                const legalValues = fieldVariants.assigneeDepartments.concat(assigneeAddFieldNotSetKey);
                const missing = _.difference(rule.Items, legalValues);
                const value = rule.Items.map((item: string) => {
                    return item === assigneeAddFieldNotSetKey ? ValueNotSet : item;
                });
                return {
                    filter: new IssuesFilterInclusionType({
                        type: IssuesFilterType.assigneeDepartment,
                        expr: FromProtobuf.convertExclusiveExpression(rule.FilterSet),
                        value,
                    }),
                    broken: missing.length > 0 ? BrokenFilterEnum.brokenInApp : BrokenFilterEnum.notBroken,
                    missing,
                };
            }
            case RuleTypeEnum.ByAssigneeLocation: {
                const legalValues = fieldVariants.assigneeLocations.concat(assigneeAddFieldNotSetKey);
                const missing = _.difference(rule.Items, legalValues);
                const value = rule.Items.map((item: string) => {
                    return item === assigneeAddFieldNotSetKey ? ValueNotSet : item;
                });
                return {
                    filter: new IssuesFilterInclusionType({
                        type: IssuesFilterType.assigneeLocation,
                        expr: FromProtobuf.convertExclusiveExpression(rule.FilterSet),
                        value,
                    }),
                    broken: missing.length > 0 ? BrokenFilterEnum.brokenInApp : BrokenFilterEnum.notBroken,
                    missing,
                };
            }
            case RuleTypeEnum.ByArea: {
                const legalValues = fieldVariants.clashAreas;
                const missing = _.difference(rule.Items, legalValues);
                return {
                    filter: new IssuesFilterInclusionType({
                        type: IssuesFilterType.clashArea,
                        expr: FromProtobuf.convertNonExclusiveExpression(rule.FilterSet),
                        value: rule.Items,
                    }),
                    broken: missing.length > 0 ? BrokenFilterEnum.brokenInApp : BrokenFilterEnum.notBroken,
                    missing,
                };
            }
            case RuleTypeEnum.ByRoom: {
                const legalValues = fieldVariants.clashRooms;
                const missing = _.difference(rule.Items, legalValues);
                return {
                    filter: new IssuesFilterInclusionType({
                        type: IssuesFilterType.clashRoom,
                        expr: FromProtobuf.convertNonExclusiveExpression(rule.FilterSet),
                        value: rule.Items,
                    }),
                    broken: missing.length > 0 ? BrokenFilterEnum.brokenInApp : BrokenFilterEnum.notBroken,
                    missing,
                };
            }
            case RuleTypeEnum.BySpace: {
                const legalValues = fieldVariants.clashSpaces;
                const missing = _.difference(rule.Items, legalValues);
                return {
                    filter: new IssuesFilterInclusionType({
                        type: IssuesFilterType.clashSpace,
                        expr: FromProtobuf.convertNonExclusiveExpression(rule.FilterSet),
                        value: rule.Items,
                    }),
                    broken: missing.length > 0 ? BrokenFilterEnum.brokenInApp : BrokenFilterEnum.notBroken,
                    missing,
                };
            }
            case RuleTypeEnum.ByZone: {
                const legalValues = fieldVariants.clashZones;
                const missing = _.difference(rule.Items, legalValues);
                return {
                    filter: new IssuesFilterInclusionType({
                        type: IssuesFilterType.clashZone,
                        expr: FromProtobuf.convertNonExclusiveExpression(rule.FilterSet),
                        value: rule.Items,
                    }),
                    broken: missing.length > 0 ? BrokenFilterEnum.brokenInApp : BrokenFilterEnum.notBroken,
                    missing,
                };
            }
            case RuleTypeEnum.ByPrivacy: {
                const value = rule.Items.map((item: string) => 1 - Number(item));
                const legalValues = [0, 1].map(String);
                const missing = _.difference(rule.Items, legalValues);
                return {
                    filter: new IssuesFilterInclusionType({
                        type: IssuesFilterType.visibility,
                        expr: FromProtobuf.convertExclusiveExpression(rule.FilterSet),
                        value,
                    }),
                    broken: missing.length > 0 ? BrokenFilterEnum.brokenInApp : BrokenFilterEnum.notBroken,
                    missing,
                };
            }
            case RuleTypeEnum.ByProcoreCoordinationIssueType:
                // Not done yet
                return {
                    filter: [1],
                    broken: BrokenFilterEnum.brokenOnFrontend,
                };
            case RuleTypeEnum.ByProcoreCoordinationIssueStatus:
                // Not done yet
                return {
                    filter: [1],
                    broken: BrokenFilterEnum.brokenOnFrontend,
                };
            case RuleTypeEnum.ByAttachedDocs:
                // Not done yet
                return {
                    filter: {
                        type: IssuesFilterType.attachedDocs,
                    },
                    broken: BrokenFilterEnum.brokenOnFrontend,
                };
            case RuleTypeEnum.ByProcoreRFIType:
                // Procore filters should be done in https://revizto.atlassian.net/browse/WEB-9190
                return {
                    filter: new IssuesFilterProcoreType({
                        type: IssuesFilterType.procoreType,
                        expr: FromProtobuf.convertExclusiveExpression(rule.FilterSet),
                        value: rule.Items,
                    }),
                    broken: BrokenFilterEnum.brokenOnFrontend,
                };
            case RuleTypeEnum.ByProcoreRFIStatus:
                return {
                    filter: new IssuesFilterProcoreStatus({
                        type: IssuesFilterType.procoreStatus,
                        expr: FromProtobuf.convertExclusiveExpression(rule.FilterSet),
                        value: rule.Items,
                    }),
                    broken: BrokenFilterEnum.brokenOnFrontend,
                };
            case RuleTypeEnum.ByDuration:
                return {
                    filter: new IssuesFilterDuration(rule),
                    broken: BrokenFilterEnum.notBroken,
                };
            case RuleTypeEnum.ByDeadline:
                return {
                    filter: new IssueTrackerFilterDeadline(rule),
                    broken: BrokenFilterEnum.notBroken,
                };
            case RuleTypeEnum.ByCreatedDate:
                return {
                    filter: new IssueTrackerFilterCreated(rule),
                    broken: BrokenFilterEnum.notBroken,
                };
            case RuleTypeEnum.ByClosedDate:
                return {
                    filter: new IssueTrackerFilterClosed(rule),
                    broken: BrokenFilterEnum.notBroken,
                };
            case RuleTypeEnum.ByCustomStatus: {
                return {
                    filter: new IssuesFilterInclusionType({
                        type: IssuesFilterType.customStatus,
                        expr: FromProtobuf.convertExclusiveExpression(rule.FilterSet),
                        value: rule.Items,
                    }),
                    broken: BrokenFilterEnum.notBroken,
                };
            }
            case RuleTypeEnum.ByCustomType: {
                return {
                    filter: new IssuesFilterInclusionType({
                        type: IssuesFilterType.customType,
                        expr: FromProtobuf.convertExclusiveExpression(rule.FilterSet),
                        value: rule.Items,
                    }),
                    broken: BrokenFilterEnum.notBroken,
                };
            }
            case RuleTypeEnum.ByStatusCategory: {
                return {
                    filter: new IssuesFilterInclusionType({
                        type: IssuesFilterType.statusCategory,
                        expr: FromProtobuf.convertExclusiveExpression(rule.FilterSet),
                        value: rule.Items,
                    }),
                    broken: BrokenFilterEnum.notBroken,
                };
            }
            case RuleTypeEnum.ByDeletedAt: {
                return {
                    filter: new IssueTrackerFilterDeletedAt(rule),
                    broken: BrokenFilterEnum.notBroken,
                };
            }
            default:
                throw { message: 'Unknown type of IssueFilter Rule: ' + rule.RuleType };
        }
    }

    public static encodeToProtobuf(filter: any, projectFieldVariants: FieldVariants, projectMembers: ProjectMember[]): IssueFilterSetRule {
        if (!filter.isActive) {
            return null;
        }

        const fieldVariants = getFieldVariants(projectFieldVariants, projectMembers);

        switch (filter.type) {
            case IssuesFilterType.type: {
                // legacy
                const allTypes = [IssueTypes.regular, IssueTypes.clash];
                const selections = filter.modifier === IssueFilterExpr.NOT_IN
                    ? _.difference(allTypes, filter.selections)
                    : filter.selections;

                return selections.length ? new IssueFilterSetRule({
                    RuleType: RuleTypeEnum.ByType3,
                    FilterSet: ToProtobuf.convertExpression(filter.modifier),
                    Items: selections.map(ToProtobuf.typeNumberToString),
                }) : null;
            }
            case IssuesFilterType.assignee:
                return new IssueFilterSetRule({
                    RuleType: RuleTypeEnum.ByAssignee,
                    FilterSet: ToProtobuf.convertExpression(filter.modifier),
                    Items: filter.selections,
                });
            case IssuesFilterType.reporter:
                return new IssueFilterSetRule({
                    RuleType: RuleTypeEnum.ByReporter,
                    FilterSet: ToProtobuf.convertExpression(filter.modifier),
                    Items: filter.selections,
                });
            case IssuesFilterType.watchers:
                return new IssueFilterSetRule({
                    RuleType: RuleTypeEnum.ByWatchers,
                    FilterSet: ToProtobuf.convertExpression(filter.modifier),
                    Items: filter.selections,
                });
            case IssuesFilterType.priority:
                return new IssueFilterSetRule({
                    RuleType: RuleTypeEnum.ByPriority,
                    FilterSet: ToProtobuf.convertExpression(filter.modifier),
                    Items: filter.selections.map(ToProtobuf.priorityToNumberString),
                });
            case IssuesFilterType.status:
                // legacy
                return new IssueFilterSetRule({
                    RuleType: RuleTypeEnum.ByStatus,
                    FilterSet: ToProtobuf.convertExpression(filter.modifier),
                    Items: filter.selections.map(ToProtobuf.statusToNumber),
                });
            case IssuesFilterType.tags: {
                const Items = filter.selections.map((item: string) => item === ValueNotSet ? noTagKey : item);
                return new IssueFilterSetRule({
                    RuleType: RuleTypeEnum.ByTag,
                    FilterSet: ToProtobuf.convertExpression(filter.modifier),
                    Items,
                });
            }
            case IssuesFilterType.mainSheetTag:
                return new IssueFilterSetRule({
                    RuleType: RuleTypeEnum.BySheetTags,
                    FilterSet: ToProtobuf.convertExpression(filter.modifier),
                    Items: filter.selections,
                });
            case IssuesFilterType.clashDiscipline:
                return new IssueFilterSetRule({
                    RuleType: RuleTypeEnum.ByDiscipline,
                    FilterSet: ToProtobuf.convertExpression(filter.modifier),
                    Items: filter.selections.map(numberToDiscipline),
                });
            case IssuesFilterType.clashCategory:
                return new IssueFilterSetRule({
                    RuleType: RuleTypeEnum.ByCategory,
                    FilterSet: ToProtobuf.convertExpression(filter.modifier),
                    Items: filter.selections,
                });
            case IssuesFilterType.clashLevel:
                return new IssueFilterSetRule({
                    RuleType: RuleTypeEnum.ByLevel,
                    FilterSet: ToProtobuf.convertExpression(filter.modifier),
                    Items: filter.selections,
                });
            case IssuesFilterType.clashSourceFile:
                return new IssueFilterSetRule({
                    RuleType: RuleTypeEnum.BySourceFile,
                    FilterSet: ToProtobuf.convertExpression(filter.modifier),
                    Items: filter.selections,
                });
            case IssuesFilterType.clashTest:
                return new IssueFilterSetRule({
                    RuleType: RuleTypeEnum.ByTest,
                    FilterSet: ToProtobuf.convertExpression(filter.modifier),
                    Items: filter.selections,
                });
            case IssuesFilterType.clashGridX:
                return new IssueFilterSetRule({
                    RuleType: RuleTypeEnum.ByGridX,
                    FilterSet: ToProtobuf.convertExpression(filter.modifier),
                    Items: filter.selections,
                });
            case IssuesFilterType.clashGridY:
                return new IssueFilterSetRule({
                    RuleType: RuleTypeEnum.ByGridY,
                    FilterSet: ToProtobuf.convertExpression(filter.modifier),
                    Items: filter.selections,
                });
            case IssuesFilterType.binding:
                return new IssueFilterSetRule({
                    RuleType: RuleTypeEnum.ByCreatedFrom,
                    FilterSet: ToProtobuf.convertExpression(filter.modifier),
                    Items: filter.selections.map(ToProtobuf.bindingNumberToString),
                });
            case IssuesFilterType.stampAbbr:
                return new IssueFilterSetRule({
                    RuleType: RuleTypeEnum.ByStamp2,
                    FilterSet: ToProtobuf.convertExpression(filter.modifier),
                    Items: filter.selections,
                });
            case IssuesFilterType.stampCategory: {
                const fieldVariantsObj = _.keyBy(fieldVariants.stampCategories, 'uuid');
                const Items = filter.selections.map((elem: string) => {
                    return elem === 'noCategory' ? 'No Category' : fieldVariantsObj[elem]?.title;
                });
                return new IssueFilterSetRule({
                    RuleType: RuleTypeEnum.ByStampCategory,
                    FilterSet: ToProtobuf.convertExpression(filter.modifier),
                    Items,
                });
            }
            case IssuesFilterType.stampColor:
                return new IssueFilterSetRule({
                    RuleType: RuleTypeEnum.ByStampColor,
                    FilterSet: ToProtobuf.convertExpression(filter.modifier),
                    Items: filter.selections,
                });
            case IssuesFilterType.assigneeCompany: {
                const Items = filter.selections.map((elem: string) => {
                    return elem === ValueNotSet ? assigneeAddFieldNotSetKey : elem;
                });
                return new IssueFilterSetRule({
                    RuleType: RuleTypeEnum.ByAssigneeCompany,
                    FilterSet: ToProtobuf.convertExpression(filter.modifier),
                    Items,
                });
            }
            case IssuesFilterType.assigneeDepartment: {
                const Items = filter.selections.map((elem: string) => {
                    return elem === ValueNotSet ? assigneeAddFieldNotSetKey : elem;
                });
                return new IssueFilterSetRule({
                    RuleType: RuleTypeEnum.ByAssigneeDepartment,
                    FilterSet: ToProtobuf.convertExpression(filter.modifier),
                    Items,
                });
            }
            case IssuesFilterType.assigneeLocation: {
                const Items = filter.selections.map((elem: string) => {
                    return elem === ValueNotSet ? assigneeAddFieldNotSetKey : elem;
                });
                return new IssueFilterSetRule({
                    RuleType: RuleTypeEnum.ByAssigneeLocation,
                    FilterSet: ToProtobuf.convertExpression(filter.modifier),
                    Items,
                });
            }
            case IssuesFilterType.clashArea:
                return new IssueFilterSetRule({
                    RuleType: RuleTypeEnum.ByArea,
                    FilterSet: ToProtobuf.convertExpression(filter.modifier),
                    Items: filter.selections,
                });
            case IssuesFilterType.clashRoom:
                return new IssueFilterSetRule({
                    RuleType: RuleTypeEnum.ByRoom,
                    FilterSet: ToProtobuf.convertExpression(filter.modifier),
                    Items: filter.selections,
                });
            case IssuesFilterType.clashSpace:
                return new IssueFilterSetRule({
                    RuleType: RuleTypeEnum.BySpace,
                    FilterSet: ToProtobuf.convertExpression(filter.modifier),
                    Items: filter.selections,
                });
            case IssuesFilterType.clashZone:
                return new IssueFilterSetRule({
                    RuleType: RuleTypeEnum.ByZone,
                    FilterSet: ToProtobuf.convertExpression(filter.modifier),
                    Items: filter.selections,
                });
            case IssuesFilterType.visibility: {
                const Items = filter.selections.map((elem: number) => String(1 - elem));
                return new IssueFilterSetRule({
                    RuleType: RuleTypeEnum.ByPrivacy,
                    FilterSet: ToProtobuf.convertExpression(filter.modifier),
                    Items,
                });
            }
            case IssuesFilterType.deadline:
                return new IssueFilterSetRule({
                    RuleType: RuleTypeEnum.ByDeadline,
                    FilterSet: 1,
                    Items: ToProtobuf.dateFilterToItems(filter),
                });
            case IssuesFilterType.created:
                return new IssueFilterSetRule({
                    RuleType: RuleTypeEnum.ByCreatedDate,
                    FilterSet: 1,
                    Items: ToProtobuf.dateFilterToItems(filter),
                });
            case IssuesFilterType.closed:
                return new IssueFilterSetRule({
                    RuleType: RuleTypeEnum.ByClosedDate,
                    FilterSet: 1,
                    Items: ToProtobuf.dateFilterToItems(filter),
                });
            case IssuesFilterType.customType:
                return new IssueFilterSetRule({
                    RuleType: RuleTypeEnum.ByCustomType,
                    FilterSet: ToProtobuf.convertExpression(filter.modifier),
                    Items: filter.selections,
                });
            case IssuesFilterType.customStatus:
                return new IssueFilterSetRule({
                    RuleType: RuleTypeEnum.ByCustomStatus,
                    FilterSet: ToProtobuf.convertExpression(filter.modifier),
                    Items: filter.selections,
                });
            case IssuesFilterType.statusCategory:
                return new IssueFilterSetRule({
                    RuleType: RuleTypeEnum.ByStatusCategory,
                    FilterSet: ToProtobuf.convertExpression(filter.modifier),
                    Items: filter.selections,
                });
            case IssuesFilterType.duration:
                return new IssueFilterSetRule({
                    RuleType: RuleTypeEnum.ByDuration,
                    Items: ToProtobuf.durationFilterToItems(filter),
                });
            default:
                return null;
        }
    }

    public static dateFilter(rule: IssueFilterSetRule): { [key: string]: any } {
        const ruleObj: any = JSON.parse(rule.Items[0]);
        const { type, days, includeNow, date, date2 } = ruleObj;

        switch (type) {
            case DateFilterRuleType.NotSet:
                return { expr: IssueFilterExpr.NOT_SET };
            case DateFilterRuleType.BeforeNow: {
                const expr = IssueFilterExpr.LESS_THAN;
                const value = moment().unix();
                const timeUnit = TimeUnit.EXACT_DATE;
                return { expr, value, timeUnit };
            }
            case DateFilterRuleType.MoreThanNDaysAgo: {
                const expr = IssueFilterExpr.LESS_THAN;
                const value = moment().subtract(days, 'd').unix();
                const timeUnit = TimeUnit.EXACT_DATE;
                return { expr, value, timeUnit };
            }
            case DateFilterRuleType.WithinLastNDays: {
                const expr = IssueFilterExpr.INTERVAL;
                const value = moment().subtract(days, 'd').unix();
                const timeUnit = TimeUnit.EXACT_DATE;
                const pairValue = moment().unix();
                const pairTimeUnit = TimeUnit.EXACT_DATE;
                return { expr, value, timeUnit, pairValue, pairTimeUnit };
            }
            case DateFilterRuleType.InNDaysFromNow: {
                const expr = IssueFilterExpr.INTERVAL;
                const value = includeNow ? 0 : moment().add(1, 'd').unix();
                const timeUnit = TimeUnit.EXACT_DATE;
                const pairValue = moment().add(days, 'd').unix();
                const pairTimeUnit = TimeUnit.EXACT_DATE;
                return { expr, value, timeUnit, pairValue, pairTimeUnit };
            }
            case DateFilterRuleType.CustomEqual:
                return { expr: IssueFilterExpr.EQUALS, ...convertDate(date) };
            case DateFilterRuleType.CustomLess:
                return { expr: IssueFilterExpr.LESS_THAN, ...convertDate(date) };
            case DateFilterRuleType.CustomLessOrEqual:
                return { expr: IssueFilterExpr.LESS_OR_EQUAL_THAN, ...convertDate(date) };
            case DateFilterRuleType.CustomMore:
                return { expr: IssueFilterExpr.MORE_THAN, ...convertDate(date) };
            case DateFilterRuleType.CustomMoreOrEqual:
                return { expr: IssueFilterExpr.MORE_OR_EQUAL_THAN, ...convertDate(date) };
            case DateFilterRuleType.CustomBetween: {
                const { value: pairValue, direction: pairDirection, timeUnit: pairTimeUnit } = convertDate(date2);
                return { expr: IssueFilterExpr.INTERVAL, ...convertDate(date), pairValue, pairDirection, pairTimeUnit };
            }
        }

        function convertDate(dateObj: any) {
            const { isRelative, value, units } = dateObj;
            if (!isRelative) {
                return {
                    timeUnit: TimeUnit.EXACT_DATE,
                    value: moment(value, 'DD-MM-YYYY').unix(),
                };
            }
            if (value === 0) {
                return {
                    timeUnit: TimeUnit.EXACT_DATE,
                    value: moment().unix(),
                };
            }
            const timeUnit = [
                TimeUnit.DAYS,
                TimeUnit.WEEKS,
                TimeUnit.MONTHS,
            ][units];
            const direction = Math.sign(dateObj.value) === 1 ? Direction.AHEAD : Direction.AGO;
            const valueAbs = Math.abs(dateObj.value);
            return { timeUnit, direction, value: valueAbs };
        }
        return {};
    }

    constructor(filter: any = {}) {
        switch (filter.type) {
            case IssuesFilterType.project:
            case IssuesFilterType.assignee:
            case IssuesFilterType.reporter:
            case IssuesFilterType.mainSheet:
            case IssuesFilterType.stampAbbr:
            case IssuesFilterType.stampCategory:
            case IssuesFilterType.stampCategoryByTitle:
            case IssuesFilterType.stampColor:
            case IssuesFilterType.assigneeCompany:
            case IssuesFilterType.assigneeDepartment:
            case IssuesFilterType.assigneeLocation:
            case IssuesFilterType.reporterCompany:
            case IssuesFilterType.reporterDepartment:
            case IssuesFilterType.reporterLocation:
            case IssuesFilterType.visibility:
            case IssuesFilterType.customStatus:
            case IssuesFilterType.customType:
            case IssuesFilterType.statusCategory:
                return new IssuesFilterInclusionType(filter);
            case IssuesFilterType.clashLevel:
            case IssuesFilterType.clashArea:
            case IssuesFilterType.clashRoom:
            case IssuesFilterType.clashZone:
            case IssuesFilterType.clashSourceFile:
            case IssuesFilterType.clashTest:
            case IssuesFilterType.clashGridX:
            case IssuesFilterType.clashGridY:
            case IssuesFilterType.clashCategory:
            case IssuesFilterType.clashSpace:
            case IssuesFilterType.watchers:
            case IssuesFilterType.tags:
            case IssuesFilterType.projectTags:
            case IssuesFilterType.mainSheetTag:
            case IssuesFilterType.status: // Legacy filter will be converted to customStatus
                return new IssuesFilterInclusionExtendType(filter);
            case IssuesFilterType.clashDiscipline:
                return new IssuesFilterClashDiscipline(filter);
            case IssuesFilterType.priority:
                return new IssuesFilterPriority(filter);
            case IssuesFilterType.type: // Legacy filter will be converted to customType
                return new IssuesFilterIssuesType(filter);
            case IssuesFilterType.procoreType:
                return new IssuesFilterProcoreType(filter);
            case IssuesFilterType.procoreStatus:
                return new IssuesFilterProcoreStatus(filter);
            case IssuesFilterType.created:
            case IssuesFilterType.closed:
                return new IssuesFilterConditionDateType(filter);
            case IssuesFilterType.duration:
                return new IssuesFilterDuration(filter);
            case IssuesFilterType.deadline:
            case IssuesFilterType.deletedAt:
                return new IssuesFilterDate(filter);
            case IssuesFilterType.title:
                return new IssuesFilterInputType(filter);
            case IssuesFilterType.binding:
            case IssuesFilterType.createdFrom: {
                filter.type = IssuesFilterType.createdFrom;
                return new IssuesFilterIssuesCreatedFrom(filter);
            }
            default:
                return new IssuesFilterInclusionType();
        }
    }
}
