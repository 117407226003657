import _ from 'lodash';
import Router from '@/router';
import {
    currentUserKey,
    IssueClashDiscipline,
    IssueFilterExpr,
    IssuesFilterType,
    ValueNotSet,
} from '@/constants';
import { NO_STAMP } from '@/constants/Stamps';
import { IIssueTrackerFilterValue } from '@/models/ProjectIssuesFilters';
import { Issue } from '@/models';
import { getIssueAssigneeMember } from '@/services/issueTracker/IssueAssigneeMember';
import { getStampCategoryUuid } from '@/services/issueTracker/StampCategoryUuid';
import { DurationFilterValue } from '@/services/issueTracker/IssueTrackerFilterByDurationValue';
import store from '@/storage';

const transformSelections = (selections: Array<(string | number)>) => {
    return selections.map((selectedValue) => (
        selectedValue === currentUserKey ? store.getters.userData.email : selectedValue
    ));
};

function getFieldVariantsMainSheet(): any[] {
    const projectId = Number(Router.currentRoute.params.projectId);
    return store.getters.fieldVariantsByProjectId(projectId).mainSheets || [];
}

// Don't export by index.ts in services dir for avoid dependency of Store in all services imports
const applyFilterToIssues = (filter: IIssueTrackerFilterValue, issues: Issue[]) => {
    if (!filter.isActive) {
        return issues;
    }

    let selections: any = (filter.type === IssuesFilterType.title) ? filter.selections : [...filter.selections || []];
    if ([IssuesFilterType.assignee, IssuesFilterType.watchers, IssuesFilterType.reporter].includes(filter.type)) {
        selections = transformSelections(selections);
    }
    if ([IssuesFilterType.clashDiscipline, IssuesFilterType.id, IssuesFilterType.binding].includes(filter.type)) {
        selections = selections.map(Number);
    }

    if (filter.type === IssuesFilterType.mainSheet) {
        const fieldVariantsByUuid = _.keyBy(getFieldVariantsMainSheet(), 'uuid');
        selections = selections.map((uuid: string) => fieldVariantsByUuid[uuid]?.externalUuid);
    }

    if (filter.type === IssuesFilterType.mainSheetTag) {
        const externalUuidsByTag: any = {};
        getFieldVariantsMainSheet().forEach((variant: any) => {
            if (_.isString(variant.tags)) {
                try {
                    const parsedTags = JSON.parse(variant.tags);
                    parsedTags.forEach((tag: string) => externalUuidsByTag[tag] = variant.externalUuid);
                } catch (e) {
                    window.console.error('Can\'t parse sheet tags: ', variant.tags, ' for variant: ', variant, ' error: ', e);
                }

                return;
            }

            if (_.isArray(variant.tags)) {
                variant.tags.forEach((tag: string) => externalUuidsByTag[tag] = variant.externalUuid);
            }
        });
        selections = selections.map((tag: any) => externalUuidsByTag[tag]);
    }

    return issues.filter((issue) => {
        let localValueNotSet: string | number = ValueNotSet;
        let compareFn = (a: any, b: any) => a === b;
        let issueValue;

        switch (filter.type) {
            case IssuesFilterType.title:
                issueValue = issue.title.toLowerCase();
                break;
            case IssuesFilterType.customType:
                issueValue = issue.customType;
                break;
            case IssuesFilterType.stampCategory:
                issueValue = getStampCategoryUuid(issue);
                break;
            case IssuesFilterType.assigneeCompany:
                issueValue = getIssueAssigneeMember(issue)?.company;
                break;
            case IssuesFilterType.assigneeDepartment:
                issueValue = getIssueAssigneeMember(issue)?.department;
                break;
            case IssuesFilterType.assigneeLocation:
                issueValue = getIssueAssigneeMember(issue)?.location;
                break;
            case IssuesFilterType.binding:
                issueValue = issue.createdFrom;
                break;
            case IssuesFilterType.mainSheet:
                issueValue = (issue.sheet as any).uuid;
                break;
            case IssuesFilterType.mainSheetTag:
                issueValue = [(issue.sheet as any).uuid];
                break;
            case IssuesFilterType.visibility:
                issueValue = issue.visibility;
                break;
            case IssuesFilterType.clashDiscipline: {
                if (!issue.isNavisClash) {
                    return filter.modifier === IssueFilterExpr.HAS_NOT_ALL_IN;
                }
                compareFn = (a: any, b: any) => {
                    if (a === IssueClashDiscipline.Coordination) {
                        return b === IssueClashDiscipline.Coordination;
                    }
                    return (a & b & 31) > 0;
                };
                localValueNotSet = IssueClashDiscipline.None;
                break;
            }
            case IssuesFilterType.clashLevel: {
                compareFn = (a: any, b: any) => {
                    if (!b) {
                        return false;
                    }
                    return a.toLowerCase() === b.toLowerCase();
                };
                break;
            }
            case IssuesFilterType.stampAbbr:
                localValueNotSet = NO_STAMP;
                break;
            default:
                issueValue = (issue as any)[filter.type];
        }

        switch (filter.modifier) {
            case IssueFilterExpr.INCLUDE:
                return issueValue.includes(selections) || (issueValue.includes(localValueNotSet));
            case IssueFilterExpr.IN:
                return selections.includes(issueValue) || (selections.includes(localValueNotSet) && !issueValue);
            case IssueFilterExpr.NOT_IN:
                return (issueValue && !selections.includes(issueValue))
                    || (selections.includes(localValueNotSet) && issueValue !== undefined)
                    || (!selections.includes(localValueNotSet) && issueValue === undefined);
            case IssueFilterExpr.HAS_ONE_IN:
                return _.intersectionWith(selections, issueValue, compareFn).length > 0
                    || (selections.includes(localValueNotSet) && issueValue[0] === undefined);
            case IssueFilterExpr.HAS_NOT_ALL_IN:
                return (!selections.includes(localValueNotSet) && _.intersectionWith(selections, issueValue, compareFn).length === 0)
                    || (selections.includes(localValueNotSet) && issueValue[0] !== undefined);
            case IssueFilterExpr.HAS_ALL_IN:
                return _.intersectionWith(selections, issueValue, compareFn).length === selections.length
                    || (selections.length === 1 && selections.includes(localValueNotSet) && issueValue[0] === undefined);
            default:
                if (filter.type === IssuesFilterType.duration) {
                    const durationFilter: any = filter;
                    if (durationFilter.dateFilterType == DurationFilterValue.MORE_THAN) {
                        return issue.duration > durationFilter.daysValue;
                    }
                    if (durationFilter.dateFilterType == DurationFilterValue.LESS_THAN) {
                        return issue.duration < durationFilter.daysValue;
                    }
                    if (durationFilter.dateFilterType == DurationFilterValue.EQUALS) {
                        return issue.duration === durationFilter.daysValue;
                    }
                    return false;
                }
                return false;
        }
    });
};

export default applyFilterToIssues;
