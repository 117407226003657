



































































import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { TranslateResult } from 'vue-i18n';
import IconSvg16 from '@/components/common/icon/IconSvg16.vue';
import WsTooltip from '@/components/common/WsTooltip.vue';

@Component({
    components: {
        WsTooltip,
        IconSvg16,
    },
})
export default class WsDropdown extends Vue {
    @Prop({ default: true }) public arrow!: boolean;
    @Prop({ default: '' }) public activatorText!: TranslateResult;
    @Prop({ default: '' }) public activatorTooltip!: TranslateResult;
    @Prop({ default: 336 }) public maxHeight!: number;
    @Prop({ default: 351 }) public width!: number;
    @Prop({ default: [] }) public itemsToShow!: any[];
    @Prop({ default: '' }) public searchQuery!: string;
    @Prop({ type: Boolean, default: false }) public disabled!: boolean;

    public isOpen = false;

    get styles() {
        return `max-height: ${this.maxHeight}px;`;
    }

    get isEmptySearch() {
        return !this.itemsToShow?.length && this.searchQuery.length && !this.isNoItems;
    }

    get isNoItems() {
        return !this.itemsToShow?.length;
    }

    @Emit()
    public change(value: boolean) {
        this.isOpen = value;
        return value;
    }

    public close() {
        this.isOpen = false;
    }
}
