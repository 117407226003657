var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[{ 'ws-select-wrapper': _vm.isNew }]},[(_vm.isNew)?_c('label',[_vm._v(_vm._s(_vm.label))]):_vm._e(),_c('v-select',{ref:"vselect",class:( _obj = {
            'ws-select': _vm.isNew
        }, _obj['spacing-' + _vm.spacing] = true, _obj ),attrs:{"attach":_vm.attach,"items":_vm.localItemsFiltered,"label":_vm.isNew ? undefined : _vm.label,"outlined":_vm.outlined,"dense":_vm.dense,"errorCount":_vm.maxMessages,"messages":_vm.messages,"errorMessages":_vm.errorMessages,"successMessages":_vm.successMessages,"error":_vm.error,"rules":_vm.rules,"hideDetails":_vm.hideDetails,"hideSelected":_vm.hideSelected,"required":_vm.required,"placeholder":_vm.placeholder,"clearable":_vm.clearable,"itemText":_vm.itemText,"itemValue":_vm.itemValue,"itemDisabled":_vm.itemDisabled,"disabled":_vm.disabled,"append-icon":_vm.icon,"returnObject":_vm.returnObject,"multiple":_vm.multiple,"autofocus":_vm.autofocus,"rounded":_vm.rounded,"ripple":false},on:{"change":_vm.change,"input":_vm.input,"click":_vm.updateTestAttributes},scopedSlots:_vm._u([(_vm.$slots.item || _vm.$scopedSlots.item)?{key:"item",fn:function(ref){
        var item = ref.item;
return [_vm._t("item",null,{"item":item})]}}:(!(_vm.$slots.item || _vm.$scopedSlots.item))?{key:"item",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"v-list-item__content"},[_c('div',{staticClass:"v-list-item__title"},[_c('WsTruncateAuto',{attrs:{"value":_vm.getItemText(item) || item,"limit":_vm.selectLimit,"single-line":""}})],1)])]}}:null,(_vm.required)?{key:"label",fn:function(){return [_c('span',{staticClass:"required-star"},[_vm._v("*")]),_vm._v(_vm._s(_vm.label)+" ")]},proxy:true}:null,(_vm.showSelectionSlot)?{key:"selection",fn:function(ref){
        var item = ref.item;
return [_vm._t("selection",null,{"item":item})]}}:null,(_vm.searchable || _vm.allowSelectAll)?{key:"prepend-item",fn:function(){return [(_vm.searchable)?_c('v-list-item',[_c('v-list-item-content',[_c('v-text-field',{attrs:{"value":_vm.searchString,"placeholder":_vm.$t('Simple_word.search'),"autofocus":"","outlined":"","dense":"","clearable":"","hideDetails":"","prependInnerIcon":"mdi-magnify"},on:{"blur":_vm.blurSearchString,"input":function($event){_vm.searchString = $event}}})],1)],1):_vm._e(),(_vm.allowSelectAll)?[_c('v-list-item',{attrs:{"ripple":""},on:{"click":_vm.toggleSelectAll}},[[_c('v-list-item-action',[_c('v-checkbox',{attrs:{"input-value":_vm.allSelected,"indeterminate":_vm.someSelected}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_c('span',{staticClass:"select-all"},[_vm._v(_vm._s(_vm.$t('Text.selectAll')))])])],1)]],2),_c('hr',{staticClass:"ws-select-hr"})]:_vm._e(),(_vm.$slots.prepend)?[_vm._t("prepend")]:_vm._e()]},proxy:true}:null],null,true),model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}},[_vm._t("default")],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }