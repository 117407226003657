export default {
    about: {
        contacts: {
            press: "Press contacts",
            sales: "Pricing and Sales",
            tech: "Technical support",
            other: "Other questions",
            revizto5: "Revizto 5 feedback/issues"
        }
    },
    support: {
        notMemberYet: "Not a Revizto user yet?",
        requestTrial: "Request a trial"
    },
    Punctuation: {
        comma: ", ",
        semicolon: ";",
        colon: ":",
        space: " ",
        dash: "–"
    },
    Simple_word: {
        action: "Action",
        actions: "Actions",
        additionalFields: "Additional fields",
        any: "Any",
        attachment: "Attachment",
        attachments: "Attachments",
        all: "All",
        color: "Color",
        clashes: "Clashes",
        cancel: "Cancel",
        create: "Create",
        dashboards: "Dashboards",
        date: "Date",
        day: "Day",
        days: "Days",
        default: "Default",
        disable: "Disable",
        disabled: "Disabled",
        download: "Download",
        done: "Done",
        edit: "Edit",
        enable: "Enable",
        enabled: "Enabled",
        end: "End",
        emails: "Emails",
        error: "Error",
        fail: "Fail",
        filter: "Filter",
        half: "Half",
        help: "Help",
        image: "Image",
        inactive: "Inactive",
        issues: "Issues",
        latest: "Latest",
        line: "Line",
        loading: "Loading",
        message: "Message",
        month: "Month",
        name: "Name",
        next: "Next",
        new: "New",
        no: "No",
        not: " not",
        note: "Note",
        notifications: "Notifications",
        on: "On",
        off: "Off",
        options: "Options",
        password: "Password",
        period: "Period",
        project: "Project",
        projectWithColon: "Project:",
        projects: "Projects",
        processing: "Processing",
        quarter: "Quarter",
        recent: "Recent",
        regions: "Regions",
        reject: "Reject",
        reports: "Reports",
        required: "Required",
        return: "Return",
        rfi: "RFI",
        save: "Save",
        search: "Search",
        selected: "Selected",
        sheet: "Sheet",
        sheets: "Sheets",
        size: "Size",
        stable: "Stable",
        stamps: "Stamps",
        start: "Start",
        subject: "Subject",
        success: "Success",
        tags: "Tags",
        thumbnail: "Thumbnail",
        time: "Time",
        title: "Title",
        today: "Today",
        total: "Total",
        type: "Type",
        unknown: "unknown",
        unlimited: "Unlimited",
        updated: "Updated",
        user: "User",
        users: "Users",
        value: "Value",
        version: "Version",
        warning: "Warning",
        webinars: "Webinars",
        week: "Week",
        weeks: "Weeks",
        year: "Year",
        yes: "Yes",
        yesterday: "Yesterday",
        id: "ID",
        added: "added",
        deleted: "deleted",
        changed: "changed",
        files: "File",
        photo: "photo",
        none: "None",
        confirmation: "Confirmation",
        reload: "Reload",
        charts: "Charts",
        update: "Update"
    },
    contact_us: "Contact us",
    error: {
        title: "Page not found",
        desc: "There is nothing here...",
        unknown: "Unknown error.",
        reload: "Please reload page and try again"
    },
    ActionTypes: {
        project_creation: "Create project",
        project_archivation: "Archive project",
        project_edition: "Edit project details",
        project_deletion: "Delete project",
        project_movement_out: "Migrate project to another license",
        project_movement_in: "Migrate project from another license",
        project_unarchivation: "Restore project",
        project_revision: "Add project version",
        license_member_addition: "Add license member",
        license_member_change_role: "Assign license role",
        license_member_deactivation: "Deactivate license member",
        license_member_deletion: "Delete license member",
        license_member_activation: "Activate license member",
        license_member_change_fields: "Edit additional license member fields",
        license_role_addition: "Create project role",
        license_role_edition: "Edit project role",
        license_role_deletion: "Delete project role",
        project_member_addition: "Add project member",
        project_member_change_role: "Edit project member",
        project_member_deletion: "Remove project member",
        auth_method_creation: "Create authentication method",
        auth_method_update: "Edit authentication method",
        auth_method_deletion: "Delete authentication method",
        auth_method_assign: "Assign authentication method",
        auth_method_revoke: "Revoke authentication method",
        auth_method_replace: "Replace authentication method",
        dashboard_creation: "Create dashboard",
        dashboard_update: "Edit dashboard",
        dashboard_deletion: "Delete dashboard",
        dashboard_import: "Import dashboard",
        dashboard_export: "Export dashboard",
        dashboard_copy: "Duplicate dashboard",
        dashboard_graph_creation: "Create chart",
        dashboard_graph_update: "Edit chart",
        dashboard_graph_deletion: "Delete chart",
        dashboard_graph_import: "Import chart",
        dashboard_graph_copy: "Duplicate chart",
        search_set_creation: "Create search set",
        search_set_update: "Edit search set",
        search_set_sharing: "Share search set",
        search_set_unsharing: "Unshare search set",
        search_set_deletion: "Delete search set",
        search_set_move: "Move search set",
        search_set_rename: "Rename search set",
        license_super_admin_replace: "Change super administrator",
        license_block: "Suspend license",
        license_unblock: "Reinstate license",
        workflow_add: "Create workflow",
        workflow_delete: "Delete workflow",
        workflow_edit: "Edit workflow",
        workflow_add_status: "Add status to workflow",
        workflow_status_reorder: "Reorder statuses in workflow",
        workflow_create_type: "Create issue type",
        workflow_edit_type: "Edit issue type",
        workflow_delete_type: "Delete issue type",
        workflow_restore_type: "Restore issue type",
        workflow_remove_status: "Delete status from workflow",
        workflow_remove_status_from_project: "Delete status from project",
        sharing_info_update: "Edit dashboard public link settings"
    },
    StampColor: {
        "-1": "No stamp color",
        "0": "White",
        "1": "Red",
        "2": "Orange",
        "3": "Yellow",
        "4": "Light green",
        "5": "Green",
        "6": "Light blue",
        "7": "Blue",
        "8": "Purple",
        "9": "Pink",
        "10": "Black"
    },
    Collocation: {
        accessCode: "Access code:",
        addNew: "Add new",
        additionalFields: "Additional fields",
        allowCloud: "Allow Cloud",
        allowShared: "Allow shared",
        alreadyExist: "Not unique",
        andOthers: "and {othersCount} others",
        andMore: "and {othersCount} more",
        approveInvitation: "Approve Invitation",
        availableActions: "Available actions",
        byCategory: "By Category",
        byAccessRoles: "By Access Roles",
        checkingNow: "Please wait...",
        confirmArchive: "Confirm archivation",
        confirmDelete: "Confirm deletion",
        confirmUnsubscribe: "Confirm unsubscribe",
        confirmRestore: "Confirm restoration",
        copyCodeToClipboard: "Copy to clipboard",
        createProject: "New project",
        createNewProject: "New project",
        cropYourImage: "Crop your image",
        dateFormat: "Date format",
        deleteLogo: "Delete logo",
        deleteProjects: "Delete projects",
        deleteRole: "Delete role",
        downloadFile: "Download file",
        editRoleName: "Edit role name",
        enterName: "Enter name",
        emailSent: "Emails have been sent",
        inUse: "In Use",
        internalError: "Internal error.",
        highLights: "Activity highlights",
        isRequired: "{something} is required",
        licenseCapacity: "License capacity",
        loadingData: "Loading data",
        multipleProjects: "Multiple projects",
        noneOf: "None of",
        noDataAvailable: "No items found",
        noDataToDisplay: "No data to display",
        noChartDataSelected: "No chart data selected",
        noMembersAvailable: "No members available",
        noCategory: "No category",
        noProjects: "No projects",
        noProjectsAvailable: "No projects available",
        noStamp: "No stamp",
        anyStamp: "Any stamp",
        noUsers: "No users",
        notSet: "Not set",
        permittedLicenses: "Your licenses that support Revizto API:",
        printToPdf: "Print to PDF",
        projectAdministrator: "Project administrator",
        projectTeam: "Project Team",
        projectRole: "Project role",
        projectSettings: "Project settings",
        removeMembers: "Remove members",
        resetPassword: "Reset password",
        restoreAccess: "Restore Access",
        requiredFields: "Required fields",
        returnToLogin: "Return to login",
        selectFile: "Select file",
        selectOrCreate: "Select an option or create one",
        selectVersion: "Select version",
        sendEmail: "Send email",
        sendTo: "Send to",
        showMore: "Show more",
        showLess: "Show less",
        timeFormat: "Time format",
        tooLong: "The role name is too long.",
        userInfo: "User info",
        userNotFound: "User not found",
        userManual: "User manual",
        showSomething: "Show {entity}",
        hideSomething: "Hide {entity}",
        linkCopied: "Link copied to clipboard.",
        linkCopyError: "Cannot copy the link.",
        linkCopiedShort: "Link copied",
        codeCopied: "Code copied",
        passwordCopied: "Password copied",
        passwordChanged: "Password changed",
        setImageAspectRatio: "Maintain aspect ratio",
        viaClashSync: "via clash sync",
        multiple: "<Multiple values>"
    },
    Components: {
        weekdays_picker: {
            mo: "Mo",
            tu: "Tu",
            we: "We",
            th: "Th",
            fr: "Fr",
            sa: "Sa",
            su: "Su"
        },
        weekDays: {
            mo: "Monday",
            tu: "Tuesday",
            we: "Wednesday",
            th: "Thursday",
            fr: "Friday",
            sa: "Saturday",
            su: "Sunday"
        },
        tagsSelect: {
            alreadyExist: "This tag already exists.",
            noDataAvailable: "No tags found"
        },
        progressBar: {
            counter: "{current} out of {limit}",
            counter_simple: "<span>{current}</span>/{limit}",
            unlimited: "{current} out of Unlimited"
        },
        showGridsSeparately: "Show grid intersections separately"
    },
    Form: {
        first_name: "First name",
        last_name: "Last name",
        email: "Email",
        pass: "Password",
        confirm_pass: "Confirm password",
        old_pass: "Old password",
        new_pass: "New password",
        confirm_new_pass: "Confirm new password",
        region: "Region",
        company: "Show company",
        department: "Show department",
        location: "Show office location",
        settings_changed: "Settings were updated successfully",
        timeFormat: "Time format",
        dateFormat: "Date format",
        acceptEula: "I accept the terms in the <a style=\"color: #1964e6;\" target=\"_blank\" href=\"https://revizto.com/eula\">License Agreement</a>",
        loadingRegions: "loading regions...",
        requiredFields: "Please enter required fields",
        login: "Login",
        newTag: "New tag",
        timezone: "Time zone"
    },
    Text: {
        abbreviate: "Abbreviate",
        abbreviation: "Abbreviation",
        registration: "Registration",
        project: "Project",
        projects: "Projects",
        project_name: "Project Name",
        users: "Users",
        license: "License",
        title: "Title",
        name: "Name",
        role: "Role",
        status: "Status",
        owner: "Owner",
        description: "Description",
        date: "Date",
        search: "Search",
        storage: "Storage",
        location: "Location",
        newTag: "- add new tag",
        notEnoughPermissions: "Not enough rights to view this page.",
        or: "Or",
        and: "And",
        company: "Company",
        department: "Department",
        error: "Error",
        assign: "Assign",
        expires: "Expires",
        userSlots: "User slots",
        projectSlots: "Project slots",
        empty: "Empty",
        permissions: "Access rights",
        messageForUsersOptional: "Message for users (optional)",
        undefinedName: "undefined name",
        undefinedRole: "undefined role",
        selectAll: "Select all",
        selectOrEnter: "Select or enter...",
        usersSelected: "Users selected: {count}",
        projectsSelected: "Projects selected: {count}",
        maxTextCount: "Character limit: <span>{count}</span>"
    },
    Popup: {
        welcome: "Welcome",
        check_email: {
            title: "Check your email",
            text1: "To complete registration please confirm your email",
            text2: "To complete password changing please check your email"
        }
    },
    TableHeader: {
        abbreviation: "Abbreviation",
        active: "Active",
        activeDate: "Last activity",
        appVersion: "Revizto version",
        archiveDate: "Archived on",
        actions: "Actions",
        authMethod: "Authentication method",
        author: "Author",
        comments: "Result",
        company: "Company",
        created: "Created on",
        date: "Date",
        deactivationDate: "Deactivated on",
        department: "Department",
        email: "Email",
        invited: "Invited on",
        invitedBy: "Invited by",
        involvedPeople: "Members",
        lastActiveDate: "Last active on",
        location: "Location",
        name: "Name",
        number: "#",
        operation: "Operation",
        project: "Project",
        projectLocation: "Project location",
        projectRole: "Project role",
        projectsInvolved: "Projects",
        receivers: "Recipients",
        revisions: "Revizto version",
        role: "Role",
        status: "Status",
        subject: "Subject",
        tags: "Tags",
        time: "Time",
        totalIssues: "Total issues",
        users: "Users"
    },
    Button: {
        accept: "Accept",
        activate: "Activate",
        activity: "Activity",
        active_projects: "Active projects",
        all: "All",
        apply: "Apply",
        approve: "Approve",
        archive: "Archive",
        archive_projects: "Archive projects",
        archived: "Archived",
        add: "Add",
        add_tag: "Add tags",
        back: "Back",
        cancel: "Cancel",
        change: "Change",
        change_pass: "Change password",
        choose_file: "Choose file",
        clone: "Duplicate",
        close: "Close",
        confirm: "Confirm",
        continue: "Continue",
        copy_code: "Copy code",
        copy_link: "Copy link",
        copy: "Copy",
        create: "Create",
        create_account: "Create account",
        deactivate: "Deactivate",
        delete: "Delete",
        deleteUsers: "Delete users",
        delete_permanently: "Delete permanently",
        done: "Done",
        edit: "Edit",
        edit_role: "Edit role",
        edit_tags: "Edit tags",
        edit_watchers: "Edit watchers",
        export: "Export",
        export_excel: "Export to Excel",
        filters: "Filters",
        fit: "Fit",
        forgot: "Forgot password",
        import: "Import",
        invite: "Invite",
        login: "Login",
        logout: "Sign Out",
        more: "More",
        move: "Move",
        next: "Next",
        ok: "OK",
        reactivate: "Activate",
        register: "Register",
        refresh: "Refresh",
        reject: "Reject",
        remove: "Remove",
        remove_from_project: "Remove from project",
        reset: "Reset",
        resend_invitation: "Resend invitation",
        resetFilter: "Reset filter",
        restore: "Restore",
        return: "Return",
        save: "Save",
        save_as: "Save as",
        save_changes: "Save changes",
        send: "Send",
        sendEmail: "Send email",
        set: "Set",
        setAuthMethod: "Set authentication method",
        setCompany: "Set company",
        setDepartment: "Set department",
        setLocation: "Set location",
        setProjectRole: "Set project role",
        showProjectRole: "View project role",
        sign_in: "Sign in",
        submit: "Submit",
        suspended: "Suspended",
        subscribe: "Subscribe",
        test: "Test connection",
        unsubscribe: "Unsubscribe",
        upgrade: "Upgrade",
        removeFromProjects: "Remove from projects",
        goBack: "Go back",
        undo: "Undo"
    },
    Region: {
        auto: "Auto",
        "us-east-1": "North America (USA)",
        "us-east-2": "USA Stage (Ohio)",
        "us-west-1": "USA Stage (California)",
        "eu-west-1": "Europe (Ireland)",
        "eu-central-1": "Europe (Frankfurt)",
        "sa-east-1": "South America (Brazil)",
        "ap-southeast-1": "Southeast Asia (Singapore)",
        "ap-southeast-2": "ANZ (Australia)",
        "ap-northeast-1": "Japan",
        "cn-north-1": "China",
        "cn-shanghai": "China",
        "eu-west-2": "United Kingdom",
        "eu-west-3": "Europe (Paris)",
        "autotest-1": "Autotest 1",
        "eu-north-1": "Stockholm",
        "eu-south-1": "Milan",
        "ca-central-1": "Canada",
        "me-central-1": "UAE",
        "eu-south-1-oslo": "Oslo",
        "ali-me-central-1": "KSA",
        rome: "Rome",
        amsterdam: "Amsterdam",
        florencia: "Florence",
        "eu-central-2": "Switzerland"
    },
    Placeholder: {
        extension: "Ext.",
        pickDate: "Pick a date",
        timeFormat: "Select time format",
        date: "Select date",
        dateFormat: "Select date format",
        language: "Select language",
        role: "Select role",
        region: "Select role",
        licenseOwner: "Select or enter new super administrator",
        roleOldAdmin: "New role for old super administrator",
        location: "Enter or select location",
        company: "Enter or select company",
        department: "Enter or select department",
        select: "Select",
        timezone: "Select timezone"
    },
    Tags: {
        currentTags: "Current tags",
        addTags: "Add tags",
        removeTags: "Remove tags",
        newTag: "New tag"
    },
    User: {
        integrations: {
            signin: "Sign in",
            signout: "Sign out",
            signInToAnother: "Sign in to another organization",
            procore: {
                name: "Procore",
                text: "Procore integration includes two modules. The first module allows browsing Procore project documents in Revizto, attaching them to issues, viewing selected files while offline, and adding PDF and DWFx files as sheets. The second module allows converting Revizto issues to Procore coordination issues. The main goal is to speed up the process of creating RFIs based on Revizto issues."
            },
            box: {
                name: "Box",
                text: "Box integration allows browsing Box-hosted files in Revizto, attaching them to issues, viewing selected files while offline, and adding PDF and DWFx files as sheets."
            },
            asite: {
                region: "Asite region",
                regionSelect: "Select Asite region",
                name: "Asite",
                text: "Asite integration allows browsing Asite-hosted files in Revizto, attaching them to issues, viewing selected files while offline, and adding PDF and DWFx files as sheets."
            },
            forge: {
                name: "Autodesk Construction Cloud",
                text: "ACC integration allows browsing ACC project files in Revizto, attaching them to issues, viewing selected files while offline, and adding PDF and DWFx files as sheets."
            },
            sharepoint: {
                name: "Microsoft SharePoint",
                text: "SharePoint integration allows browsing SharePoint files in Revizto, attaching them to issues, viewing selected files while offline, and adding PDF and DWFx files as sheets.",
                signInTitle: "Sign in to Microsoft SharePoint",
                signInText: "Copy the address of the home page of your SharePoint site from the browser.",
                site: "Site address",
                example: "<b>Example addresses:</b><br />https://organization.sharepoint.com<br />https://organization.sharepoint.com/sites/target_site<br />https://organization.sharepoint.com/sites/x/y/target_site<br />https://organization.sharepoint.com/sites/target_site/default.aspx"
            },
            confirm: "Do you want to sign out of <b>{service}</b>?"
        },
        widget: {
            settings: "My Account"
        },
        settings: {
            picture: "Change picture"
        },
        menu: {
            profile: "Profile",
            notifications: "Global Email Notifications"
        },
        notifications: {
            desc: "Configure global email notification settings for your Revizto projects.",
            note: "To override these settings for a specific project, open the project page and go to &quot;My Notifications&quot;. To learn whether your project has global or custom notifications, open the &quot;My Projects&quot; page and check the &quot;Email notifications&quot; column.",
            defaultMode: "This project has <b>global</b> email notification settings.",
            customMode: "This project has <b>custom</b> email notification settings.",
            toDefault: "Switch to global notifications",
            toCustom: "Switch to custom notifications",
            toGlobal: "Change global settings",
            frequency: {
                title: "Notification frequency",
                period: {
                    "0": "Disabled",
                    "1": "Instant",
                    "2": "1 hour",
                    "4": "2 hours",
                    "8": "6 hours",
                    "16": "24 hours",
                    "32": "30 minutes"
                }
            },
            events: {
                title: "Event types",
                assignee_changed: "Assignee changed",
                comment_added: "Comment added",
                deadline_changed: "Deadline changed",
                issue_closed: "Issue completed",
                markup_changed: "Markup changed",
                markup_created: "Markup created",
                markup_changed_clashSync: "Markup changed via clash sync",
                markup_created_clashSync: "Markup created via clash sync",
                publicity_changed: "Visibility changed",
                reporter_changed: "Reporter changed",
                status_changed: "Status changed",
                tags_changed: "Tags changed",
                title_changed: "Title changed",
                watchers_changed: "Watchers changed",
                priority_changed: "Priority changed",
                edit_issue_custom_type: "Type changed"
            },
            types: {
                title: "My involvement",
                watched_by_me: "Watched by me",
                assigned_to_me: "Assigned to me"
            },
            settings: {
                project: "Project settings",
                edit: "Edit settings",
                projects_not_found: "Projects not found",
                select: [
                    "No notifications",
                    "Global Settings",
                    "Custom Settings"
                ]
            }
        },
        sessions: {
            devices: "Applications",
            browsers: "Workspace",
            api: "API",
            reviztoAllowsDevices: {
                "1": "You can be signed in to Revizto on up to <strong>{maxAllowed} devices</strong>.",
                "2": "You are signed in on the following devices."
            },
            reviztoAllowsBrowsers: "You are signed in to Revizto Workspace in the following browsers.",
            reviztoOverflowDevices: {
                "1": "You have reached the limit of devices for your account.",
                "2": "To be able to sign in on a new device, remove some devices from the list."
            },
            reviztoApi: "You have the following Revizto API sessions.",
            getAccessCode: "Get access code",
            deviceCount: "Devices",
            maxAllowed: "Max allowed",
            deviceName: "Device name",
            browser: "Browser",
            os: "OS",
            lastLocationAndIP: "Last location & IP address",
            lastActivity: "Last activity",
            deviceID: "Device ID",
            remove: "Remove",
            unknown: "Unknown",
            unknownRevizto: "Revizto 5.5 or earlier",
            currentSession: "Current session",
            sessionRemovedApp: "Signed out of Revizto.",
            sessionRemovedWeb: "Signed out of Revizto Workspace in another browser.",
            sessionRemovedApi: "Signed out of a Revizto API session."
        }
    },
    Menu: {
        projects: "My Projects",
        license: "License",
        dashboard: "Dashboard",
        select_license: "Select license",
        tools: "Tools",
        selectTool: "Select tool",
        workspace: "Revizto Workspace",
        licenseInfo: "License Info",
        licenseDashboard: "Cross-Project Dashboards",
        licenseManagement: "License Management Tools",
        projectRoles: "Project Roles",
        actionLog: "License Log",
        userManagement: "User Management",
        project: "Project Directory",
        recentProjects: "Recent Projects",
        suspendedLicenses: "Suspended Licenses",
        activeLicenses: "Active Licenses",
        reportingHistory: "Report History",
        accountSecurity: "Security",
        activeSessions: "Active Sessions",
        selectAnotherProject: "Select another project",
        integrations: "Integrations"
    },
    Feedback: {
        title: "Contact us",
        message: "Your message",
        button: "Send message",
        logs: "Download log",
        subject: "Subject",
        success: {
            title: "Thank you",
            text: "The message is sent."
        },
        dashboards: {
            creating: {
                title: "How do you rate your dashboard experience?"
            }
        },
        issueTracker: {
            general: {
                title: "How do you rate your web issue tracker experience?"
            }
        },
        whatBetter: "What could have been done better?",
        thanks: "Thanks for your feedback",
        maxTextCount: "Character limit: {count}."
    },
    errors: {
        empty_category: "Select a category",
        name_to_long: "The first name is too long.",
        lastname_to_long: "The last name is too long.",
        email_to_long: "The email is too long",
        empty_first_name: "Please enter your first name",
        invalid_first_name: "Invalid first name field.",
        empty_last_name: "Please enter your last name",
        invalid_last_name: "Invalid last name field.",
        empty_email: "Please enter your email",
        empty_login: "Please enter your login",
        enter_email: "Enter an email address",
        invalid_email: "Please enter a valid email",
        empty_licenseRole: "Select a license role",
        empty_pass: "Please enter your password",
        empty_old_pass: "Please enter your old password",
        cant_be_guest: "Not eligible for \"Guest\" role.",
        pass_dont_match: "The passwords do not match.",
        unknown_error: "Something went wrong :( Please contact our support team",
        file_oversize: "File must be less than {maxSizeMb} Mb",
        empty_subject: "Please enter the subject.",
        empty_message: "Please enter the message.",
        unexpected: "Unexpected error",
        server_error: "Internal server error. Please, try again later.",
        too_many_users_add: "No users were added because the list of users is too large. Please divide the list into smaller groups.",
        avatar: "Error",
        invalid_url: "Please provide a valid link",
        noItemSelection: "No items for selection",
        search_no_matches: "No matches found",
        email_entered: "This email already entered",
        registrationLinkExpired: "Your invitation has expired.",
        databaseError: "Database error.",
        incorrectCredentials: "We cannot find an account with that email address, or the password is incorrect.",
        invalidEmail: "Invalid email.",
        empty_authMethod: "Select an authentication method",
        bannedPermanently: "This Revizto account has expired",
        authorizationBanned: "Too many sign-in attempts. Please wait for 1 minute and then try again.",
        empty_name: "Enter a name",
        no_available_licenses: "No available licenses found"
    },
    UserExists: "The user with email {email} is already registered.",
    Download: {
        menu: "Download Revizto",
        version: {
            "4": "Version 4",
            "5": "Version 5"
        },
        titleNew: "Download Revizto",
        history: "Version history",
        win: {
            title: "Revizto for Windows",
            text: "<p>Revizto for Windows includes plug-ins for supported authoring tools, import/export capabilities, and the clash automation module. Also includes VR support for HTC Vive, Valve Index, Oculus Rift, and Meta Quest.</p>Requires Windows 10 or 11."
        },
        iPhone: {
            title: "Revizto for iPhone",
            text: "<p>The fully functional Revizto app for iPhone allows users to easily access, manage, and collaborate on construction and infrastructure projects anytime and anywhere, whether in the office, field, or on the go.</p>Key features:<p><nobr>&#8226; Explore projects in 3D and 2D</nobr><br><nobr>&#8226; Issue tracker: create and manage issues and stamps</nobr><br><nobr>&#8226; Split mode 2D | 3D</nobr><br><nobr>&#8226; View object properties and add/edit custom properties as needed</nobr><br><nobr>&#8226; Switch between appearance templates</nobr><br><nobr>&#8226; Utilize 3D and 2D rulers</nobr><br><nobr>&#8226; Overlay 2D on 3D and create section cuts</nobr><br><nobr>&#8226; Navigate through 3D levels effortlessly</nobr><br><nobr>&#8226; Navigation modes: use joystick, orbit view, and more</nobr><br><nobr>&#8226; View point cloud data</nobr></p>Requires iOS 13.0 or later."
        },
        iPad: {
            title: "Revizto for iPad",
            text: "<p>Revizto for iPad retains most of the desktop application features, excluding import/export capabilities and the clash automation module.</p>Requires iPadOS 13.0 or later."
        },
        androidPhone: {
            name: "Android (smartphone)",
            title: "Revizto for Android smartphones",
            text: "<p>The fully functional Revizto app for Android smartphones allows users to easily access, manage, and collaborate on construction and infrastructure projects anytime and anywhere, whether in the office, field, or on the go.</p>Key features:<p><nobr>&#8226; Explore projects in 3D and 2D</nobr><br><nobr>&#8226; Issue tracker: create and manage issues and stamps</nobr><br><nobr>&#8226; Split mode 2D | 3D</nobr><br><nobr>&#8226; View object properties and add/edit custom properties as needed</nobr><br><nobr>&#8226; Switch between appearance templates</nobr><br><nobr>&#8226; Utilize 3D and 2D rulers</nobr><br><nobr>&#8226; Overlay 2D on 3D and create section cuts</nobr><br><nobr>&#8226; Navigate through 3D levels effortlessly</nobr><br><nobr>&#8226; Navigation modes: use joystick, orbit view, and more</nobr><br><nobr>&#8226; View point cloud data</nobr></p>Requires Android 7 or later."
        },
        androidTablet: {
            name: "Android (tablet)",
            title: "Revizto for Android tablets",
            text: "<p>Revizto for Android tablets retains most of the desktop app features, excluding import/export capabilities and the clash automation module.</p>Requires Android 7 or later."
        },
        mac: {
            title: "Revizto for Mac",
            text: "<p>Revizto for Mac is intended for viewing and collaborating on Revizto projects. It does not include plug-ins for authoring tools, model import/export capabilities, and the clash automation module.</p>Requires macOS 10.12 Sierra or later."
        },
        qr: {
            label: "This link requires the Revizto Site app",
            notSupported: "Your operating system does not support Revizto Site",
            forMobile: "Download for phone:",
            forTablet: "Download for tablet:"
        },
        fullVersion: "Revizto with plug-ins for importing models and sheets from authoring tools<br>VR support for HTC Vive, Valve Index, Oculus Rift, and Meta Quest",
        croppedVersion: "Revizto without the option to import or export models or sheets.",
        winVersion: "Requires Windows 10 or 11",
        macVersion: "Works on MacOS X 10.9 or later, 64-bit processor",
        ipadVersion: "Works only on iPads with iPadOS 13 or later",
        droidVersion: "Works only on devices with 7\" screen or bigger with Android 7",
        winMini: "If you want to download Revizto without option to import and export 2D and 3D click here <a class=\"link\" href=\"https://update.revizto.com/v4/viewer/build\">Download Windows mini</a>"
    },
    Integration: {
        loading: {
            title: "Getting access to {serviceName}",
            title1: "Getting access to {serviceName} services",
            text: "Please do not close this tab."
        },
        success: {
            title: "The Revizto application is now connected to your {serviceName} account",
            text: "You can close this tab and go back to the Revizto application."
        },
        error: {
            title: "Oops! An error occurred on connection attempt",
            retry: "Retry",
            accessDenied: "Your account does not have access to the specified SharePoint site.",
            notFound: "The specified site URL does not exist.<br>Please return to the Revizto application, check the URL, and try again.",
            emptySharePointResourceIdentifier: "Please enter the URL of the SharePoint site.",
            emptyAsiteResourceIdentifier: "Please select the Asite region.",
            forgeAccessDenied: "Cannot sign in to your Autodesk account. You have canceled the authorization.",
            forgeinvalidRequest: "Cannot sign in to your Autodesk account. Internal error. Please try again later."
        },
        sharepointText: "To complete the integration, fill the form. If you don’t know your SharePoint host name, contact your system administrator.",
        serviceName: {
            box: "Box",
            forge: "Autodesk",
            procore: "Procore",
            sharepoint: "Microsoft SharePoint",
            asite: "Asite"
        }
    },
    License: {
        title: "License info",
        licenseNameCannotBeBlank: "The license name cannot be blank.",
        administratorsTitle: "License Administrators",
        expired: "Your license has expired.",
        teamExceeded: "The license is suspended because the user limit is exceeded.",
        spaceExceeded: "The license is suspended because the project limit is exceeded.",
        licenseFrozen: "The user is suspended because the license is disabled.",
        noLicenses: "No license",
        noLicensesRegion: "You have no Revizto licenses in the <b>{region}</b> region.",
        chooseAnotherRegion: "Please select another region",
        name: "License name",
        yourRole: "Your role in the license",
        roles: "Cross-license collaboration",
        domains: "Company domains",
        allowEditingAdditionalFields: "Who can edit additional fields:",
        licenseAdmins: "License administrators",
        licenseAdminsAndAllProjectAdmins: "License administrators and all project administrators",
        licenseAdminsAndSpecifiedProjectAdmins: "License administrators and selected project administrators",
        changingRightsAdditionalFields: "Select who can edit additional fields",
        changeRightsAdditionalFields: "Select who can edit additional fields",
        allowUsersToBeGuests: "Full members can be guests in other licenses",
        allowedForAllLicenseMembers: "Allowed for all license members",
        allowedForAllLicenseMembersTooltip: "Members of this license can be guests in other licenses issued in this region.",
        allowedForGuestDomains: "Allowed for company domains",
        allowedForGuestDomainsTooltip: "Members of this license that belong to the listed company domains can be guests in other licenses issued in this region.",
        disabled: "Not allowed",
        disabledTooltip: "Members of this license cannot be guests in other licenses.",
        plan: "Subscription plan",
        plusWithSign: "Revizto+",
        plusInfoTooltip: "License members have access to all Revizto features, including clash automation.",
        plusInfoTooltipAPI: "License members have access to all Revizto features, including clash automation, issue location tags, and more.",
        standard: "Standard",
        standardInfoTooltip: "License members have access to all Revizto features, except for clash automation, issue location tags, and some other features that are a part of Revizto+ subscription plan.",
        plusTooltip: "When this option is enabled, full members of this license can be added to other licenses as guests. To enable or disable this option, contact a Revizto sales representative.",
        allowGuestsDomainsTooltip: "Only full members from the listed email domains can be added to other licenses as guests. To change the list of domains, contact a Revizto sales representative.",
        clashAutomation: "Clash automation",
        reviztoPlusLossText: "The destination license has the \"Standard\" subscription plan. Clash automation and other Revizto+ features will be unavailable after migration. To unlock these features, upgrade the destination license to Revizto+.",
        manage_users: "Manage Users",
        manage_projects: "Manage Projects",
        storage_region: "Region",
        showAllAuthMethods: "Show all authentication methods",
        importContacts: "Import contacts",
        end: "Expiration date",
        logo: "Company logo",
        additionalUserFields: "Additional user fields",
        userFrozen: "You are suspended from this license. Please contact a license administrator.",
        blocked: "Your license is suspended.",
        contactSalesManager: "Please contact a Revizto sales representative.",
        contactSupport: "If you have any questions, feel free to contact us at <a class=\"link\" href=\"mailto:service@revizto.com\">service@revizto.com</a>.",
        projectLocation: "Project location",
        sharedLocation: "Shared location",
        cloud: "Cloud",
        local: "Local",
        apiEnabled: "API enabled",
        activity: "Activity",
        userSlots: "User slots",
        userSlotsTooltip: {
            "1": "The number of user slots in the license, both occupied and total.",
            "2": "The super administrator, guest members, and members of the \"Waiting for approval\" list don’t occupy license slots.",
            "3": "The total number of user slots depends on your subscription plan. To change it, contact a Revizto sales executive."
        },
        additionalFieldsAdmins: {
            noItems: "There are no items to select. All project administrators are also license administrators.",
            noItemsMatch: "There are no items that match your search."
        },
        delete: {
            deleteLicense: "Delete license",
            aboutToDelete: "You are about to delete:",
            warning: "Your access to the license will be lost and all license data will be PERMANENTLY deleted.",
            warning1: "All projects will be deleted.",
            warning2: "All license members will be deleted.",
            warning3: "All settings will be deleted.",
            really: "Do you really want to delete the license \"{name}\"?",
            confirmBy: "Confirm by typing the license name:",
            wrongName: "The typed text doesn't match the license name",
            licenseDeleted: "The license \"{name}\" is deleted.",
            someLicenseDeleted: "The license is deleted.",
            redirected: "You have been redirected to the license \"{name}\".",
            selectLicense: "Select a license to sign in."
        },
        restrictedEmails: {
            collapsedString: {
                before: "",
                before_plural: "",
                after: "",
                after_plural: "",
                additional: "Cannot send mail to the following license member(s) because their statuses are \"Pending\", \"Pending approval\", or \"Suspended\":"
            }
        },
        roleGuestNotEligible: "Guest (Not eligible)",
        role: {
            "0": "Not in the team",
            "1": "Guest",
            "2": "Collaborator",
            "3": "Content creator",
            "4": "Administrator",
            "5": "Super administrator",
            "50": "Deactivated"
        },
        settings: {
            change_license_owner_new_admin: "Select or enter new super administrator",
            change_license_owner_new_role: "Your new role",
            change_license_owner: "Change super administrator",
            change_license_owner_description: "You are about to change the super administrator of the license <b>\"{license}\"</b>. A license can have a single super administrator, so you have to choose a new role for yourself.",
            change_project_owner: "Change project owner",
            change_project_owner_description: "You are about to change the owner of the project <b>{projectTitle}</b>.",
            change_project_title: "Change project title",
            change_project_title_description: "Please enter a new title for the project <b>{projectTitle}</b>.",
            change_project_license: "Change project license",
            change_project_license_description: "You are about to migrate <b>{projectTitle}</b> to another license. The project members will be added to the destination license as guests (if they are eligible for the \"Guest\" role) or collaborators.",
            change_project_license_success: "Project migrated",
            change_project_license_success_description: "<p>Project \"<b>{projectTitle}</b>\" is migrated to license \"<b>{licenseName}</b>.\"</p><p>Some of the project members are <b>suspended</b> because their authentication settings require a license administrator's review.</p><p>To complete the transfer, go to the User Management page and configure their authentication settings.</p>",
            change_project_abbreviate: "Change project abbreviation",
            change_project_abbreviate_description: "Please enter a new abbreviation for the project <b>{projectTitle}</b>.",
            deleteLogoQuestion: "Do you want to delete the logo?",
            new_license: "New License",
            new_owner: "New super administrator",
            oldSuperAdminSettings: "Your new role",
            ownerChanged: "Super administrator changed.",
            role_current_owner: "Role for the current super administrator",
            selectAuthMethod: "Select authentication method",
            selectNewRole: "Select new role for yourself",
            select_role: "Select role",
            select_user: "Select user",
            select_license: "Select license",
            enterCorrectEmail: "Please enter correct email",
            selectNewOwner: "Please select new super administrator",
            pleaseSelectAuthMethod: "Select the authentication method for the new super administrator.",
            pleaseEnterDomain: "Enter the domain username.",
            pleaseSelectRole: "Please select a role for yourself.",
            editLicenseName: "Edit license name",
            editLicenseOwner: "Edit super administrator",
            editLogo: "Edit logo",
            removeLogo: "Delete logo",
            removeThumbnail: "Delete thumbnail",
            openUserManagement: "Open user management",
            openProjectManagement: "Open project management",
            label: {
                cloud: "Cloud:",
                local: "Local:",
                sharedLocation: "Shared location:"
            }
        },
        filter: {
            title: "Filter",
            reset_all: "Reset All",
            new_filter: "Create new filter",
            update_filter: "Update existing filter",
            name_filter: "The name of the filter",
            select_placeholder: "Select saved filter",
            no_saved: "No saved filters",
            confirm_remove: "Do you want to remove the filter \"{title}\"?",
            by_status: "By Status",
            by_role: "By Role",
            by_invitation: "By Invitation Date",
            by_tag: "By Tag",
            status: {
                "1": "Active",
                "2": "Active outside",
                "4": "Suspended",
                "5": "Deactivated",
                "6": "Pending",
                "7": "Pending approval"
            },
            status_project: {
                "1": "Active",
                "2": "Archived"
            },
            invited_at_type: {
                all: "All",
                before: "Before",
                after: "After",
                range: "Range"
            },
            tag_action: {
                union: "ALL",
                intersection: "ANY",
                exclusion: "NONE OF"
            },
            select_tag: "Select tag..."
        },
        help_role: "<b>Roles in the team</b><br /><br /><strong>Super administrator</strong> has full control over the Revizto license and its projects.<br /><br /><strong>Administrator</strong> has full control over the Revizto license and its projects, except for changing the super administrator.<br /><br /><strong>Content creator</strong> can create Revizto projects in the cloud, work with projects, and add people who are not license members to projects.<br /><br /><strong>Collaborator</strong> can work with Revizto projects but cannot create projects in the cloud.<br /><br /><strong>Guest</strong> is a member of another Revizto license. A guest does not consume a slot in your license. Guests have the same rights as collaborators.<br />",
        group: {
            titles: {
                add_users: "Add users",
                edit_users: "Edit users",
                edit_tags: "Edit tags",
                set_role: "Set role",
                send_email: "Send Email",
                sent_email: "The email was sent successfully"
            },
            buttons: {
                add_tags: "Add selected tags",
                remove_tags: "Remove selected tags"
            },
            placeholders: {
                add_tags: "Select or enter tags",
                select_role: "Select role",
                remove_tags: "Select tags to remove",
                enterOrSelect: "Enter or select tag"
            },
            reports: {
                add_tags: {
                    collapsedString: {
                        before: "{tags} tag(s) added to ",
                        before_plural: "{tags} tag(s) added to ",
                        after: ".",
                        after_plural: "."
                    }
                },
                remove_tags: {
                    collapsedString: {
                        before: "{tags} tag(s) removed from ",
                        before_plural: "{tags} tag(s) removed from ",
                        after: ".",
                        after_plural: "."
                    }
                },
                change_role_success: {
                    collapsedString: {
                        before: "Role changed for ",
                        before_plural: "Roles changed for ",
                        after: ".",
                        after_plural: "."
                    }
                },
                change_auth_method_success: {
                    collapsedString: {
                        before: "Authentication method changed for ",
                        before_plural: "Authentication methods changed for ",
                        after: ".",
                        after_plural: "."
                    }
                },
                change_role_for_yourself: "Cannot change the role for {name} because Revizto users cannot change their own roles.",
                change_role_for_super_admin: "Cannot change the role for {name} because they are the super administrator.",
                change_role_for_not_paid_1: "Cannot assign the \"{role}\" role to",
                change_role_for_not_paid_2: "because they are not eligible for it.",
                changeRoleAdmin: {
                    collapsedString: {
                        before: "Cannot change the role for ",
                        before_plural: "Cannot change the roles for ",
                        after: " because they are a license administrator.",
                        after_plural: " because they are license administrators."
                    }
                },
                attemptToDeleteProjectOwner: "Attempt to delete project owner",
                sent_email_success: {
                    collapsedString: {
                        before: "Email sent to ",
                        before_plural: "Emails sent to ",
                        after: ".",
                        after_plural: "."
                    }
                },
                sent_email_fail: {
                    collapsedString: {
                        before: "Cannot send the email to ",
                        before_plural: "Cannot send the email to ",
                        after: ".",
                        after_plural: "."
                    }
                },
                archive_projects_success: {
                    collapsedString: {
                        before: "Project archived: ",
                        before_plural: "Projects archived: ",
                        after: ".",
                        after_plural: "."
                    }
                },
                archive_projects_fail: {
                    collapsedString: {
                        before: "Cannot archive project: ",
                        before_plural: "Cannot archive projects: ",
                        after: ".",
                        after_plural: "."
                    }
                },
                archive_projects_fail_request: "Failed to archive selected projects",
                restore_projects_success: {
                    collapsedString: {
                        before: "Project restored: ",
                        before_plural: "Projects restored: ",
                        after: ".",
                        after_plural: "."
                    }
                },
                restore_projects_fail: {
                    collapsedString: {
                        before: "Cannot restore project: ",
                        before_plural: "Cannot restore projects: ",
                        after: ".",
                        after_plural: "."
                    }
                },
                restore_projects_fail_request: "Failed to restore selected projects",
                delete_projects_success: "The following projects has been delete successfully:",
                delete_projects_fail: {
                    collapsedString: {
                        before: "Cannot delete project: ",
                        before_plural: "Cannot delete projects: ",
                        after: ".",
                        after_plural: "."
                    }
                },
                delete_projects_fail_request: "Failed to delete selected projects",
                delete_projects_info: "You are about to permanently delete {countProjects} selected projects. You won't be able to restore them later."
            }
        },
        addMember: {
            title: "Add New Users to {name}",
            role: "License Role",
            tags: "Assign Tags",
            inviteNewUser: "Invite new user",
            comment: "Comment",
            emailRequred: "The \"Email\" field is required",
            usersReadyToImport: "user<br/>to import",
            usersReadyToImport_plural: "users<br/>to import",
            usersReadyToImport_plural2: "users<br/>to import",
            usersCanBeGuest: "user eligible for<br/>the \"Guest\" role",
            usersCanBeGuest_plural: "users eligible for<br/>the \"Guest\" role",
            usersCanBeGuest_plural2: "users eligible for<br/>the \"Guest\" role",
            emailsInvalid: "invalid email<br/>address",
            emailsInvalid_plural: "invalid email<br/>addresses",
            emailsInvalid_plural2: "invalid email<br/>addresses",
            usersCantBeAdded: "user will be removed due to invalid email address.",
            usersCantBeAdded_plural: "users will be removed due to invalid email addresses.",
            duplicatedEmails: "Entries with duplicate email addresses will be removed.",
            acceptableFilesText: "A CSV, XLS, or XLSX file, up to 40 MB.",
            dropFileFull: "Drag your file here or <span>browse</span>",
            acceptableFiles: "A CSV, XLS, or XLSX file, up to 40 MB.",
            invalidFiletype: "Invalid file type",
            fileSizeLimitExceede: "File size limit exceeded",
            dropFile: "Drop file here",
            authMethodTitle: "Set authentication method for all users ",
            downloadInvalidEmails: "Download list",
            nobodyWaitingApproval: "Nobody waiting approval",
            multipleProjects: "Multiple projects",
            approvalToMultipleProjects: "You are trying to approve the person, who was added to several projects. Their email is: ",
            setRoleAllUsers: "Set role for all users",
            finalReviewTitle: "Final review",
            setRoleGuest: "Set \"Guest\" role when possible",
            dontChangeOldRole: "Keep the roles of existing users",
            importFromFile: "Import from file",
            copyPasteFromFile: "Copy from file",
            inputUsersAsTable: "Copy users from a spreadsheet.",
            usersWillAdd: "<strong>{count}</strong> user will be added",
            usersWillAdd_plural: "<strong>{count}</strong> users will be added",
            usersWillUpdate: "<strong>{count}</strong> user will be updated",
            usersWillUpdate_plural: "<strong>{count}</strong> users will be updated",
            usersWillGuest: "<strong>{count}</strong> user will be added with the \"Guest\" role",
            usersWillGuest_plural: "<strong>{count}</strong> users will be added with the \"Guest\" role",
            usersAddSuccess: "{count} user added with the \"{role}\" role",
            usersAddSuccess_plural: "{count} users added with the \"{role}\" role",
            usersUpdateSuccess: "{count} user updated",
            usersUpdateSuccess_plural: "{count} users updated",
            authMethodTitleName: "authentication method",
            licenseRoleField: "License role",
            tagsField: "Tags",
            addTagsToAllUsers: "Add tags to all users",
            tagsTitle: "Set license role and add tags",
            select_tag: "Select or enter...",
            addToProjects: "Add to projects",
            addUsersToProjects: "Add users to projects",
            addToProjectsDescription: "You can add all users to one or several projects. In each project, all users will have the same role.",
            importCompleted: "Import completed",
            emptyData: "The selected sheet does not contain data",
            excelSheet: "Excel sheet",
            setAuthMethod: "Set authentication method",
            mapUserFields: "Map user fields",
            setRolesAndTags: "Set role and add tags",
            errors: {
                overflowingNewOwner: "Cannot add the new super administrator because the license limit would be exceeded.",
                overflowUsers: "{list} were not changed because your license limit would be exceeded.",
                overflowUser: "Cannot change the license role for {user} because this requires a free user slot in the license. ",
                emptyMails: "Please enter a valid email address.",
                internalError: "{list} were not added because of an internal error.",
                tryChangeSuperAdmin: "Super Administrator cannot be changed here",
                yourselfError: "{list} was not changed because you cannot change your own role",
                emailNotWaitingForApproval: "{emails} is not in the \"Waiting for approval\" list.",
                emailNotWaitingForApproval_pl: "{emails} are not in the \"Waiting for approval\" list."
            },
            serverErrors: {
                overflowing: "Cannot add user: {list}. This requires a free license slot.",
                overflowing_plural: "Cannot add users: {list}. This requires {count} free license slots.",
                reactiveOverflowing: "Cannot activate user: {list}. This requires a free license slot.",
                reactiveOverflowing_plural: "Cannot activate users: {list}. This requires {count} free license slots.",
                yourselfError: "Cannot change the license role for {list}. A user cannot change their own role.",
                hasOwner: "Cannot change the license role for {list}. The super administrator role cannot be changed.",
                yourselfErrorSuperAdmin: "Cannot change the license role for {list}. You can change it on the “License Info” page",
                overflowUser: "Cannot change the license role for {list}. Changing the role from \"Guest\" to \"{count}\" requires a free license slot.",
                overflowUser_plural: "Cannot change the license roles for {list}. Changing the roles from \"Guest\" to \"{role}\" requires {count} free license slots.",
                residence: "Cannot add the user: {list}. They are not eligible for the \"Guest\" role. To be eligible, they must be a full member of another license that is issued in this region and allows cross-license collaboration for this user.",
                residence_plural: "Cannot add the users: {list}. They are not eligible for the \"Guest\" role. To be eligible, they must be full members of another license that is issued in this region and allows cross-license collaboration for these users.",
                notAcceptedMails: "Cannot add the user: {list}. Invalid email.",
                notAcceptedMails_plural: "Cannot add the users: {list}. Invalid emails.",
                internalError: "Cannot add the user: {list}. Internal error.",
                internalError_plural: "Cannot add the users: {list}. Internal error.",
                authMethodError: "Cannot change the authentication method for {user}. A user cannot change their own authentication method.",
                authMethodNotAllowed: "Cannot change the authentication method for {user}. The authentication method is not found."
            },
            success: {
                added: "<span>{list}</span> are added to the license."
            },
            fields: {
                email: "Email",
                firstName: "First name",
                lastName: "Last name",
                role: "Role",
                tags: "Tags",
                company: "Company",
                department: "Department",
                location: "Office Location"
            },
            titles: {
                setAuthMethod: "Set authentication method for all users"
            }
        },
        changeMemberProperties: {
            text: "Tags will be changed for 1 user.",
            text_plural: "Tags will be changed for {count} users.",
            company: "You are about to change the company for {count} user(s).",
            department: "You are about to change the department for {count} user(s).",
            location: "You are about to change the location for {count} user(s).",
            reasonRestrict: {
                collapsedString: {
                    before: "",
                    before_plural: "",
                    after: "",
                    after_plural: "",
                    additional: "Cannot edit the following user because they are super administrator:"
                }
            },
            reasonRestrictNeedApproval: {
                collapsedString: {
                    before: "",
                    before_plural: "",
                    after: "",
                    after_plural: "",
                    additional: "Cannot edit the following user(s) because they have the \"Pending approval\" status:"
                }
            }
        },
        reactivateMember: {
            text: "The user will be activated. Their membership in all their projects will be restored.",
            text_plural: "The users will be activated. Their memberships in all their projects will be restored.",
            success: {
                collapsedString: {
                    before: "License member activated: ",
                    before_plural: "License members activated: ",
                    after: ".",
                    after_plural: "."
                }
            },
            fail: {
                collapsedString: {
                    before: "Cannot activate license member: ",
                    before_plural: "Cannot activate license members: ",
                    after: ".",
                    after_plural: "."
                }
            },
            fullLicenseError: "are not activated because all available user slots in the license are currently occupied",
            noPayedLicenseError: "cannot be activated as guests because they are not eligible for the \"Guest\" role. Please assign a different role to them.",
            reasonRestrict: {
                collapsedString: {
                    before: "",
                    before_plural: "",
                    after: "",
                    after_plural: "",
                    additional: "Cannot activate the following license member(s) because their statuses are \"Active\", \"Pending\", or \"Suspended\":"
                }
            }
        },
        deactivateMember: {
            text: "The selected user will be deactivated and will have no access to your license and projects.",
            text_p: "The selected {count} users will be deactivated and will have no access to your license and projects.",
            textComment: "You can activate them back later. This will restore their access to your license and projects.",
            success: {
                collapsedString: {
                    before: "License member deactivated: ",
                    before_plural: "License members deactivated: ",
                    after: ".",
                    after_plural: "."
                }
            },
            fail: {
                collapsedString: {
                    before: "Cannot deactivate license member: ",
                    before_plural: "Cannot deactivate license members: ",
                    after: ".",
                    after_plural: "."
                }
            },
            failOwner: "is not deactivated because they are the super administrator.",
            failMyself: "is not deactivated because you cannot deactivate yourself.",
            reasonRestrict: {
                collapsedString: {
                    before: "",
                    before_plural: "",
                    after: "",
                    after_plural: "",
                    additional: "Cannot deactivate the following license member(s) because they are the current user and/or the super administrator:"
                }
            }
        },
        resendInvitation: {
            title: "Resend invitations",
            willBeResent: "Resend the invitation to the following user:",
            willBeResent_pl: "Resend the invitations to the following users:",
            success: {
                collapsedString: {
                    before: "Invitation sent to: ",
                    before_plural: "Invitations sent to: ",
                    after: ".",
                    after_plural: "."
                }
            },
            fail: {
                collapsedString: {
                    before: "Cannot send invitation to: ",
                    before_plural: "Cannot send invitations to: ",
                    after: ".",
                    after_plural: "."
                }
            },
            reasonRestrict: {
                collapsedString: {
                    before: "",
                    before_plural: "",
                    after: "",
                    after_plural: "",
                    additional: "Cannot send invitations to the following user(s) because they are already registered at Revizto Workspace:"
                }
            }
        },
        changeRole: {
            text: "This will change the role for the selected user.",
            text_plural: "This will change the role for {count} selected users.",
            reasonRestrict: {
                collapsedString: {
                    before: "",
                    before_plural: "",
                    after: "",
                    after_plural: "",
                    additional: "Cannot change the role for the following user(s) because they are the current user and/or the super administrator:"
                }
            }
        },
        deleteMember: {
            text: "The selected user will be permanently deleted. All their data, such as project membership, tags, and usage statistics, will be lost.",
            text_plural: "The selected users will be permanently deleted. All their data, such as project membership, tags, and usage statistics, will be lost.",
            success: "were successfully deleted from the license",
            fail: {
                collapsedString: {
                    before: "Cannot delete license member: ",
                    before_plural: "Cannot delete license members: ",
                    after: ".",
                    after_plural: "."
                }
            }
        },
        memberStatus: {
            deleteTitle: "Confirm remove",
            deleteDesc: "Do you really want to remove {name} from the team?",
            deactivateSecurityError: "Deactivation failed. You don't have enough rights to deactivate users in this license.",
            reactivateOverflowError: "Cannot activate the user because there are no free slots in the license.",
            reactivateSecurityError: "You don't have enough rights to activate users.",
            deleteSecurityError: "Deletion failed. You don't have enough rights to remove users from this license.",
            invitedFailed: "Invitation failed",
            invitedDelivered: "Invitation delivered",
            sendingInvitation: "Sending invitation",
            suspendReason: {
                "1": "The user is suspended because the license has expired.",
                "2": "The user is suspended because the total number of users or projects exceeds the license limit.",
                "3Standard": "This user is suspended because they are not eligible for the \"Guest\" role.<br>To be eligible for this role, they must be a full member of another license<br>that is issued in this region and allows cross-license collaboration for them.<br>You can recover the user by changing their role to \"Collaborator.\"",
                "3ReviztoPlus": "This user is suspended because they are not eligible for the \"Guest\" role.<br>To be eligible for this role, they must be a full member of another Revizto+ license<br>that is issued in this region and allows cross-license collaboration for them.<br>You can recover the user by changing their role to \"Collaborator.\"",
                "4": "This user is suspended because they have not been set a domain username.",
                "5": "This user is suspended because they have not been set an active authentication method.",
                "6": "The user is suspended by their own request."
            }
        },
        dashboardPresets: {
            title: "Edit Dashboard Presets",
            time: "Time"
        },
        nameAndEmail: "Full name and email",
        deleteProjects: {
            mainText: "Are you sure you want to permanently delete selected projects? If you proceed, all project data will be wiped without the ability to restore it.",
            helpText: "We recommend archiving selected projects instead. Archived projects do not take up license slots and act just like deleted projects, but you will be able to restore them at any point."
        },
        removeMembers: {
            question: "Are you sure you want to permanently delete selected {count} users from the license?",
            question_sg: "Do you want to permanently delete {count} license member?",
            text: "If you proceed, the user(s) will be unable to access the license's projects. Their reports and dashboards will be transferred to the project owners. They will stop receiving notifications from this license's projects.",
            helpText: "You might want to deactivate the license members instead of deleting them. Deactivated users cannot access the license and its projects, and they do not occupy license slots. You can activate them back at any time.",
            reasonRestrict: {
                collapsedString: {
                    before: "",
                    before_plural: "",
                    after: "",
                    after_plural: "",
                    additional: "Cannot delete the following license member(s) because they are the current user and/or the super administrator:"
                }
            }
        },
        twoFAActivated: "The user has enabled two-step authentication.",
        approval: "Waiting for approval",
        importUsers: "Import users",
        additionalFields: "Additional fields",
        authMethod: "Authentication method",
        authMethods: "Authentication Methods",
        addAuthMethod: "Add authentication method",
        associatedDomains: "Associated domains",
        tooltipDisabledAuthMethod: "This authentication method is inactive. The user cannot sign in.",
        approvalTexts: {
            addedWithRole: "{count} people will be added to the license as {role}.",
            added: "{count} people will be added to the license.",
            tooMuch: "The license does not have enough free slots. Some of the users will not be added to the license.",
            evenWithGuestsTooMuch: "The license does not have enough free slots. Some of the users that are not eligible for the \"Guest\" role will not be added to the license."
        },
        contacts: {
            security: "IT security contacts:",
            billing: "Billing contacts:",
            editSecurity: "Edit IT security contacts",
            editingSecurity: "Edit IT security contacts",
            editBilling: "Edit billing contacts",
            editingBilling: "Edit billing contacts",
            someEmailsInvalid: "Some emails are invalid.",
            emails: "Emails",
            enterEmails: "Please enter a comma-separated list of emails"
        }
    },
    LicenseAuthMethodSettings: {
        azure: "Azure",
        other: "Other",
        createTitle: "Create authentication method",
        editTitle: "Edit authentication method",
        deleteTitle: "Delete authentication method",
        deactivateTitle: "Deactivate authentication method",
        directoryName: "Authentication method name",
        type: "Authentication service",
        reasonRestrict: {
            collapsedString: {
                before: "",
                before_plural: "",
                after: "",
                after_plural: "",
                additional: "Cannot set authentication method for the following license member(s) because they are the current user and/or the super administrator:"
            }
        },
        types: {
            revizto_internal: "Revizto login",
            google: "Google",
            SAML: "SAML",
            unknown: "unknown"
        },
        deleteTextWoMembers: "You are about to delete the authentication method \"{auth_method_name}.\" It is not assigned to any license members.",
        deactivateTextWoMembers: "You are about to deactivate the authentication method \"{auth_method_name}.\" It is not assigned to any license members.",
        deleteTextWithMembers: "If you delete the authentication method, 1 license member will be unable to sign in. You can suspend them or assign another authentication method to them.",
        deleteTextWithMembers_plural: "After deleting the authentication method, {number} users will be unable to sign in. Suspend the users or assign another authentication method for them.",
        deactivateTextWithMembers: "If you deactivate the authentication method, {number} user(s) will be unable to sign in. You can suspend them or assign another authentication method to them.",
        directorySettingsTitle: "Other settings",
        setDefault: "Make this the default method",
        setDefaultHint1: "This is the default authentication method for users that are added manually.",
        setDefaultHint2: "This method is also assigned to users that are imported from a file, except for users whose email domains are associated with another method.",
        timeCache: "Authentication cache lifetime",
        timeCacheMinut: "{value} min",
        emailDomainsHint: "When users that belong to the listed email domains are added to the license, they are assigned this authentication method",
        googleRequireHint: "This authentication method requires a business email address registered at Google Workspace.",
        serverSettings: "Server settings",
        entityId: "Service provider Entity ID",
        x509certificate: "Public X.509 certificate",
        hostname: "Host name",
        loginUrl: "Login URL",
        invalidLoginUrlFormat: "Invalid login URL format.",
        metadataUrl: "Identity provider entity ID",
        federationMetadataUrl: "Federation metadata URL",
        logoutResponseUrl: "Logout response URL",
        port: "Port",
        ssl: "Use SSL",
        username: "Username",
        password: "Password",
        baseDn: "Base DN",
        additionalUserDn: "Additional User DN",
        additionalUserDnTips: "Prepended to the base DN to limit the scope when searching for users.",
        additionalGroupDn: "Additional Group DN",
        additionalGroupDnTips: "Prepended to the base DN to limit the scope when searching for groups.",
        authenticationMethod: "Authentication method",
        suspend: "Suspend",
        moveUsers: "Assign another authentication method",
        userSchemaSettings: "User schema settings",
        userSearchFilter: "User search filter",
        requires2FA: "The method requires two-step verification",
        requires2FA_not: "The method doesn't require two-step verification",
        setAuthMethod: "Set authentication method",
        selectAuthMethod: "Select authentication method",
        tryChangeSuperAdminError: "Cannot change the authentication method for {name} because they are the super administrator.",
        tryChangeYourselfError: "Cannot change the authentication method for {name} because Revizto users cannot change their own authentication methods.",
        successConnect: "Connection successful.",
        failedConnect: "Cannot establish connection.",
        uploadCertificate: "Paste a certificate from a clipboard or&nbsp;<span class=\"link\">upload a certificate file</span>."
    },
    LicenseMemberSettings: {
        invitedAt: "Invited on",
        deactivated: "Deactivated on",
        lastActivity: "Last active on",
        additionalFieldsTitle: {
            company: "Set company",
            department: "Set department",
            location: "Set location"
        }
    },
    LicenseMembers: {
        label: {
            status: "Status:",
            role: "Role:",
            tags: "Tags:",
            projects: "Projects:",
            invited: "Invited:",
            invitedBy: "Invited by:",
            company: "Company:",
            department: "Department:",
            location: "Location:",
            authMethod: "Authentication method:",
            lastActive: "Last active:",
            lastActivity: "Last activity:",
            created: "Created:",
            owner: "Owner:",
            version: "Version:"
        },
        removeFromProjectsDialog: {
            header: "Remove from projects",
            title: "Do you want to <b>remove {count} selected user</b> from the projects?",
            title_plural: "Do you want to <b>remove {count} selected users</b> from the projects?",
            alert: "Project owners cannot be removed from their projects",
            projectName: "Project",
            members: "Members",
            confirmationTitle: "If you remove the user(s) from the projects:",
            warnings: [
                "They will be unable to access the projects.",
                "Their reports and dashboards will be transferred to the project owners.",
                "They will stop receiving notifications from these projects."
            ],
            removeUsers: "Remove users",
            deleteSuccess: "Users removed from projects",
            deleteError: "Cannot remove users from projects"
        },
        sidebar: {
            userInfo: "User info",
            addUserTitle: "Add user",
            addUserButton: "Add user",
            showUsers: "Show users",
            details: "Details",
            reset: "Reset",
            importUserDetails: "Import user details",
            createNewWithSameDetails: "Create new user with same details",
            rememberDetails: "Remember \"Details\" for the next user",
            update: "Update",
            invitedAt: "Invited on",
            status: "Status",
            lastActivity: "Last active on",
            deactivatedAt: "Deactivated on",
            fields: {
                email: "Email",
                firstName: "First name",
                lastName: "Last name",
                licenseRole: "License role",
                authMethod: "Authentication method",
                company: "Company",
                department: "Department",
                location: "Location",
                tags: "Tags"
            },
            rolePlaceholder: "role",
            setRole: "Set role",
            bulkImportLinkText: "To import users in bulk, <a class=\"sidebar-bulk-import-link\">click here</a>",
            deleteUsersFromProjects: "The selected users are members of {count} project. <a class=\"delete-from-projects\">Remove from projects...</a>",
            deleteUsersFromProjects_plural: "The selected users are members or {count} projects. <a class=\"delete-from-projects\">Remove from projects...</a>",
            selectAction: "Select action",
            projects: {
                addTitle: "Add to projects",
                title: "Projects",
                projectsPlaceholder: "Select projects...",
                noProjectSelected: "No projects selected",
                selectProjectRole: "Select a role",
                selectProject: "Select a project",
                selectProjectsAndRole: "Select a project and a role",
                selectRoleTooltip: "Select a project role",
                projectDescription: "Cannot change the role for project owners and license administrators"
            },
            selectMemberWhileInvite: {
                description: "You have an unsaved user.",
                copy: "Copy details from the selected user",
                discard: "Discard changes and show the selected user"
            },
            selectMemberWhileEdit: {
                description: "You have other users selected.",
                add: "Add this user to the selection",
                select: "Select only this user"
            },
            guestNotGruntedDialog: {
                title: "License roles not assigned",
                subtitle: "1 user was not granted the \"Guest\" role",
                subtitle_plural: "{count} users were not granted the \"Guest\" role",
                description: "To be eligible for the \"Guest\" role, they must be full members of another Revizto+ license that is issued in this region and allows cross-license collaboration for them."
            },
            changeRoleWarning: "Cannot change the role for the <b>current user</b> and/or the <b>super administrator</b>",
            changeAuthMethodWarning: "Cannot change the authentication method for the <b>current user</b>",
            notifications: {
                memberProjectsLoadError: "Server error. Reload the page and try again.",
                memberCheckGuestError: "Server error. Reload the page and try again.",
                notEligibleGuestRole: "Not eligible for \"Guest\" role",
                memberUpdateError: "Cannot update the user",
                memberInviteError: "Cannot invite the user",
                projectRoleNotSpecified: "Project role(s) are not specified",
                memberInvited: "User invited to the license",
                memberUpdated: "User updated",
                internalError: "User not added. Internal error.",
                internalError_plural: "Users not added. Internal error.",
                internalErrorUpdate: "User not updated. Internal error.",
                internalErrorUpdate_plural: "Users not updated. Internal error.",
                invalidEmail: "User not added. Invalid email.",
                guestError: "User not added. They are not eligible for \"Guest\" role.",
                licenseExpired: "User not updated. License expired.",
                licenseExpired_plural: "Users not updated. License expired.",
                licenseLimitError: "User not added. Free license slot is required.",
                licenseLimitError_plural: "Users not added. Free license slots are required.",
                licenseLimitErrorUpdate: "User not updated. Free license slot is required.",
                licenseLimitErrorUpdate_plural: "Users not updated. Free license slots are required.",
                ownRoleChangeError: "User not updated. Role update not allowed.",
                superAdminChangeError: "User not updated. Role update not allowed.",
                authMethodChangeError: "User not updated. Authentication method update not allowed.",
                authMethodNotAllowed: "User not updated. Authentication method update not allowed.",
                authMethodNotAllowed_plural: "Users not added. Authentication method update not allowed.",
                authMethodNotAllowedUpdate: "User not updated. Authentication method update not allowed.",
                authMethodNotAllowedUpdate_plural: "Users not updated. Authentication method update not allowed."
            },
            rolePopovers: {
                warning: "Changing the license role for the current user or the super administrator is not allowed.<br /><br />If you are a super administrator, you can change your license role on the \"License Info\" page.<br /><br />If you are not a super administrator, you can ask another license administrator to change your license role."
            }
        }
    },
    AdditionalFields: {
        tabs: {
            company: "Company",
            department: "Department",
            location: "Location"
        },
        userFilterName: "User",
        usersColumnHeader: "Users",
        actions: {
            add: {
                company: "Add company",
                department: "Add department",
                location: "Add location",
                default: "Add"
            },
            edit: {
                company: "Edit company",
                department: "Edit department",
                location: "Edit location",
                default: "Edit"
            }
        },
        sidebar: {
            fields: {
                fieldName: {
                    company: "Company",
                    department: "Department",
                    location: "Location"
                },
                members: "Assigned to users: {count}"
            },
            buttons: {
                save: "Save",
                cancel: "Cancel",
                addUser: "Add user"
            }
        },
        messages: {
            company: {
                emptyField: "Enter a company name",
                createSuccess: "Company added",
                createError: "Cannot add company",
                updateSuccess: "Company updated",
                updateError: "Cannot update company",
                renameUniqueError: "Enter a unique company name or select \"Merge with existing company\"",
                deleteSuccess: "Company deleted",
                deleteError: "Cannot delete company",
                noData: "No companies available",
                noFilterMatch: "No companies match your filter",
                noSearchMatch: "No companies match your search",
                noSearchFilterMatch: "No companies match your search and filter criteria"
            },
            department: {
                emptyField: "Enter a department name",
                createSuccess: "Department added",
                createError: "Cannot add department",
                updateSuccess: "Department updated",
                updateError: "Cannot update department",
                renameUniqueError: "Enter a unique department name or select \"Merge with existing department\"",
                deleteSuccess: "Department deleted",
                deleteError: "Cannot delete department",
                noData: "No departments available",
                noFilterMatch: "No departments match your filter",
                noSearchMatch: "No departments match your search",
                noSearchFilterMatch: "No departments match your search and filter criteria"
            },
            location: {
                emptyField: "Enter a location name",
                createSuccess: "Location added",
                createError: "Cannot add location",
                updateSuccess: "Location updated",
                updateError: "Cannot update location",
                renameUniqueError: "Enter a unique location name or select \"Merge with existing location\"",
                deleteSuccess: "Location deleted",
                deleteError: "Cannot delete location",
                noData: "No locations available",
                noFilterMatch: "No locations match your filter",
                noSearchMatch: "No locations match your search",
                noSearchFilterMatch: "No locations match your search and filter criteria"
            }
        },
        dialogs: {
            unsavedChanges: {
                title: "Unsaved changes",
                description: "You have unsaved changes. Do you want to discard them?",
                discardButton: "Discard changes",
                keepButton: "Keep editing"
            },
            mergeField: {
                title: {
                    company: "Company already exists",
                    department: "Department already exists",
                    location: "Location already exists"
                },
                description: {
                    company: "The company \"{name}\" already exists.",
                    department: "The department \"{name}\" already exists.",
                    location: "The location \"{name}\" already exists."
                },
                selectAction: "Select an action:",
                actions: {
                    merge: {
                        company: "Merge with existing company",
                        department: "Merge with existing department",
                        location: "Merge with existing location"
                    },
                    rename: "Rename"
                },
                inputLabels: {
                    company: "Company",
                    department: "Department",
                    location: "Location"
                },
                buttons: {
                    save: "Save",
                    cancel: "Cancel"
                }
            },
            delete: {
                title: {
                    company: "Delete company",
                    department: "Delete department",
                    location: "Delete location"
                },
                description: {
                    subtitle: {
                        company: "The company is assigned to <b>{count} user(s).</b>",
                        department: "The department is assigned to <b>{count} user(s).</b>",
                        location: "The location is assigned to <b>{count} user(s).</b>"
                    },
                    text: {
                        company: "Select a replacement company, or leave this field blank to clear company for these users.",
                        department: "Select a replacement department, or leave this field blank to clear company for these users.",
                        location: "Select a replacement location, or leave this field blank to clear company for these users."
                    }
                },
                descriptionEmpty: {
                    company: "Do you want to delete this company?",
                    department: "Do you want to delete this department?",
                    location: "Do you want to delete this location?"
                },
                fieldName: {
                    company: "Company",
                    department: "Department",
                    location: "Location"
                },
                placeholder: {
                    company: "Select or enter company",
                    department: "Select or enter department",
                    location: "Select or enter location"
                },
                buttons: {
                    delete: "Delete",
                    cancel: "Cancel"
                }
            }
        }
    },
    license: {
        invite: {
            text5: "You have full control over the license.",
            text6: "You can upload new projects to the cloud and add external people to the license through project sharing.",
            text9: "You can work with Revizto projects you are invited to, but you can't upload new projects to the cloud."
        }
    },
    Project: {
        licenseOverflow: "There are no free project slots in the destination license.",
        archiveQuestion: "Do you want to archive project <b>{projectTitle}</b>?",
        archiveQuestionMultiple: "Do you want to archive projects <b>{projectTitle}</b>?",
        archiveVeryMany: "Do you really want to archive <b>{number}</b> projects?",
        archiveDescription: "<p>Archived projects do not occupy license slots.</p></p><p>Only a project owner and license administrators can access an archived project at Revizto Workspace. A project owner can open an archived project for viewing in the Revizto application if they select the \"Archived\" project filter. However, they cannot edit the project or add issues.</p><p>Archived projects do not send any notifications to project members.</p><p>A project owner or a license administrator can restore an archived project at any time.</p>",
        archiveDescriptionMultiple: "<p>Archived projects do not occupy license slots.</p></p><p>Only a project owner and license administrators can access an archived project at Revizto Workspace. A project owner can open an archived project for viewing in the Revizto application if they select the \"Archived\" project filter. However, they cannot edit the project or add issues.</p><p>Archived projects do not send any notifications to project members.</p><p>A project owner or a license administrator can restore an archived project at any time.</p>",
        restoreQuestion: "Do you want to restore project <b>{projectTitle}</b>?",
        restoreQuestionMultiple: "Do you want to restore projects <b>{projectTitle}</b>?",
        restoreVeryMany: "Do you really want to restore <b>{number}</b> projects?",
        restoreDescription: "<p>The restored project will occupy a license slot.</p><p>All project members will regain access to the project.</p><p>All notifications to the project members will be restored.</p>",
        restoreDescriptionMultiple: "<p>The restored projects will occupy license slots.</p><p>All project members will regain access to the projects.</p><p>All notifications to the project members will be restored.</p>",
        deleteDesc: "Do you really want to delete the project <b>{projectTitle}</b> from the cloud?",
        unsubscribeDesc: "Do you really want to delete the project <b>{projectTitle}</b> from your gallery?",
        noProjects: "No available projects",
        changeTags: "Tags will be changed for {count} projects.",
        emailNotSendToPending: "Cannot send the message to {count} recipient(s) with \"Pending\" or \"Pending approval\" statuses.",
        projectSelectLabel: "Select or enter projects...",
        restrictedEmails: {
            collapsedString: {
                before: "",
                before_plural: "",
                after: "",
                after_plural: "",
                additional: "Cannot send mail to the following project member(s) because their statuses are \"Pending\", \"Pending approval\", or \"Suspended\":"
            }
        },
        dropOrSelect: "Drop a file to attach, or <a class=\"link drop-files-link\" href=\"\">browse</a>.",
        isArchived: "The project is archived",
        previewHint: "A JPG or PNG file, up to 8 MB.<br>Recommended size 1000 x 750 px.",
        editProjectTitle: "Edit project title",
        editProjectOwner: "Edit project owner",
        editProjectTags: "Edit project tags",
        addProjectTags: "Add project tags",
        editProjectAbbreviation: "Edit project abbreviation",
        addProjectAbbreviation: "Add project abbreviation",
        changeLicense: "Migrate to another license",
        menu: {
            info: "Project Info",
            projectTeam: "Project Team",
            issueTracker: "Issue Tracker",
            dashboards: "Dashboards",
            reports: "Reports",
            scheduler: "Scheduler",
            activity: "Activity",
            revisions: "Versions",
            dashboard: "Dashboard",
            issueSettings: "Issue Settings",
            notifications: "My Notifications",
            log: "Project Log",
            workflow: "Issue Workflows",
            stamps: "Stamp templates",
            tags: "Tags"
        },
        involved_projects: "Projects Involved",
        accessRole: {
            "1": "Owner",
            "2": "License administrator"
        },
        info: {
            invalidTitle: "The title is invalid.",
            too_long_title: "The title is too long.",
            too_long_abbreviate: "The abbreviation must be no longer than 3 characters",
            empty_role: "Please select a replacement role for the current owner",
            team_member_is_required: "Please specify the new project owner"
        },
        invitation: {
            needDomainName: "The following users have blank domain usernames and therefore will be unable to sign in",
            needDomainNameToCorrect: "To correct this, go to the list of users and fill in their domain usernames. You can import the domain usernames from a file instead of filling them manually.",
            were: "were",
            was: "was",
            invited: "invited to the project.",
            customer: "{list} is eligible for the \"Guest\" role. They were added to your team as a guest.",
            customers: "{list} are eligible for the \"Guest\" role. They were added to your team as guests.",
            title: "Invite people to project",
            not_enough_right_exception: "You can invite only members of your Revizto license to your project.",
            add_new_member: "- invite new user(s)",
            set_access_level: "Set project role",
            emailLabel: "Enter email or select a user",
            errors: {
                wrong_format: "{count} people weren't added because the email format is incorrect",
                set_owner: "Cannot change {owner_name}'s role because they are the project owner.",
                set_admin: "Cannot change {admin}'s role because they are a license administrator.",
                not_enough_right: "{count} people weren't added because you don't have enough rights to invite new people to the license.",
                licenseLimit: "{count} user(s) were not added because you reached the license limit. Please contact a license administrator. You can view the list of license administrators <a class=\"link\" href=\"{href}\">here</a>.",
                listReject: "All invitations in this list will be rejected"
            },
            success: {
                level_changed: "Project role changed to \"{role_name}\" for {count} users.",
                mass_added: "{user_list} were added to the license as collaborator(s). License usage is now {slots} of {total_slots}.",
                mass_added_unlimited: "{user_list} were added to the license as collaborator(s)."
            },
            pending: {
                mass_added: "{user_list} were invited into the project with role {role_name}, and they are awaiting approval by the license administrator."
            }
        },
        notification: [
            "Disabled",
            "Global",
            "Custom"
        ],
        removeMembers: {
            mainText: "Are you sure you want to permanently remove {count} selected users from the project?",
            helpText: "<p>If you remove the user(s) from the project:</p><ul><li>They will be unable to access the project.</li><li>Their reports and dashboards will be transferred to the project owner.</li><li>They will stop receiving notifications from this project.</li></ul>",
            reasonRestrict: {
                collapsedString: {
                    before: "",
                    before_plural: "",
                    after: "",
                    after_plural: "",
                    additional: "Cannot remove the following project member(s) because they are the current user and/or the project owner:"
                }
            }
        },
        updateMembers: {
            fail: {
                collapsedString: {
                    before: "Cannot change the role for ",
                    before_plural: "Cannot change the roles for ",
                    after: ".",
                    after_plural: "."
                }
            }
        },
        create: {
            sourceLicense: "Source license",
            sourceProject: "Source project",
            ownerShouldBeLicenseMember: "The project owner must be a license member.",
            ownerCannotBeDeactivatedLicenseMember: "The project owner cannot be a deactivated license member.",
            importFromAnother: "Import settings from another project",
            createAnother: "Create another project",
            authNeeded: "Authentication needed",
            projectCreated: "Project \"{title}\" created.",
            licenseOverflow: "Cannot create a project because there are no free project slots in the license.",
            notAllowedCloud: "Cannot create a project because cloud projects are disabled in this license.",
            selectWhatToImport: "Select what to import",
            transferOptions: {
                clashSyncs: "Navisworks clash sync settings",
                customPropertySet: "Custom object properties",
                dashboards: "Dashboards",
                favoritePropertySet: "Shared favorite properties",
                filterPresets: "Shared issue filter presets",
                members: "Project members and their project roles",
                reports: "Reports",
                searchSets: "Shared search sets",
                appearSets: "Shared appearance templates",
                stampsTemplates: "Stamp templates",
                tags: "Issue tags",
                issueWorkflows: "Issue workflows"
            },
            transferWarnings: {
                clashSyncs: "Navisworks clash sync settings cannot be imported due to an unknown error.",
                customPropertySet: "You must have the right to manage custom object properties in the destination project.",
                dashboards: "You must have administrator rights in the destination project.",
                favoritePropertySet: "You must have the right to manage shared favorite properties in the destination project.",
                filterPresets: "You must have the right to manage shared issue filter presets in the destination project.",
                members: "Project members and their project roles can be imported only if both source and destination projects are hosted in the same license.",
                reports: "To import reports, you must have administrator rights in the source and destination projects.",
                searchSets: "You must have the right to manage shared search sets in the destination project.",
                appearSets: "You must have a right to manage appearance templates on the target project.",
                stampsTemplates: "You must have the right to manage stamp templates in the destination project.",
                tags: "You must have the right to create new tags in the destination project.",
                searchSetsDependAppearSets: "Search sets will be transferred because you selected appearance templates. Appearance templates can contain references to search sets.",
                cannotImportFromLocal: "Cannot import appearance templates, search sets, and custom object properties from a shared location project.",
                issueWorkflows: "To import issue workflows, you must have administrative rights in the source project."
            },
            warningDisallowCloud: "Cannot create a project because cloud projects are disabled in this license."
        },
        resendInvitation: {
            reasonRestrict: {
                collapsedString: {
                    before: "",
                    before_plural: "",
                    after: "",
                    after_plural: "",
                    additional: "Cannot send invitations to the following user(s) because they are registered at Revizto Workspace, or their registration requires a license administrator's approval:"
                }
            }
        },
        approve: {
            reasonRestrict: {
                collapsedString: {
                    before: "",
                    before_plural: "",
                    after: "",
                    after_plural: "",
                    additional: "Cannot approve the following user(s) because they are already approved:"
                }
            }
        },
        archivedProjectInfo: {
            "1": "Archived projects do not occupy license slots.",
            "2": "Only the project owner and license administrators can access archived projects.",
            "3": "Archived projects do not send any notifications to project members.",
            "4": "A project owner or a license administrator can restore an archived project at any time."
        },
        settings: {
            new_owner: "New owner",
            role_current_owner: "Role for the current owner"
        },
        scheduler: {
            common: {
                noDelay: "No delay",
                warnings: "Warnings",
                tasks: "Tasks",
                upcoming: "Upcoming schedule",
                history: "History",
                deviceOnline: "Device online",
                deviceOffline: "Device offline",
                showHideColumns: "Show/hide columns",
                deleteTasks: "Delete tasks",
                viewHistory: "View history",
                keys: {
                    additionalActions: "Action",
                    whenPerform: {
                        title: "When to perform the action"
                    },
                    taskType: "Task type",
                    device: "Device",
                    user: "User",
                    runTaskAuto: "Run task automatically on schedule",
                    recurrence: "Recurrence",
                    cannotRunManually: "You can run the task manually or through the command line. For instructions on running the task from the command line, see the task settings in the Revizto Scheduler application.",
                    endDate: "Specify the end date for the task",
                    syncProjects: "Sync Revizto project after task completion",
                    sendNotifications: "Send email notifications",
                    onFail: "Task failed or partially failed",
                    onSkip: "Task skipped or canceled by user",
                    onComplete: "Task successfully completed",
                    email: "Email addresses",
                    advancedEnabled: "Perform command-line actions before or after the task",
                    commandLine1: "Command-line settings",
                    commandLine2: "You can run this task from the command line.",
                    taskAlias: "Task alias for the command line",
                    modelImportSettings: "Model import settings",
                    sourceFiles: "Source files",
                    noFiles: "No files match your search",
                    dateTime: "Date & time",
                    time: "Time",
                    sourceModel: "Source model",
                    publishFrom: "Publish from",
                    clashAutomationSettings: {
                        title: "Clash automation settings",
                        clashTests: {
                            title: "Clash tests",
                            all: "All",
                            selectedLabels: "Selected labels",
                            selectedLabelsHint: "Clash tests can have labels. Clash tests with any of the specified labels are included in the task scope.",
                            selectedTests: "Selected clash tests"
                        },
                        clashLabels: {
                            title: "Clash test labels"
                        }
                    }
                },
                errors: {
                    title: "errors",
                    taskRunFailed: {
                        title: "Last run failed",
                        text: "Last run of the task \"{task}\" ({project}) has failed."
                    },
                    userDeleted: {
                        title: "User deleted from project",
                        text: "Cannot run the task because the task owner was deleted from the project."
                    },
                    deviceOffline: {
                        title: "Device offline",
                        text: "Cannot run the task because the device where the task should run is offline."
                    },
                    noModel: {
                        title: "No model specified",
                        text: "Cannot run the model publishing task because the task is not linked to any model."
                    }
                }
            },
            tasks: {
                noTasks: "No tasks created yet",
                searchError: "No tasks match your search",
                deletedUser: "Not in project",
                filterError: "No tasks match you filter",
                filterSearchError: "No tasks match your search and filter criteria",
                emptyTable: "<b>No tasks created yet.</b><br />Run Revizto Scheduler to create a task.",
                table: {
                    displayName: "Name",
                    taskType: "Task type",
                    device: "Device",
                    user: "User",
                    schedule: "Schedule",
                    averageDuration: "Average duration",
                    nextRun: "Next run",
                    lastRun: "Last run",
                    lastRunStatus: "Last run status"
                },
                customDates: "Custom dates",
                eachWeek: "Every week",
                biWeekly: "Every two weeks",
                onDemand: "On demand",
                filters: {
                    types: "Type:",
                    deviceIds: "Device:",
                    userEmails: "User:",
                    alerts: "Alerts:"
                },
                confirmDeletion: "Confirm deletion",
                deleteOne: "Do you want to delete the task <b>{task}</b>?",
                deleteMany: "Do you want to delete <b>{count}</b> selected tasks?",
                deleteError: "Cannot delete tasks: ",
                deleteSuccess: "Tasks deleted: ",
                cannotDelete: "Cannot delete the following task(s):",
                types: {
                    export: "Publish from {from}",
                    import: "Import 3D models from files",
                    clashDetection: "Clash automation"
                }
            },
            upcomingTasks: {
                emptyTable: "No scheduled tasks",
                table: {
                    start: "Start time",
                    name: "Name",
                    taskType: "Task type",
                    device: "Device",
                    averageDuration: "Average duration",
                    user: "User",
                    schedule: "Schedule"
                },
                filters: {
                    startDate: "Start date:",
                    types: "Task type:",
                    deviceIds: "Device:",
                    alerts: "Alerts:",
                    userEmails: "User:"
                },
                startDatePresets: {
                    today: "Today",
                    tomorrow: "Tomorrow",
                    nextWeek: "Next week",
                    nextTwoWeek: "Next two weeks"
                }
            },
            taskHistory: {
                noTasks: "No completed tasks",
                title: "Task run history",
                table: {
                    start: "Started",
                    status: "Status",
                    taskName: "Name",
                    taskType: "Task type",
                    scheduledStartAt: "Scheduled start",
                    delay: "Delay",
                    duration: "Duration",
                    device: "Device",
                    user: "User",
                    ended: "Ended"
                },
                filters: {
                    startDate: "Started:",
                    types: "Task type:",
                    statuses: "Status:",
                    deviceIds: "Device:",
                    userEmails: "User:"
                },
                startDatePresets: {
                    yesterday: "Yesterday",
                    previousWeek: "Previous week",
                    previousTwoWeek: "Previous two weeks"
                },
                statuses: {
                    queued: "Queued",
                    running: "Running",
                    success: "Success",
                    failure: "Failed",
                    partialFailure: "Partially failed",
                    skipped: "Skipped",
                    userCancel: "Canceled",
                    deviceUnreachable: "Unreachable",
                    null: ""
                },
                hours: "h",
                minutes: "m",
                seconds: "s",
                emptyTable: "No completed tasks",
                detail: {
                    taskType: "Task type",
                    device: "Device",
                    user: "User",
                    started: "Scheduled start",
                    delay: "Delay",
                    ended: "Ended",
                    duration: "Duration"
                }
            },
            weekDays: {
                monday: "Mon",
                tuesday: "Tue",
                wednesday: "Wed",
                thursday: "Thu",
                friday: "Fri",
                saturday: "Sat",
                sunday: "Sun"
            }
        },
        workflow: {
            list: {
                workflow: "Workflow",
                statuses: "Statuses",
                issueTypes: "Issue types",
                actions: "Actions"
            },
            common: {
                duplicateSpace: " ",
                duplicateTooltip: "Duplicate",
                deleteTooltip: "Delete",
                selectWorkflowPlaceholder: "Select",
                buttonBack: "Back",
                descriptionLabel: "Description",
                cannotBeDeleted: "Standard statuses cannot be edited",
                workflows: "Workflows",
                issueTypes: "Issue types",
                maxTextCount: "Characters left:",
                resolvingClashGroups: "Resolved clash groups set issue status to",
                reappearingClashes: "Reopened clashes set issue status to",
                statusExists: "The status is already in the workflow",
                nameCantBeChanged: "Status names cannot be edited.",
                nameCantBeChangedDescription: "If you need to rename the status, create a new status with the desired name, add it to all required workflows, and then delete the old status and select the new one as a replacement.",
                categoryCantBeChanged: "The status category cannot be changed because the status belongs to an active workflow.",
                categoryCantBeChangedDescription: "If you need to change the category, delete the status from all workflows and from the project, and then create a new status with the same name but in a different category.",
                issueTypeCantBeChanged: "Names of issue types cannot be edited.",
                issueTypeCantBeChangedDescription: "If you need to rename the issue type, create a new type with the desired name in the same workflow, and then delete the old type and select the new one as a replacement."
            },
            issueTypes: {
                isDefault: "This is the default issue type",
                default: "Default",
                deleted: "Deleted",
                new: "New issue type",
                tableHeaders: {
                    type: "Issue type",
                    description: "Description",
                    workflow: "Workflow",
                    actions: "Actions"
                },
                filter: {
                    status: "Issue types",
                    all: "All",
                    active: "Active",
                    deleted: "Deleted"
                },
                add: {
                    name: "Name",
                    description: "Description",
                    workflow: "Workflow",
                    setAsDefault: "Set this issue type as default",
                    error: "Failed to create issue type",
                    icon: "Icon",
                    success: "Issue type created"
                },
                edit: {
                    title: "Edit issue type",
                    error: "Failed to edit issue type",
                    success: "Issue type updated",
                    changeType: "You are about to change the workflow for the issue type <b>\"{type}\"</b>. The new workflow <b>\"{workflowNew}\"</b> does not include some of the statuses that are present in the current workflow <b>\"{workflowOld}\"</b>. Please select replacement statuses for them.",
                    changeStatuses: "Issues of the <b>\"{type}\"</b> type will change statuses as indicated here",
                    summaryText: "The issue type <b>\"{type}\"</b> will be associated with the workflow <b>\"{workflowNew}\"</b>."
                },
                delete: {
                    title: "Delete issue type",
                    message: "You are about to delete the issue type <b>\"{name}\"</b>. Please select a replacement issue type for all issues, stamp templates, and clash tests that use this type.",
                    replaceStatuses: "The replacement issue type <b>\"{newName}\"</b> is associated with a different workflow, which does not include some of the statuses available for the issue type <b>\"{oldName}\"</b>. Please select replacement statuses for them.",
                    replaceStatuses2: "Issues of the type <b>\"{oldName}\"</b> will change type to <b>\"{newName}\"</b> and change statuses as indicated here",
                    confirmation1: "The issue type <b>\"{name}\"</b> will be deleted.",
                    confirmation2: "All issues, stamp templates, and clash tests that use the <b>\"{old}\"</b> issue type will change the type to <b>\"{new}\"</b>.",
                    confirmDelete: "Confirm deletion",
                    success: "Issue type \"{name}\" deleted"
                }
            },
            workflow: {
                delete: {
                    title: "Delete workflow",
                    text: "You are about to delete the workflow <b>\"{workflow}\"</b>. It has the following issue types associated with it:",
                    caption: "Please select a replacement workflow for these issue types:",
                    confirm: "Confirm deletion",
                    step2text: "Some of the statuses are missing from the workflow <b>\"{workflow}\"</b>. Please select replacement statuses for them.",
                    confirmText: "Do you want to delete the workflow <b>\"{workflow}\"</b>?",
                    emptyWorkflow: "Select a workflow"
                }
            },
            standard: "This is a standard workflow that cannot be deleted",
            clone: " (Copy)",
            createWorkflow: "Create workflow",
            editWorkflow: "Edit workflow",
            newWorkflow: "New workflow",
            statusTable: {
                status: "Status",
                category: "Category",
                action: "Action"
            },
            addStatus: "Add status",
            addType: {
                button: "Add type",
                newType: "New issue type",
                popupTitle: "Add issue types",
                popupText: "Select issue types to associate with the workflow <b>\"{workflow}\"</b>.",
                tableHeader: {
                    type: "Issue type",
                    workflow: "Current workflow"
                },
                popupReplaceText: "Some of the statuses that are associated with the selected issue types are missing from the workflow <b>\"{workflows}\"</b>. Please select replacement statuses for them.",
                summary: "Summary",
                summaryText: "<b>{count} issue type(s)</b> will be associated with the workflow <b>\"{workflow}\"</b>.",
                error: "Failed to add issue type(s)",
                success: "Issue type(s) added"
            },
            noTypes: "The workflow is not associated with any issue types",
            associatedTypes: "Associated issue types:",
            copyStatus: "Import from another workflow",
            createStatus: "Create status",
            editStatus: "Edit status",
            createStatusHint: "If you select \"Completed\", issues with this status will be hidden from the issue tracker by default. Only the issue authors and users with the \"Edit issue statuses and issue types\" right will be able to set this status.",
            statusConstructor: {
                preview: "Preview",
                selectColor: "Select color",
                background: "Background",
                text: "Text"
            },
            copyStatusTitle: "Select statuses to import",
            copyStatusText: "Select statuses to add to the workflow.",
            noData: "No matches found",
            status: {
                newStatus: "New status",
                add: {
                    name: "Name",
                    status: "New status",
                    title: "Add status to workflow",
                    hint: "Search for an existing status or type a name of a new status.",
                    placeholder: "Select or type",
                    createText: "New status",
                    description: "Description",
                    category: "Category"
                },
                delete: {
                    title: "Delete status from workflow",
                    step1text: "You are about to delete the status <b>\"{status}\"</b> from the workflow <b>\"{workflow}\"</b>. Please select a replacement status for all issues that have this status and use the <b>\"{workflow}\"</b> workflow.",
                    step2text: "The status <b>\"{status}\"</b> is no longer used in any of the project workflows. You can delete it from the project <b>\"{project}\"</b>, or you can keep it in the project  for future use.",
                    keep: "Keep in project",
                    delete: "Delete from project",
                    lastTitle: "Cannot delete status",
                    last: "Cannot delete the status <b>\"{status}\"</b> from the workflow <b>\"{workflow}\"</b>. The workflow must contain at least one status of \"<b>{category}\"</b> category.",
                    last_two: "Cannot delete the status <b>\"{status}\"</b> from the workflow <b>\"{workflow}\"</b>. The workflow must include at least one status of \"<b>{category1}\"</b> or \"<b>{category2}\"</b> category.",
                    process: "Cannot add status \"{status}\" because its deletion is still in progress. Try again in a few minutes.",
                    hint: "Search for an existing status or type a name of a new status."
                },
                editHint: "The status will be modified in all project workflows"
            }
        }
    },
    ProjectMembers: {
        label: {
            status: "Status:",
            role: "Role:",
            company: "Company:",
            department: "Department:",
            location: "Location:"
        }
    },
    Tooltips: {
        configurePresets: "Configure presets"
    },
    ActivityChart: {
        date: "Date",
        activeProjects: "Active projects",
        activeUsers: "Unique active users",
        currentName: "Current",
        sendExcel: {
            button: "Export to Excel",
            success: "Excel report was sent to your email"
        },
        datePeriods: {
            year: "360 days",
            half: "180 days",
            quarter: "90 days",
            month: "30 days",
            week: "7 days",
            yesterday: "24 hours"
        },
        total_amount_label: "Total amount of users for selected date interval: {count}",
        show_activity_of_guests: "Activity of guest users",
        licenseActivityTitle: "License member activity: {licenseName}",
        timeInterval: "Time period:"
    },
    Issue: {
        type: "Type",
        status: "Status",
        abbreviation: "Stamp",
        title: "Title",
        priority: "Priority",
        deadline: "Deadline",
        created: "Created",
        assignee: "Assignee",
        reporter: "Reporter",
        watchers: "Watchers",
        public: "Public",
        private: "Private",
        tags: "Tags",
        color: "Stamp color",
        fit: "Fit",
        minimize: "Zoom out",
        maximize: "Zoom in",
        procore: "Linked RFI",
        procoreStatus: "RFI status",
        rfi: "RFI #{number}: {subject}",
        draftRfi: "Draft RFI: {subject}",
        applyStampTemplate: "Apply stamp template",
        stamp: "Stamp",
        clashSourceFile: "Clash source file",
        grid: "Grid intersections",
        level: "Level",
        room: "Room",
        space: "Space",
        area: "Area",
        zone: "Zone",
        issuesCount: "{count} issue(s)"
    },
    Dashboard: {
        list: {
            newDashboard: "New dashboard",
            charts_order: "Arrange charts",
            settings: "Configure",
            send_now: "Send now",
            delivery: "Schedule delivery",
            includeDashboardFilters: "Include dashboard filters",
            empty: "Currently there are no dashboards in the project that are available to you.",
            deliveryList: "Dashboard recipients",
            refreshInProgress: "Refreshing charts...",
            publicPageEnabledBadge: "Public page available"
        },
        manageDashboards: "View all dashboards",
        filter: {
            label: {
                author: "Dashboard owner:",
                sharing: "Sharing:",
                privacy: "Issue privacy:",
                visibility: "Sharing:",
                color: "Color:",
                publicPage: "Public page"
            },
            values: {
                enabled: "Enabled",
                disabled: "Disabled"
            }
        },
        update: {
            add_title: "New dashboard",
            edit_title: "Dashboard settings",
            sharing_text: "The dashboard includes public issues that are available to the dashboard owner.",
            share_with_text: "All listed people will see all issues that are available to the dashboard owner.",
            privacy: {
                "1": "Public issues",
                "2": "All issues",
                "3": "Issues visible to the dashboard owner"
            },
            visibility: {
                "1": "Only me",
                "2": "License administrators"
            },
            sharing: {
                "1": "All project members",
                "2": "Selected project members",
                "3": "Project administrators",
                "4": "Only me"
            },
            labels: {
                name: "Dashboard name",
                privacy: "Privacy",
                sharing: "Sharing",
                members: "Share with",
                destinationProjects: "Destination projects"
            },
            buttons: {
                cancel: "Cancel",
                saveAsPreset: "Save as preset"
            },
            preset: {
                title: "Save as preset",
                create: "Create preset",
                update: "Update preset",
                createName: "Preset name",
                updateName: "Preset",
                select: "Select preset..."
            },
            errors: {
                emptyDashboardTitle: "Please specify the dashboard name.",
                emptyProjectsTitle: "Please specify the destination projects.",
                emptyChartTitle: "Please specify the chart name."
            }
        },
        remove: {
            title: "Confirm deletion",
            desc: "Do you really want to delete dashboard {dashboardTitle}?"
        },
        create: {
            btn: "Create new report",
            desc: "Create an issue report to be aware of what's happening on the project. You can also set up recurring email delivery to any number of persons to keep track of the project activities on a regular basis."
        },
        charts_order: {
            title: "Arrange charts"
        },
        loading: "Reports are loading",
        selectColor: "Select dashboard color",
        chart: {
            statusFlow: {
                statusNew: "{status} (new)"
            },
            invalidStartPeriod: "The start date must be earlier than the end date",
            invalidEndPeriod: "The end date must be later than the start date",
            loading: "The dashboard page will scroll to your chosen chart after all the other charts of the dashboard have loaded.",
            noChart: "Cannot generate the chart.",
            deadlines: "Missed Deadlines",
            no_issues: "There are no issues of interest in the project for the following time period:",
            calculation: "Calculation of chart",
            calculation_failed: "Calculation of chart failed. Please, tell us about that:",
            deleteText: "Do you really want to delete the chart \"{title}\"?",
            notUniqueTitle: "The title is not unique.",
            tableTitle: "Chart data",
            builtTime: {
                now: "Built just now",
                minutes: "Built {time} minute(s) ago",
                hours: "Built {time} hour(s) ago",
                days: "Built {time} day(s) ago"
            },
            buttons: {
                sendNow: "Send now",
                delivery: "Schedule delivery",
                issues: "Issues",
                settings: "Configure",
                importCharts: "Import charts",
                importDashboard: "Import dashboard",
                exportDashboard: "Export dashboard",
                refreshAll: "Refresh all",
                newChart: "New chart",
                fullSize: "Full size",
                halfSize: "Half size",
                openChart: "Open chart",
                sendChart: "Send chart",
                clone: "Duplicate"
            },
            settings: {
                add_title: "Create chart",
                edit_title: "Chart settings",
                addTitle: "Create chart: {chartTitle}",
                editTitle: "Chart settings: {chartTitle}",
                cannotHideBars: "Cannot hide bars with zero values when the chart is filtered by the primary grouping parameter.",
                total: "Total:\n{count}",
                clashGridSelect: {
                    noDataAvailable: "No grid intersections found"
                },
                buttons: {
                    delete: "Delete Chart",
                    addFilter: "Add Condition"
                },
                preview: {
                    title: "Preview",
                    all: "All",
                    issues: "Issues",
                    cantSendNewChart: "Please save the chart before you send it to your teammates.",
                    cantSheduleNewChart: "Please save the chart before scheduling the delivery."
                },
                name: {
                    title: "Name"
                },
                view: {
                    title: "View",
                    labels: {
                        type: "Chart type",
                        data: "Chart Data",
                        size: "Chart size",
                        show: "Show",
                        show_mode: "Show bars with zero values",
                        show_zero_values: "Show zero values in chart data table",
                        single_color: "Use single color"
                    },
                    type: {
                        "1": "Pie",
                        "2": "Bar",
                        "3": "Status flow",
                        "4": "Timeline",
                        "5": "Doughnut",
                        "7": "Treemap",
                        "8": "Bubble"
                    },
                    size: {
                        "1": "Full",
                        "2": "Half"
                    },
                    show: {
                        "1": "Count",
                        "2": "Percentage",
                        "3": "Average duration"
                    }
                },
                timePeriodForTimeLine: {
                    label: "Period Type",
                    "1": "Last 8 weeks",
                    "2": "Last 6 months",
                    "3": "Last year",
                    "4": "Last 3 years",
                    "5": "Last 4 weeks"
                },
                group: {
                    title: "Grouping",
                    group_head: "Group Issue by:",
                    show_tags_separately: "Show tags separately",
                    show_tags_separately_tooltip: "Issues with multiple tags will be included multiple times.",
                    show_grids_separately_tooltip: "Issues with multiple grid intersections will be included multiple times.",
                    add_grouping: {
                        secondary: "Add Secondary Grouping",
                        tertiary: "Add Tertiary Grouping"
                    }
                },
                sorting: {
                    title: "Sorting",
                    sort_head: "Sorting order:",
                    value: {
                        FIXED: "Fixed",
                        "BY-VALUE-ASC": "Ascending by value",
                        "BY-VALUE-DESC": "Descending by value"
                    }
                },
                filtersTitle: "Chart filters",
                filter: {
                    title: "Filters",
                    all_inclusion_label: "Meet ALL of the following conditions",
                    any_inclusion_label: "Meet ANY of the following conditions",
                    filterConflict: "Filter conflict",
                    hasRestrictedValues: "There is a dashboard-wide filter that overrides this filter. The chart data might be inaccurate.",
                    field: {
                        project: "Project",
                        projectTags: "Project tags",
                        assignee: "Assignee",
                        reporter: "Reporter",
                        watchers: "Watchers",
                        priority: "Priority",
                        status: "Status",
                        customStatus: "Status",
                        customType: "Type",
                        statusCategory: "Status category",
                        tags: "Tags",
                        visibility: "Visibility",
                        createdFrom: "Issue origin",
                        title: "Title",
                        deadline: "Deadline",
                        closed: "Completion date",
                        created: "Date created",
                        duration: "Duration",
                        mainSheet: "Source sheet",
                        mainSheetTag: "Source sheet tags",
                        type: "Is a clash",
                        clashCategory: "Clash categories [Navisworks]",
                        clashDiscipline: "Clash disciplines [Navisworks]",
                        clashGrid: "Grid intersections",
                        clashGridX: "Grid letter",
                        clashGridY: "Grid number",
                        clashSourceFile: "Source file",
                        clashTest: "Clash test",
                        clashLevel: "Level",
                        clashRoom: "Room",
                        clashSpace: "Space",
                        clashArea: "Area",
                        clashZone: "Zone",
                        stampAbbr: "Stamp",
                        stampCategory: "Stamp category",
                        stampCategoryByTitle: "Stamp category",
                        stampColor: "Stamp color",
                        procoreType: "Is an RFI",
                        procoreStatus: "RFI status",
                        assigneeCompany: "Assignee company",
                        assigneeDepartment: "Assignee department",
                        assigneeLocation: "Assignee location",
                        reporterCompany: "Reporter company",
                        reporterDepartment: "Reporter department",
                        reporterLocation: "Reporter location",
                        old_status: "Previous status",
                        new_status: "Current status"
                    },
                    label: {
                        project: "Project:",
                        assignee: "Assignee:",
                        reporter: "Reporter:",
                        priority: "Priority:",
                        status: "Status:",
                        customStatus: "Status:",
                        statusCategory: "Status category:",
                        customType: "Type:",
                        tags: "Tags:",
                        stampAbbr: "Stamp:",
                        stampCategory: "Stamp category:",
                        stampColor: "Stamp color:",
                        clashLevel: "Level:",
                        clashRoom: "Room:",
                        clashSpace: "Space:",
                        clashArea: "Area:",
                        clashZone: "Zone:",
                        clashGrid: "Grid intersections:",
                        clashTest: "Clash test:",
                        clashSourceFile: "Source file:",
                        procoreType: "Is an RFI:",
                        procoreStatus: "RFI status:",
                        assigneeCompany: "Assignee company:",
                        assigneeDepartment: "Assignee department:",
                        assigneeLocation: "Assignee location:",
                        reporterCompany: "Reporter company:",
                        reporterDepartment: "Reporter department:",
                        reporterLocation: "Reporter location:",
                        old_status: "Previous status:",
                        new_status: "Current status:",
                        line: "Lines:"
                    },
                    expr: {
                        "1": "Is any of",
                        "-1": "Is none of",
                        "2": "Include any of",
                        "3": "Include all of",
                        "-3": "Do not include",
                        "4": "Is",
                        "5": "Less than",
                        "7": "More than",
                        "9": "Set to",
                        "-9": "Not set",
                        "11": "In range",
                        "12": "Include",
                        "-12": "Do not include",
                        date: {
                            moreThan: "More than",
                            withinLast: "Within the last",
                            custom: "Custom"
                        },
                        deadline: {
                            "4": "Is",
                            "5": "Earlier than",
                            "7": "Later than",
                            "11": "In range",
                            "-9": "Not set",
                            nowOverdue: "Overdue",
                            moreThan: "More than",
                            dueInNext: "Due in next",
                            custom: "Custom"
                        },
                        duration: {
                            "4": "Equal to",
                            "5": "Less than",
                            "7": "Greater than"
                        },
                        title: {
                            "12": "Contains",
                            "-12": "Doesn't contain"
                        }
                    },
                    timeUnit: {
                        "1": "Weeks",
                        "2": "Months",
                        "3": "Years",
                        "4": "Today",
                        "5": "Days",
                        "6": "Exact date",
                        withAgo: {
                            "1": "Weeks ago",
                            "2": "Months ago",
                            "3": "Years ago",
                            "4": "Today",
                            "5": "Days ago",
                            "6": "Exact date"
                        }
                    },
                    direction: {
                        "1": "Ahead",
                        "2": "Ago"
                    },
                    status: {
                        open: "Open",
                        in_progress: "In progress",
                        solved: "Solved",
                        closed: "Closed",
                        deleted: "Deleted"
                    },
                    priority: {
                        none: "None",
                        trivial: "Trivial",
                        minor: "Minor",
                        major: "Major",
                        critical: "Critical",
                        blocker: "Blocker"
                    },
                    statusCategory: {
                        "to do": "To do",
                        tracking: "Tracking",
                        completed: "Completed"
                    },
                    visibility: [
                        "Private",
                        "Public"
                    ],
                    tags: {
                        null: "No tags",
                        notSelectedTags: "Other tags or no tags",
                        "multiple-tags": "Multiple tags from the grouping"
                    },
                    type: {
                        "1": "Nonclash",
                        "3": "Clash"
                    },
                    clashRoom: {
                        "multiple-clashValues": "Multiple rooms",
                        "non-clash": "No room"
                    },
                    clashArea: {
                        "multiple-clashValues": "Multiple areas",
                        "non-clash": "No area"
                    },
                    clashZone: {
                        "multiple-clashValues": "Multiple zones",
                        "non-clash": "No zone"
                    },
                    clashSpace: {
                        "multiple-clashValues": "Multiple spaces",
                        "non-clash": "No space"
                    },
                    clashLevel: {
                        "multiple-clashValues": "Multiple levels",
                        "non-clash": "No level"
                    },
                    clashTest: {
                        "multiple-clashValues": "Multiple tests",
                        "non-clash": "Nonclash issues",
                        unknown: "Unknown test"
                    },
                    clashSourceFile: {
                        "multiple-clashValues": "Multiple source files",
                        "non-clash": "Nonclash issues"
                    },
                    stampAbbr: {
                        null: "No stamps"
                    },
                    clashDisciplineType: {
                        "0": "None",
                        "1": "Architectural",
                        "2": "Structural",
                        "4": "Mechanical",
                        "8": "Electrical",
                        "16": "Plumbing",
                        "31": "All",
                        "4095": "Coordination"
                    },
                    procoreType: {
                        "1": "Non-RFI",
                        "2": "RFI"
                    },
                    procoreStatus: [
                        "Non-RFI",
                        "Draft",
                        "Open",
                        "Official response",
                        "Closed"
                    ],
                    createdFrom: {
                        "0": "None",
                        "2": "2D",
                        "3": "3D"
                    }
                },
                time_period: {
                    title: "Time Period",
                    not_valid: "Custom time period is not valid",
                    type: {
                        "1": "Predefined",
                        "2": "Custom"
                    },
                    pre_defined: {
                        "1": "Previous Day",
                        "2": "Previous Week",
                        "3": "Previous Month",
                        "4": "Current Day",
                        "5": "Current Week",
                        "6": "Current Month"
                    },
                    custom: {
                        "1": "Today",
                        "2": "Days ago",
                        "3": "Weeks ago",
                        "4": "Months ago"
                    }
                }
            },
            send: {
                title: "Send chart",
                title_delivery: "Chart delivery",
                format: {
                    label: "Format",
                    "0": "PDF",
                    "1": "PDF with issues",
                    "2": "XLS"
                }
            },
            clash: {
                "non-clash": "No clash"
            }
        },
        issues: {
            title: "Issues",
            all: "All issues",
            loading: "Issues are loading"
        },
        settings: {
            title: "General",
            name: "Name",
            period: "Time Period",
            tag: "Tag",
            all: "All",
            any: "Any",
            reporter: "Reporter",
            assignee: "Assignee",
            priority: "Priority",
            edit: "Edit",
            save: "Save",
            cancel: "Cancel",
            filtersTitle: "Dashboard filters"
        },
        delivery: {
            title: "Delivery",
            title_dashboard_send: "Send dashboard",
            title_dashboard_delivery: "Dashboard delivery",
            regular: "Regular Delivery",
            add_recipient: "Add recipient",
            one_time: "One-time instant delivery",
            send_now: "Send now",
            send_to: "Send to",
            recipients: "Recipients",
            weekdays: "Weekday(s)",
            month: "Day(s) of the month",
            hour: "Time",
            timezone: "Time zone",
            format: "Format",
            chart: "Chart",
            excel: "Excel",
            pdf: "PDF",
            send: "Send now",
            report_sent: "Report sent",
            report_failed: "Failed. Please try again",
            done: "Done",
            current: "Current",
            enabled: "Deliver on schedule"
        },
        chart_time_lines: {
            title_required: "Please specify line {index} title",
            add_line: "Add New line",
            remove_line: "Remove line",
            lineType: "Line type",
            linesTitle: "Lines",
            lineColor: "Line color",
            types: {
                "1": "Issues",
                "2": "Objects"
            },
            showForecast: "Show forecast",
            numberOfForecastPoints: "Forecast points",
            predictedValue: "(predicted)"
        },
        othersNMore: "{n} more",
        current: "Current dashboard",
        currentFull: "Current dashboard:",
        favorites: {
            errorTitle: "Edit favorites",
            errorInvalidParams: "Invalid parameters",
            errorPermission: "Access denied",
            addToFavorites: "Add to favorites",
            removeFromFavorites: "Remove from favorites"
        },
        subscribeText: "The dashboard will be emailed to you on schedule.",
        unsubscribeText: "You will no longer receive the dashboard by email. You can subscribe to the dashboard again at any time.",
        openOnNewTab: "Open in a new tab",
        share_settings: {
            edit: "Manage share settings",
            select: "Select dashboard",
            topnav_button: "Edit public page",
            title_dashboard_sharing: "Edit public link",
            public_link: "Public link",
            topnav_public_page: "Public page:",
            share_option_title: "Enable public link",
            share_option_description: "A public link allows users who don't have access to the project to view the dashboard with limited functionality (they can't view issues that contribute to the dashboard).",
            label_share_option: "Access settings",
            option_any: "Anyone with the link can view the dashboard",
            option_by_password: "Only people who have a password can view the dashboard",
            show_sign_in_option: "Show the \"Sign in\" button on the destination page",
            show_sign_in_option_description: "A project member can sign in to view the interactive version of the dashboard.",
            copy_link: "Copy link",
            get_embed_code: "Get embed code",
            sharing_enabled: "Sharing enabled",
            sharing_disabled: "Sharing disabled",
            no_charts: "The dashboard has no charts",
            no_dashboard_selected: "No dashboard selected",
            password_required: "Enter the password"
        },
        chart_share_settings: {
            button: "Share",
            share_options_description: "A public link allows users who don't have access to the project to view the chart with limited functionality (they can't view issues that contribute to the chart).",
            option_any: "Anyone with the link can view the chart",
            option_by_password: "Only people who have a password can view the chart",
            show_sign_in_option_description: "Only people who have a password can view the chart",
            share_option_description: "A public page allows users who don't have access to the project to view the chart with limited functionality (they can't view issues that contribute to the chart)."
        },
        publicSignIn: {
            projectDashboard: "If you are a member of the Revizto project, you can sign in to open the interactive version of the dashboard",
            licenseDashboard: "If you are a member of the Revizto license, you can sign in to open the interactive version of the dashboard",
            projectGraph: "If you are a member of the Revizto project, you can sign in to open the interactive version of the chart",
            licenseGraph: "If you are a member of the Revizto license, you can sign in to open the interactive version of the chart"
        },
        publicPassword: {
            title: {
                projectDashboard: "Dashboard access",
                licenseDashboard: "Dashboard access",
                projectGraph: "Chart access",
                licenseGraph: "Chart access"
            }
        }
    },
    Report: {
        empty: "You don't have any reports",
        add_button_in_center: "New report",
        add_button: "Add New Report",
        delete_button: "Delete Report",
        each_month: "Every month",
        add_title: "Create Report",
        edit_title: "Report Settings",
        send_title: "Send Report",
        everyTwoWeeksOn: {
            "1": "Every two weeks on Monday",
            "2": "Every two weeks on Tuesday",
            "3": "Every two weeks on Wednesday",
            "4": "Every two weeks on Thursday",
            "5": "Every two weeks on Friday",
            "6": "Every two weeks on Saturday",
            "7": "Every two weeks on Sunday"
        },
        settings: {
            period_title: "Time period",
            filters_title: "Filter issues",
            sharing_title: "Sharing",
            report_type: {
                label: "Type",
                "0": "XLS",
                "2": "PDF"
            },
            visibility: {
                label: "Visibility",
                "0": "Public issues",
                "1": "Visible to the owner",
                "2": "Visible to all invited"
            }
        },
        errors: {
            empty_title: "Please specify report title"
        },
        issue_selection_type: {
            label: "Show status at",
            "1": "Current date",
            "2": "Previous date"
        },
        label: {
            exportFormat: "Format:",
            title: "Title:",
            author: "Owner:",
            deliveryPeriod: "Delivery period:",
            deliveryWeekdays: "Delivery weekdays:",
            subscribers: "Subscribers:"
        },
        remove: {
            title: "Confirm deletion",
            desc: "Do you really want to delete \"{reportTitle}\"?"
        }
    },
    Markup: {
        edit: "Edit markup",
        app: "Go to the Revizto application",
        error: {
            sheets: "<b>Editing sheet markups is not supported.</b> <br><br> You can edit the markup in the Revizto application.<br><br>",
            background: "Cannot load background"
        },
        dialogTitle: "Markup",
        toolbar: {
            cursor: "Move",
            pen: "Pen",
            line: "Line",
            rectangle: "Rectangle",
            arrow: "Arrow",
            callout: "Callout",
            ellipse: "Ellipse",
            polyline: "Polygon",
            openpolyline: "Polyline",
            text: "Text",
            load: "Upload image",
            undo: "Undo",
            redo: "Redo",
            camera: "Take photo"
        },
        inspector: {
            title: {
                none: "Multiple items",
                rectangle: "Rectangle",
                pen: "Pen",
                line: "Line",
                arrow: "Arrow",
                callout: "Callout",
                ellipse: "Ellipse",
                polyline: "Polygon",
                openpolyline: "Polyline",
                text: "Text"
            },
            itemTitles: {
                mainColor: "Main color",
                background: "Background",
                border: "Border",
                line: "Line",
                lineAndBorder: "Line & Border",
                stroke: "Stroke",
                text: "Text",
                arrowheads: "Arrowheads"
            },
            itemText: {
                line: "Line",
                arrow: "Arrow"
            },
            items: {
                color: "Color",
                opacity: "Opacity",
                weight: "Width",
                type: "Type",
                border: "Border",
                size: "Size",
                alignment: "Alignment",
                start: "Start",
                end: "End"
            },
            noneValueText: "None"
        },
        textDialog: {
            title: "Edit text"
        }
    },
    Backend: {
        weekdays: {
            "1": "Mo",
            "2": "Tu",
            "3": "We",
            "4": "Th",
            "5": "Fr",
            "6": "Sa",
            "7": "Su"
        },
        weekdaysFull: {
            "1": "Monday",
            "2": "Tuesday",
            "3": "Wednesday",
            "4": "Thursday",
            "5": "Friday",
            "6": "Saturday",
            "7": "Sunday"
        }
    },
    SendNow: {
        pdfTitle: "PDF options",
        xlsTitle: "XLS options",
        send: "Send now"
    },
    DeliveryOptions: {
        logo: "Include company logo",
        dataTables: "Include data tables",
        comments: "Include comments",
        updates: "Include updates",
        images: "Bigger images",
        deletedIssues: "Include deleted issues",
        customFieldsLabel: "Additional Fields",
        orderByLabel: "Order by",
        includeUserComments: "Include issue comments (text only)",
        includeAttachments: "Include issue attachments",
        includeFieldChanges: "Include issue field changes",
        includeMarkupChanges: "Include issue markup changes",
        includeCompletedIssues: "Include closed issues",
        previewSize: {
            label: "Image size",
            "0": "Small images",
            "1": "Large images",
            "2": "No images"
        },
        fields: {
            id: "ID",
            tags: "Tags",
            title: "Title",
            watchers: "Watchers",
            type: "Is a clash",
            public: "Public",
            sheet: "Sheet",
            status: "Status",
            customStatus: "Status",
            customType: "Type",
            assignee: "Assignee",
            reporter: "Reporter",
            priority: "Priority",
            created: "Created",
            deadline: "Deadline",
            project: "Project",
            stamp: "Stamp",
            stampColor: "Stamp color",
            stamp_color: "Stamp color",
            clashDiscipline: "Clash discipline [Navisworks]",
            clashDistance: "Clash distance (ft) [Navisworks]",
            clashDistanceM: "Clash distance (m) [Navisworks]",
            clashDistanceMm: "Clash distance (mm) [Navisworks]",
            clashCategory: "Clash category [Navisworks]",
            clashTest: "Clash test",
            clashLevel: "Level",
            clashRoom: "Room",
            clashSpace: "Space",
            clashArea: "Area",
            clashZone: "Zone",
            clash_room: "Room",
            clash_space: "Space",
            clash_area: "Area",
            clash_zone: "Zone",
            clashSourceFile: "Clash source file",
            clash_test: "Clash test",
            clash_discipline: "Clash discipline [Navisworks]",
            clash_distance: "Clash distance (ft) [Navisworks]",
            clash_distance_m: "Clash distance (m) [Navisworks]",
            clash_distance_mm: "Clash distance (mm) [Navisworks]",
            clash_category: "Clash category [Navisworks]",
            clash_level: "Level",
            clash_source_file: "Clash source file",
            clash_grid_x: "Grid letter [Navisworks]",
            clash_grid_y: "Grid number [Navisworks]",
            clashGrid: "Grid intersections"
        },
        time: "Time",
        period: "Delivery period",
        scheduledTitle: "Scheduled delivery",
        periodType: {
            "1": "None",
            "2": "Each Week",
            "3": "Each Two Weeks",
            "4": "Each Month"
        },
        eachMonthInfo: "Delivered on the first Monday of each month.",
        weekday: "Weekday",
        weekdays: "Weekday(s)",
        addExternalRecipient: "Add external recipient",
        addSort: "Add another"
    },
    TwoFA: {
        label: "Two-factor authentication",
        labelShort: "2FA",
        preferredMethod: "Current method:",
        email: "Email",
        googleAuth: "Authenticator app",
        enableTitle: "Enable two-factor authentication",
        disableTitle: "Disable two-factor authentication",
        enableInfo: "How would you like to receive your security codes?",
        disableInfo: "If you disable two-factor authentication, you’ll only need your email and password to sign in to Revizto.",
        googleLabel: "Authenticator app",
        googleDesc: "Get security codes from Google Authenticator, Microsoft Authenticator, or 1Password (recommended)",
        emailLabel: "Email",
        emailDesc: "Get security codes by email (less secure)",
        passwordConfirmation: "For security, please enter your password for {email}",
        passwordConfirmationError: "Invalid password",
        qrCodeScan: "Please use your authenticator app to scan the QR code.",
        qrCodeEnter: "Or enter this code in your authenticator app.",
        securityCodeEmail: "We have just sent you an email with the security code. Please enter it below.",
        securityCodeGoogle: "Please enter the security code from your authenticator app.",
        securityCodeError: "Wrong code: that code didn't work. Please check the code and try again.",
        hasBeenUsedCodeError: "The security code has already been used",
        emailProblem: "If you did not receive the email after several attempts, please contact our <a class=\"link\" href=\"mailto:service@revizto.com\">technical support</a>.",
        finalEmail: "From now on, when you sign in to Revizto, you’ll have to enter a security code sent to you by email.",
        finalGAuth: "From now on, when you sign in to Revizto, you’ll have to enter a security code from your authenticator app. If you lose access to the app, you will be able to receive a security code by email.",
        fromApplication: "ATTENTION! If you came to this page from the Revizto application, please restart the application.",
        resendCode: "Resend code",
        resendTimer: "Will be available in {secondsLeft} sec",
        notAccessGA: "I don’t have access to the authenticator app",
        enable: "Enforce two-factor authentication for all users that use this authentication method",
        preliminary: "Two-factor authentication adds an extra layer of protection to your account. When you sign in to Revizto, first you enter your password, and then an additional security code.",
        oneOfYourLicenses: "One of your licenses requires two-factor authentication.",
        redirectToSecurity: "This license requires two-factor authentication.<br>Sign in to the license to set up two-factor authentication."
    },
    TimeFormat: {
        label: "Time format",
        "1": "24h",
        "2": "12h"
    },
    DateFormat: {
        label: "Date format",
        "1": "DD/MM/YYYY",
        "2": "MM/DD/YYYY",
        "3": "DD.MM.YYYY",
        "4": "MM-DD-YYYY",
        "5": "DD-MM-YYYY",
        "6": "YYYY-MM-DD"
    },
    DateDifferenceMessages: {
        today: "Today",
        yesterday: "Yesterday",
        tomorrow: "Tomorrow",
        days: "{count}d",
        moreMonth: "{count}m",
        moreYear: "1y+"
    },
    SortDirection: {
        asc: "Asc",
        desc: "Desc"
    },
    role_management: {
        back_to_project: "Back to project",
        manage_levels: "Manage Project Roles",
        view_levels: "View Project Roles",
        edit_name: "Edit name",
        name_tips: "Please enter the name of the project role",
        success_saved: "Changes saved successfully",
        success_added: "New project role successfully created",
        saved_as_new: "Save as new role",
        delete_level: "Delete this level",
        previous_owner: "Specify access for the previous owner",
        previous_owner_tips: "You are about to change the project owner. Please specify the project role for the previous owner of the the project.",
        is_want_to_save: "You have made changes to the rights level \"{levelName}\". Do you want to save them?",
        dont_save: "Don't Save",
        is_want_to_delete: "The role \"{levelName}\" is assigned to {count} user(s) across {projects} project(s) throughout the license. If you delete this role, those projects will be affected. Do you want to assign a replacement role to those users?",
        delete_level_title: "Delete level",
        save_as: "Save project role as:",
        new_level_name: "Please enter the name of the new project role",
        same_name_already_exists: "The role with the same name already exists",
        new_role_name_restricted: "This role name is restricted",
        select_level: "Select project rights level:",
        has_following_rights: "<strong>{userName}</strong> has the following rights:",
        editRoleNameLabel: "Enter a new name for \"{accessRole}\"",
        enterNewRoleName: "Enter a name for the new role.",
        roleNameTooLong: "The name is too long (> {maxLength} characters).",
        deletion: {
            notUsedRoleMainText: "Are you sure that you want to delete the role \"{accessRole}\"?",
            usedRoleMainText: "You are about to delete the role \"{accessRole}\", which is assigned to {membersCount} user(s) across {projectsCount} project(s) throughout the license.",
            usedRoleHelpText: "If you delete this role, those projects will be affected.",
            usedRoleReplacementHelpText: "To proceed, assign a replacement role."
        },
        newRole: "New role",
        compareRoles: "Compare roles",
        roleNotSelected: "Select one or several project roles",
        roleName: "Role name",
        users: "Users",
        projects: "Projects",
        editPermissions: "Edit role",
        involvedUsers: "Users that have the role",
        involvedProjects: "Projects that have the role",
        numberOfUsers: "Users",
        newProjectRole: "New project role",
        permissionsOverview: "All access rights",
        differencesOverview: "Differences",
        showDifferences: "Show differences"
    },
    Role: {
        permissions: {
            edit_3d: {
                title: "Manage all 3D models",
                info: "Grants the right to manage all models in the project. The \"Transform scene\" operation additionally requires the \"Assign custom object properties\" right."
            },
            append_3d: {
                title: "Add new 3D models",
                info: "Grants the right to add models to the project, and the right to edit and delete the models added by this user.<br/><br/>This does not grant the right to overwrite models added by other users."
            },
            edit_2d: {
                title: "Manage all sheets",
                info: "Grants the right to manage all sheets in the project."
            },
            append_2d: {
                title: "Add new sheets",
                info: "Grants the right to add sheets to the project, and the right to edit and delete the sheets added by this user.<br/><br/>This does not grant the right to overwrite sheets added by other users."
            },
            manage_export_sheets_to_pdf: {
                title: "Export sheets to PDF",
                info: "Grants the right to export sheets to PDF."
            },
            manage_export_project_to_exe: {
                title: "Export projects to EXE/Save local project copies",
                info: "Grants the right to export Revizto projects to standalone executable files for Microsoft Windows, and save local project copies."
            },
            add_edit_viewpoints: {
                title: "Add and edit viewpoints",
                info: "Grants the right to add and edit viewpoints, except for the home viewpoint."
            },
            add_edit_videotracks: {
                title: "Add and edit videotracks",
                info: "Grants the right to add and edit videotracks."
            },
            view_public_issues: {
                title: "View public issues",
                info: "Grants the right to view public issues created by other users.<br/><br/>Without this right, a user can view only the issues that they created, the issues assigned to them, and the issues watched by them."
            },
            create_issue: {
                title: "Create issues",
                info: "Grants the right to create issues."
            },
            comment_issue: {
                title: "Comment issues",
                info: "Grants the right to add comments and attachments to any issue visible to this user.<br/><br/>Without this right, a user can add comments and attachments only to the issues that they created and the issues assigned to them."
            },
            edit_issue_status: {
                title: "Edit issue statuses (except completion)",
                info: "Grants the right to change the status of any visible issue to a status of \"To do\" or \"Tracking\" categories.<br/><br/>Without this right, a user can do that only to the issues that they created and the issues assigned to them."
            },
            close_issue: {
                title: "Edit issue statuses and issue types",
                info: "Grants the right to edit the status and type of any issue visible to the user.<br/><br/>Without this right, a user can do that only to the issues that they created themselves."
            },
            edit_issue_title: {
                title: "Edit issue titles",
                info: "Grants the right to edit the title of any issue visible to the user.<br/><br/>Without this right, a user can only edit the titles of the issues that they created."
            },
            edit_issue_deadline: {
                title: "Edit issue deadlines",
                info: "Grants the right to edit the deadline of any issue visible to the user.<br/><br/>Without this right, a user can only edit the deadlines of the issues that they created."
            },
            edit_issue_assignee: {
                title: "Edit issue assignees",
                info: "Grants the right to edit the assignee of any issue visible to the user.<br/><br/>Without this right, a user can only edit the assignees of the issues that they created and reassign the issues assigned to them."
            },
            edit_issue_reporter: {
                title: "Edit issue reporters",
                info: "Grants the right to edit the reporter of any issue visible to the user.<br/><br/>Without this right, a user can only edit the reporters of the issues that they created."
            },
            edit_issue_watchers: {
                title: "Edit issue watchers",
                info: "Grants the right to edit the watchers of any issue visible to the user.<br/><br/>Without this right, a user can edit only the watchers of the issues that they created and the issues assigned to them, and add themselves as a watcher to any issue visible to them."
            },
            edit_issue_visibility: {
                title: "Edit issue privacy",
                info: "Grants the right to edit the privacy (public or private) of any issue visible to the user.<br/><br/>Without this right, a user can only edit the privacy of the issues that they created."
            },
            tag_issue: {
                title: "Tag issues",
                info: "Grants the right to tag or untag issues (using the tags that exist in the project). This does not grant the right to create new tags.<br/><br/>Without this right, a user can only tag or untag the issues that they created and the issues assigned to them."
            },
            create_new_tags: {
                title: "Create issue tags",
                info: "Grants the right to create new issue tags. Creating an issue tag adds it to the list of tags available in the project."
            },
            edit_issue_markup: {
                title: "Edit issue markups",
                info: "Grants the right to edit the markup and 3D view of any issue visible to the user.<br/><br/>Without this right, a user can only edit the markups and 3D views of the issues that they created and the issues assigned to them."
            },
            delete_issue: {
                title: "Delete issues",
                info: "Grants the right to delete any issue visible to this user.<br/><br/>Without this right, a user can delete only the issues that they created."
            },
            manage_project_rights: {
                title: "Administrative rights",
                info: "Grants administrative rights in the project."
            },
            revert_project_to_older_revision: {
                title: "Revert project to older versions",
                info: "Grants the right to revert a project to an older version."
            },
            manage_issue_tags_across_project: {
                title: "Rename and delete tags",
                info: "Grants the right to rename issue tags, and permanently delete issue tags from the project."
            },
            edit_issue_priority: {
                title: "Edit issue priority",
                info: "Grants the right to edit the priority of any issue visible to the user.<br/><br/>Without this right, a user can only edit the priorities of the issues that they created."
            },
            manage_issue_filter_set: {
                title: "Manage shared issue filter presets",
                info: "Grants the right to share issue filter presets with the project team, and manage shared issue filter presets."
            },
            manage_issue_preset: {
                title: "Manage stamp templates",
                info: "Grants the right to create, edit, and delete stamp templates and stamp categories.<br/><br/>This does not grant the right to edit stamp issues."
            },
            export_to_ifc: {
                title: "Export project to IFC",
                info: "Grants the right to export Revizto projects to IFC."
            },
            manage_search_set: {
                title: "Manage shared search sets",
                info: "Grants the right to share search sets with the project team, and manage shared search sets."
            },
            manage_favorite_property_set: {
                title: "Manage shared favorite properties",
                info: "Grants the right to share favorite properties presets with the project team, and manage shared favorite properties presets."
            },
            manage_object_properties: {
                title: "Manage custom object properties",
                info: "Grants the right to create, rename, and permanently delete custom object properties in the project."
            },
            assign_object_properties: {
                title: "Assign custom object properties",
                info: "Grants the right to add existing custom properties to objects, and edit custom property values for any object."
            },
            manage_appearance_templates: {
                title: "Manage shared appearance templates",
                info: "Grants the right to share appearance templates with the project team, and manage shared appearance templates."
            },
            allow_clash_sync: {
                title: "Sync Navisworks clashes",
                info: "Grants the right to sync Navisworks clashes with the issue tracker.<br/><br/>This includes creating clash issues, editing clash issue statuses, and editing clash issue markups with the \"Sync Clashes\" tool in the Navisworks plug-in.<br/><br/>This does not grant the right to edit issue statuses and markups manually."
            },
            clash_test_admin: {
                title: "Clash administrator",
                info: "Grants full control over all clash tests."
            },
            create_clash_test: {
                title: "Create clash tests",
                info: "Grants the right to create clash tests."
            },
            view_public_clash_test: {
                title: "View public clash tests",
                info: "Grants the right to view public clash tests in the \"Clash automation\" module.<br/><br/>This right does not affect the visibility of clash issues in the issue tracker."
            },
            manage_shared_entities: {
                title: "Share charts and dashboards publicly",
                info: "Grants the right to share charts and dashboards owned by this user by generating a link that allows anonymous access."
            }
        },
        errors: {
            noChangeBecauseLimit: "Role wasn't changed because your license limit would be exceeded.",
            noChangeBecauseServerError: "The role wasn't changed due to a server error",
            roleNameExist: "The role name already exists"
        },
        titles: {
            role_info: "Role info",
            change_role_for_user: "Change role for \"{username}\"",
            change_role_for_users: "Change role for {count} selected users"
        },
        editRoleButon: "Edit role name",
        permissionGroup1: "Project administration",
        permissionGroup2: "Issue tracker",
        permissionGroup3: "3D",
        permissionGroup4: "Export from Revizto",
        permissionGroup5: "2D",
        permissionGroup6: "Clash automation",
        permissionGroup7: "Dashboards",
        name: "Role name"
    },
    LicenseStats: {
        totalUsers: "Total users",
        paidUsers: "Full members",
        guests: "Guests",
        projects: "Project capacity: {number} out of {totalNumber}",
        unlimited: "Project capacity: {number} out of Unlimited"
    },
    userActionResultNotification: {
        allSaved: "All changes have been saved.",
        dashboardDeleted: "Dashboard has been deleted.",
        dashboardSent: "Dashboard has been sent.",
        chartDeleted: "Chart has been deleted.",
        chartSent: "Chart has been sent.",
        fileBeingProcessedChart: "Preparing to send the chart.",
        fileBeingProcessedReport: "Preparing to send the report.",
        fileBeingProcessedDashboard: "Preparing to send the dashboard.",
        presetCreated: "Preset has been created.",
        presetDeleted: "Preset has been deleted.",
        presetsDeleted: "Presets have been deleted.",
        activitySent: "You should receive an email with the excel report shortly",
        tagsAdded: "Tag has been added.",
        tagsAdded_plural: "Tags have been added.",
        tagsDeleted: "Tag has been deleted.",
        tagsDeleted_plural: "Tags have been deleted.",
        stampsDeleted: "Stamp template deleted.",
        stampCategoryDeleted: "Stamp category deleted.",
        stampsAdded: "Stamp template added.",
        stampCategoryAdded: "Stamp category added.",
        stampCategoryUpdated: "Stamp category updated.",
        stampUpdated: "Stamp template updated.",
        projectsDeleted: "Project(s) deleted.",
        projectsArchived: "Project(s) archived.",
        projectArchived: "Project archived.",
        projectsRestored: "Project(s) restored.",
        projectRestored: "Project restored.",
        logoDeleted: "Logo deleted.",
        emailSent: "Email sent.",
        emailSent_plural: "Emails sent.",
        messageSent: "Message sent.",
        memberDeleted: "Member has been removed.",
        memberDeleted_plural: "Members have been removed.",
        memberInvited: "Member has been invited.",
        memberInvited_plural: "Members have been invited.",
        memberInvitationError: "Some member invitations resulted in an error.",
        memberActivated: "Member has been activated.",
        memberActivated_plural: "Members have been activated.",
        memberDeactivated: "Member has been deactivated.",
        memberDeactivated_plural: "Members have been deactivated.",
        memberUpdated: "Member have been updated.",
        memberUpdated_plural: "Members have been updated.",
        unsubscribed: "You have been unsubscribed.",
        passwordChanged: "Password was changed successfully",
        invitationResend: "Invitation has been resent.",
        invitationResend_plural: "Invitations have been resent.",
        issuesSuccessfullyRestored: "{count} issue(s) restored.",
        IssuesCantBeRestored: "Cannot restore {count} issues.",
        issuesSuccessfullyUpdated: "{count} issue(s) updated.",
        IssuesCantBeUpdated: "Cannot update {count} issues.",
        issuesSuccessfullyDeleted: "{count} issue(s) deleted.",
        IssuesCantBeDeleted: "Cannot delete {count} issue(s).",
        restoreIssue: "Restore issues",
        restoreIssueConfirm: "Do you want to restore {count} issue(s)?",
        deleteIssue: "Delete issues",
        deleteIssueConfirmTitle: "Do you want to delete {count} issue(s)?",
        deleteIssueConfirmText: "You can view deleted issues at any time (Presets > Deleted issues).",
        accessCodeCopied: "Access code copied to clipboard.",
        deleteIssuePermissionsText: "If you do not have the rights to delete some issues, they will be skipped.",
        filtersIncorrect: "Server error. The filter might provide incorrect results. Please reload the page and apply the filter again.",
        stampError: "Cannot create stamp template.",
        stampUpdateError: "Cannot update stamp template.",
        duplicateStamp: "Duplicate stamp abbreviation.",
        subscribedSuccessfully: "Subscribed to the dashboard.",
        unsubscribedSuccessfully: "Unsubscribed from the dashboard.",
        taskRemoved: "Task(s) deleted",
        taskRemovedError: "Cannot delete task",
        cannotRemoveCustomStatus: "Failed to delete status",
        statusesRemoved: "Status deleted",
        statusAdded: "Status added",
        cannotAddStats: "Failed to add status",
        statusUpdated: "Status updated",
        workflowRemoved: "Workflow deleted",
        cannotRemoveWorkflow: "Failed to delete workflow",
        workflowCreateError: "Failed to create workflow",
        workflowCreateOptionError: "Cannot create workflow because your license does not allow workflows",
        workflowUpdateError: "Failed to update workflow",
        statusesReordered: "Status order saved",
        workflowUpdated: "Workflow saved",
        workflowCreated: "Workflow created",
        errorCloneDashboard: "Cannot duplicate dashboard"
    },
    Footer: {
        legal: "Legal"
    },
    Header: {
        copy: "Revizto SA © {currentYear}",
        helpCenter: "Help center"
    },
    TagEditor: {
        createNew: "Create new"
    },
    Boarding: {
        title: "Welcome to Revizto",
        text1: "You have created your account. To start working with Revizto, download the Revizto application.",
        text2: "Your current role in the \"{licenseName}\" license is \"{licenseRole}\".",
        support: "If you have any questions, email us at <a class=\"text-link link\" href=\"mailto:service@revizto.com\">service@revizto.com</a>.",
        welcomeTitle: "Welcome to Revizto Workspace",
        welcomeStep: {
            title: "You have created a Revizto account.",
            paragraph: "To gain access to all Revizto features, download and install the Revizto application",
            button: "Download"
        },
        steps: {
            projectTeam: {
                title: "Project team",
                text: "<p>View and manage your team on the <b>Project Team</b> page.</p><p>Invite new members and specify their access rights.</p>"
            },
            dashboards: {
                title: "Dashboards",
                text: "<p>Visualize project data with our dashboards, create charts, and track the progress of your project.</p><ul><li>Share dashboards or charts with your colleagues.</li><li>Export your dashboards to PDF documents or spreadsheets.</li><li>Set up scheduled email reports.</li></ul>"
            },
            webIssueTracker: {
                title: "Web issue tracker",
                text: "<p>The web issue tracker complements the capabilities of the Revizto application and allows you to collaborate with your team from any device without installing the application.</p><p>View and edit issues, leave comments, and create markups. Find issues using the filters or create issue filter presets for quick access.</p>"
            },
            notifications: {
                title: "Notifications",
                text: "<p>With the notification center, you will be aware of all real-time events in your projects: issue updates, generated reports, user invites, and more.</p><p>Track the recent changes in your project.</p>"
            }
        },
        next: "Next",
        previous: "Previous"
    },
    DisabledButtonTooltip: {
        oneProject: "You should select at least one project",
        archiveProject: "You should select at least one active project",
        restoreProject: "You should select at least one archived project",
        oneMember: "You should select at least one user",
        deactivateMember: "You should select at least one active/pending user",
        reactivateMember: "You should select at least one deactivated user",
        pendingMember: "You should select at least one pending user",
        deleteDashboardPreset: "Select presets to delete.",
        createDashboardPreset: "Enter the preset name.",
        sendDashboard: "Add recipient email addresses.",
        inviteProjectMember: "Select users or email addresses, and then select a project role for them.",
        twoFAType: "Select two-factor authentication type.",
        twoFACode: "Enter the security code.",
        twoFAPassword: "Enter the password.",
        tagsSelect: "Select tags or enter new ones.",
        notificationChange: "You have not changed anything.",
        additionalForPendingApproval: "Cannot edit the selected user(s) because they have the \"Pending approval\" status."
    },
    ActivityStatistics: {
        label: {
            tags: "Tags:"
        },
        type: {
            linear: "Line chart",
            cumulativeLinear: "Cumulative line chart"
        }
    },
    Logs: {
        operation: "Operation",
        creator: "Creator",
        code: "Code",
        message: "Message",
        invitedInLicense: "Invited in license as",
        invitedInProject: "Invited in project as",
        alreadyInLicense: "The user is already in the license.",
        alreadyInLicense_pl: "The users are already in the license.",
        alreadyInProject: "The user is already in the project.",
        roleNotChanged: "The user role was not changed.",
        licenseRole: "License role",
        projectRole: "Project role",
        licenseRoleChanged: "License role changed to",
        projectRoleChanged: "Project role changed to",
        projectRoleDeleted: "Project role deleted",
        newRole: "New role",
        oldRole: "Old role",
        newAuthMethod: "New authentication method",
        oldAuthMethod: "Old authentication method",
        movedFromLicense: "Moved from license",
        movedToLicense: "Moved to license",
        newAccessRole: "New project role",
        newAccessRoles: "New project roles",
        newLicenseMember: "New license member",
        newLicenseMembers: "New license members",
        showPermissions: "Show access rights",
        changedPermissions: "Changed access rights",
        renamedFrom: "Renamed from",
        noLogs: "No logs found",
        deletedMember: "Deleted pending user",
        notChanged: "no changes",
        createdAuthMethod: "Created authentication method <b>{title}</b> with type <b>{type}</b>",
        deletedAuthMethod: "Deleted authentication method <b>{title}</b> with type <b>{type}</b>.",
        updatedAuthMethod: "Updated authentication method <b>{title}</b> with type <b>{type}</b>.",
        isCreatedAuthMethodDefault: "This method is now the default one.",
        authMethodNoLongerDefault: "Authentication method <b>{title}</b> is no longer the default one.",
        authMethodUpdated: "Updated authentication method: <b>{title}</b>, method type: <b>{type}</b>",
        authMethodUpdatedDeleted: "Updated authentication method: <i>deleted method</i>.",
        authMethodRevoked: "Revoked authentication method <b>{title}</b> with type <b>{type}</b> from <b>{member}</b>.",
        authMethodReplaced: "Changed authentication method \"<b>{oldTitle}</b>\" to \"<b>{newTitle}</b>\" for <b>{member}</b>.",
        title: "title",
        active: "active",
        default: "default",
        relatedDomains: "related domains",
        operationFailed: "Operation failed",
        operationNotCompleted: "Operation not completed. Expected result:",
        searchSetColon: "Search set: ",
        searchSetFolderColon: "Search set folder: ",
        childrenCount: "Search sets:",
        logUsersTitle: "User: ",
        logUsersTitle_plural: "Users: ",
        label: {
            title: "Title:",
            name: "Name: ",
            tags: "Tags: ",
            type: "Type: ",
            owner: "Owner: ",
            author: "Author: ",
            project: "Project: ",
            license: "License: ",
            assign: "Sales representative:",
            expires: "Expires: ",
            abbreviate: "Abbreviation:",
            description: "Description: ",
            userSlots: "User slots: ",
            projectSlots: "Project slots: ",
            operationType: "Operation type: "
        },
        version: "Revizto version: {version}",
        dashboard: "Dashboard: ",
        crossProjectDashboard: "Cross-project dashboard: ",
        chart: "Chart: ",
        more: "More",
        less: "Less",
        workflow: {
            name: "Name:",
            icon: "Icon:",
            empty: "<Empty>",
            type: "Issue type:",
            changeDescription: "Description:",
            changeTypeDescription: "Description:",
            addStatus: "Status:",
            workflow: "Workflow:",
            newType: "Issue type:",
            deletedType: "Deleted type:",
            replaceType: "Replacement type:",
            restore: "Issue type:",
            deletedStatus: "Deleted status:",
            replacementStatus: "Replacement status:",
            workflowChanged: "Workflow:",
            statusChanged: "Is default:",
            reappearedClashStatus: "Status of reopened clash issues:",
            resolvedClashStatus: "Status of resolved clash issues:"
        },
        sharedDashboards: {
            shared: "Public link:",
            pass: "Public link password updated",
            hasLinkToWs: "Link to workspace:",
            isProtected: "Public link password:"
        }
    },
    IssueTracker: {
        copy_link_app: "Copy link (app)",
        copy_link_web: "Copy link (web)",
        multiple: "<Multiple values>",
        rfi: "RFI",
        dashboardLink: {
            highlight: "Issues in the selected data series",
            outdated: "The list of issues might be outdated.",
            issuesDependingOnYourRights: "The number of issues displayed might vary depending on your rights in the project.",
            return: "Return to chart",
            filter: "Show filters",
            tooltip: "You opened the issue tracker by clicking a data series. It displays only the issues that belong to the series.",
            showChart: "Chart settings",
            showIssues: "View issues",
            disallow: "Cannot open the data series in the issue tracker because it is based on multiple values."
        },
        status: {
            open: "Open",
            in_progress: "In progress",
            solved: "Solved",
            closed: "Closed",
            deleted: "Deleted",
            accepted: "Accepted",
            draft: "Draft"
        },
        sort: {
            unreadFirst: "Unread first",
            ascending: "Ascending",
            descending: "Descending",
            order: "Order",
            primary: "Primary",
            secondary: "Secondary",
            assignee: "Assignee",
            created: "Created",
            dateCreated: "Created",
            deadline: "Deadline",
            comment: "Last commented",
            commentDate: "Last commented",
            update: "Last updated",
            updateDate: "Last updated",
            priority: "Priority",
            status: "Status",
            clashes: "Number of clashes"
        },
        presets: {
            allIssues: "All issues",
            currentIssues: "Current issues",
            deletedIssues: "Deleted issues",
            reorder: "Arrange filter presets",
            share: "Share",
            delete: "Delete",
            drafts: "Drafts",
            savePreset: "Save preset",
            createNew: "Create a preset",
            updateExisting: "Update a preset",
            enterPresetName: "Enter preset name",
            selectPreset: "Select preset",
            shareWith: "Share with the project team",
            onlyVisible: "The preset will be visible only to you. Your project access rights don't allow sharing issue filter presets.",
            enterOrChoose: "Enter a preset name or select an existing preset.",
            filterByIDCantBeSaved: "Cannot save a filter by ID as an issue filter preset.",
            filterByTitleCantBeSaved: "Cannot save a filter by title as an issue filter preset.",
            filtersByIDAndTitleCantBeSaved: "Cannot save a filter by ID and title as an issue filter preset.",
            filterByDeletedCantBeSaved: "Cannot save a filter that includes deleted issues as an issue filter preset.",
            deletePresets: "Delete presets",
            wantDelete: "Do you want to delete the preset(s)?",
            filterIdWarning: "Issue filter presets do not support filtering by ID. The preset will be saved without this filter.",
            filterTitleWarning: "Issue filter presets do not support filtering by title. The preset will be saved without this filter.",
            filterIdAndTitleWarning: "Issue filter presets do not support filtering by ID and title. The preset will be saved without these filters.",
            nameTooLong: "The preset name is too long. ",
            onlyMembersUnshare: "To be able to unshare issue filter presets, add yourself to the project team.",
            onlyMembersReorder: "To be able to arrange issue filter presets, add yourself to the project team.",
            onlyMembersDelete: "To be able to delete shared issue filter presets, add yourself to the project team.",
            dontHavePermissionToDelete: "Your project access rights don't allow deletion of shared issue filter presets.",
            dontHavePermissionToUnshare: "Your project access rights don't allow unsharing issue filter presets."
        },
        fileInput: "Attachment",
        deleteSelected: "Delete selected issues",
        print: {
            includeUserComments: "Include comments (text only)",
            includeAttachments: "Include attachments",
            includeFieldChanges: "Include field changes",
            includeMarkupChanges: "Include markup changes",
            additionalFields: "Issue fields",
            documentTooLarge: "The document is too large.",
            generateReport: "Please generate a PDF report instead.",
            printSelectedToPdf: "Print selected issues to PDF"
        },
        convertTo360: "Convert to 360°",
        convertToImage: "Convert to flat image",
        markAsRead: "Mark as read",
        currentUser: "Current user",
        issues: "Issues",
        unseen: "Unread",
        countIssues: "{selectedIssuesCount} out of {issuesCount}",
        noIssuesToDisplay: "No issues to display",
        presetBrokenOnFrontend: "Some of the filters that are included in the preset are not supported in the web issue tracker. The preset might provide inaccurate results.",
        presetBrokenInApp: "The preset might provide inaccurate results. To correct this, open it in the Revizto application, check the warning that is displayed, and correct the preset.",
        presetHasDeletedStatus: "The preset is based on a deleted issue status and does not return any issues.",
        headers: {
            markup: "Markup",
            issues: "Issues",
            presets: "Filter presets",
            info: "Info",
            chat: "Chat"
        },
        readAllUnseen: "Mark all as read",
        multiMessage: {
            title: "Add comment",
            buttonName: "Add comment",
            messageSuccess: "Added comment to {count} issues",
            cantSend: "{count} comment(s) were not added due to an error.",
            hasNoRightsWarning: "Not enough rights to comment on {x} out of {y} selected issues. Do you want to comment on the remaining issues?"
        },
        diffComments: {
            changed: "Changed {entity}:",
            added: "Added:",
            deleted: "Deleted:",
            issueDeleted: "Deleted this issue",
            issueRestored: "Restored this issue",
            entity: {
                camera: "camera",
                colorSelect: "object colors",
                phaseSelect: "phase",
                sectioncuts: "section",
                selections: "selection",
                sheet: "source sheet",
                snapshot: "markup",
                visSelect: "visibility",
                clash: "clashes",
                stampAbbr: "stamp abbreviation",
                reporter: "reporter",
                status: "status",
                stampColor: "stamp color",
                tags: "tags",
                priority: "priority",
                assignee: "assignee",
                watchers: "watchers",
                backgroundType: "background type",
                deadline: "deadline",
                title: "title",
                visibility: "visibility",
                level: "level",
                room: "room",
                zone: "zone",
                area: "area",
                space: "space",
                grid: "grid intersections",
                customStatus: "status",
                customType: "type"
            },
            procore: {
                elevatedIssueToProcoreRFI: "Issue elevated to a Procore RFI",
                connectedIssueToProcore: "Issue elevated to a Procore RFI",
                detachedFromProcore: "Issue detached from a Procore RFI",
                changedRFISubject: "RFI subject changed to: {subject}",
                officialReply: "Marked as official response: {officialReply}",
                status: {
                    draft: "Elevated to Draft RFI",
                    "draft-open": "Elevated to Draft RFI",
                    "draft-in_progress": "Draft RFI: Official response",
                    "draft-closed": "Draft RFI: Closed",
                    open: "Elevated to RFI #{rfiNumber}",
                    in_progress: "RFI #{rfiNumber}: Official response",
                    closed: "RFI #{rfiNumber}: Closed",
                    accepted: "RFI #{rfiNumber}: Official response"
                }
            }
        },
        filters: {
            type: "Is a clash",
            customType: "Type",
            statusCategory: "Status category",
            createdFrom: "Issue origin",
            binding: "Issue origin",
            assignee: "Assignee",
            assigneeCompany: "Assignee company",
            assigneeDepartment: "Assignee department",
            assigneeLocation: "Assignee location",
            watchers: "Watchers",
            priority: "Priority",
            status: "Status",
            customStatus: "Status",
            deadline: "Deadline",
            reporter: "Reporter",
            tags: "Tags",
            stampAbbr: "Stamp",
            stampCategory: "Stamp category",
            clashDiscipline: "Clash discipline [Navisworks]",
            clashLevel: "Level",
            clashArea: "Area",
            clashRoom: "Room",
            clashSpace: "Space",
            clashZone: "Zone",
            clashSourceFile: "Source file",
            clashTest: "Clash test",
            clashGridX: "Grid letter",
            clashGridY: "Grid number",
            clashCategory: "Clash category [Navisworks]",
            procoreType: "Is an RFI",
            procoreStatus: "RFI status",
            category: "Clash category [Navisworks]",
            id: "ID",
            created: "Date created",
            closed: "Completion date",
            mainSheet: "Source sheet",
            mainSheetTag: "Source sheet tags",
            visibility: "Visibility",
            title: "Title",
            stampColor: "Stamp color",
            duration: "Duration",
            label: {
                customType: "Type:",
                customStatus: "Status:",
                statusCategory: "Status category:",
                type: "Is a clash:",
                binding: "Issue origin:",
                assignee: "Assignee:",
                assigneeCompany: "Assignee company:",
                assigneeDepartment: "Assignee department:",
                assigneeLocation: "Assignee location:",
                watchers: "Watchers:",
                priority: "Priority:",
                status: "Status:",
                deadline: "Deadline:",
                reporter: "Reporter:",
                tags: "Tags:",
                stampAbbr: "Stamp:",
                duration: "Duration:",
                stampCategory: "Stamp category:",
                clashDiscipline: "Clash discipline [Navisworks]:",
                clashLevel: "Level:",
                clashArea: "Area:",
                clashRoom: "Room:",
                clashSpace: "Space:",
                clashZone: "Zone:",
                clashSourceFile: "Source file:",
                clashTest: "Clash test:",
                clashGridX: "Grid letter:",
                clashGridY: "Grid number:",
                clashCategory: "Clash category [Navisworks]:",
                procoreType: "Is an RFI:",
                procoreStatus: "RFI status:",
                id: "ID:",
                created: "Date created:",
                closed: "Completion date:",
                mainSheet: "Source sheet:",
                mainSheetTag: "Source sheet tags:",
                visibility: "Visibility:",
                stampColor: "Stamp color:"
            },
            label_not: {
                customStatus: "Status not:",
                customType: "Type not:",
                type: "Is not:",
                binding: "Issue origin not:",
                assignee: "Assignee not:",
                assigneeCompany: "Assignee company not:",
                assigneeDepartment: "Assignee department not:",
                assigneeLocation: "Assignee location not:",
                watchers: "Watchers not:",
                priority: "Priority not:",
                status: "Status not:",
                reporter: "Reporter not:",
                tags: "Tags not:",
                stampAbbr: "Stamp not:",
                stampCategory: "Stamp category not:",
                clashDiscipline: "Clash discipline [Navisworks] not:",
                clashLevel: "Level not:",
                clashArea: "Area not:",
                clashRoom: "Room not:",
                clashSpace: "Space not:",
                clashZone: "Zone not:",
                clashSourceFile: "Source file not:",
                clashTest: "Clash test not:",
                clashGridX: "Grid letter not:",
                clashGridY: "Grid number not:",
                clashCategory: "Clash category [Navisworks] not:",
                procoreType: "Is not:",
                procoreStatus: "RFI status not:",
                id: "ID not:",
                mainSheet: "Source sheet not:",
                mainSheetTag: "Source sheet tags not:",
                visibility: "Visibility not:",
                stampColor: "Stamp color not:",
                statusCategory: "Status category not:"
            },
            procore: {
                type: {
                    regular: "Non-RFI",
                    rfi: "RFI"
                },
                status: {
                    notConnected: "Non-RFI",
                    draft: "Draft",
                    open: "Open",
                    accepted: "Official response",
                    closed: "Closed"
                }
            }
        },
        timeFilters: {
            reset: "Reset",
            and: "and",
            issuesWithoutDeadline: "Only issues without a deadline",
            issuesOverdue: "Only overdue issues",
            daysOverdue: "days overdue",
            daysAgo: "days ago",
            moreThanDaysOverdue: "More than {days} day(s) overdue",
            moreThanDaysAgo: "More than {days} day(s) ago",
            overdue: "Overdue",
            days: "days",
            includeOverdueNow: "Include overdue issues",
            relative: "Relative",
            is: "Is",
            gt: ">",
            ge: ">=",
            lt: "<",
            le: "<=",
            between: "Between",
            from: "From {date}",
            before: "Before {date}",
            durationMoreThan: "More than {days} {unit}",
            durationLessThan: "Less than {days} {unit}",
            durationEqual: "Equal {days} {unit}",
            timeUnits: {
                days: "days",
                weeks: "weeks",
                months: "months",
                years: "years"
            }
        },
        button: {
            openInApp: "Open in app",
            restore: "Restore",
            delete: "Delete",
            resend: "Resend"
        },
        chat: {
            clearSelections: "Clear selection",
            selectedCount_plural: "{count} issues selected",
            comments: "Text comments",
            pinnedMessages: "Pinned comments",
            attachments: "Attachments",
            fieldChanges: "Field updates",
            markupChanges: "Markup updates",
            loader: "Sending",
            error: "Cannot send the comment.",
            errorDetails: {
                "-55": "The license is suspended.",
                "-2": "You do not have enough rights.",
                "-20": "You do not have enough rights.",
                "-13": "The issue is deleted.",
                "-1": "Unexpected error.",
                "500": "The connection is lost.",
                notAllowed: "You don't have enough rights to comment on the issue."
            },
            pin: "Pin",
            unpin: "Unpin"
        },
        info: {
            deadlineNotSet: "Not set",
            tags: {
                currentTags: "Current tags",
                addTags: "Add tags",
                removeTags: "Remove tags"
            },
            watchers: {
                currentWatchers: "Current watchers",
                addWatchers: "Add watchers",
                removeWatchers: "Remove watchers"
            },
            assignee: {
                toMe: "Assign to me"
            }
        },
        customType: {
            selectNewStatus: {
                title: "Select new status",
                message1: "You are about to change the issue type to <b>\"{newTypeName}\"</b>.",
                message2: "Issues of this type cannot have the <b>\"{currentStatusName}\"</b> status. Please select a replacement status for this issue."
            },
            selectNewStatuses: {
                title: "Select new statuses",
                message1: "You are about to change the issue type for {count} issues.",
                message2: "{conflictCount} out of {allCount} issues have statuses that are not included in the workflow of the \"{newTypeName}\" issue type. Please select replacement statuses for them."
            }
        },
        issuesWithWarningsModal: {
            title: "Cannot change some of the issues",
            reasons: {
                notAllowedType: "The selected status is not allowed by the issue type for {issuesWithProblemsCount} out of {allCount} issues.",
                permissionError: "You don't have enough rights to change this field for {issuesWithProblemsCount} out of {allCount} issues.",
                navisIssuesCantChangeType: "Cannot change issue type for \"Navisworks clash\" issues.",
                notAllowedToChangeStatusCategoryDone: "You don't have enough rights to change the status of completed issues: {issuesWithProblemsCount} out of {allCount}."
            },
            toViewListOfIssues: "To view the list of issues where you cannot change this field, open them in a new tab.",
            button: "Open {issuesWithProblemsCount} issue(s) in new tab"
        },
        cantApplyMultiEdit: {
            title: "Cannot apply changes",
            statusAndTypeNotCompatible: {
                message: "Cannot apply the type \"{typeName}\" and the status \"{statusName}\" together because they are associated with different workflows.",
                applyWithout: "Undo status change"
            }
        },
        warningNotFullyApplied: {
            message1: "The changes will be fully applied to {issuesWithProblemsCount} out of {allCount} issues. For other issues, some or all of the fields will not be changed.",
            message2: "To learn why they cannot be changed, go back and review the warnings next to the issue fields."
        },
        invalidIssueType: {
            title: "The issue has invalid type",
            message1: "The status cannot be edited because the issue has invalid type.",
            message2: "Please change the issue type first"
        }
    },
    IssueSettings: {
        tags: {
            buttons: {
                create: "Create tag",
                rename: "Rename tag",
                delete: "Delete tag"
            },
            noTags: "There are no tags in the project.",
            noTagsFound: "No tags found.",
            tagTooLong: "The tag is too long. The limit is {limit} characters.",
            linkFromIssueTracker: "Manage tags",
            create: {
                title: "New tag",
                tag: "Tag",
                exist: "The tag is not unique.",
                successMessage: "Tag added.",
                errorMessage: "Tag not added."
            },
            edit: {
                successMessage: "Tag updated.",
                errorMessage: "Tag not renamed."
            },
            remove: {
                title: "Delete tags",
                message: "You are going to delete {n} tag from the project:",
                message_plural: "You are going to delete {n} tags from the project:",
                warning: "The tag(s) will also be removed from all issues. If they duplicate another tag, you can merge them with this tag instead of deleting.",
                warning_plural: "This tag will also be removed from all issues. If it duplicates another tag, you can merge it with this tag instead of deleting.",
                merge: "Merge with another tag",
                mergeDisabled: "No tags for merging",
                successMessage: "Tag(s) deleted.",
                errorMessage: "Tag(s) not deleted."
            },
            renameConflict: {
                title: "Select action",
                description: "The tag \"{tag}\" already exists.",
                actionRename: "Edit the tag name",
                actionMerge: "Merge with existing tag"
            }
        },
        stamps: {
            deleteStamp: "Delete stamp templates",
            deleteStampCategory: "Delete stamp category",
            exists: "The stamp template abbreviation already exists",
            buttons: {
                add: "Create stamp template",
                clone: "Duplicate stamp template",
                delete: "Delete stamp template",
                move: "Move stamp template",
                addCategory: "Create stamp category",
                cloneCategory: "Duplicate stamp category",
                deleteCategory: "Delete stamp category"
            },
            stamp: "Abbreviation",
            stamps: "Stamp templates",
            category: "Category",
            placeholder: "Select category",
            noCategoryText: "A stamp is an issue that has a special pin with 1–4 characters on it. Stamps are designed to report repetitive problems throughout the project.<br /><br />Stamp templates can include predefined issue field values (title, color, assignee, tags, and so on). Only project members with sufficient rights can create stamp templates.<br /><br />This project has no stamp templates. To add demo stamp templates to the project, click the button below.",
            selectAction: "Select action",
            importAs: "Import as",
            skip: "Skip the stamp template",
            apply: "Apply to all {count} similar conflict(s)",
            new: "New",
            existing: "Existing",
            noCategoryStamp: "To create your own stamp template, click the button below.",
            addDemoStamps: "Add demo templates",
            noCategory: "No category",
            selectDemoStamps: "Select the demo stamp templates to add to your project.",
            demoStampCategories: {
                DACH: "Germany, Austria, Switzerland (design)",
                FR: "France",
                UK1: "United Kingdom (construction)",
                UK2: "United Kingdom (design)",
                APAC: "Australia & New Zealand",
                NA: "USA & Canada"
            },
            regularIssue: "<not found>",
            longTitle: "The title is too long.",
            removeConfirmation: "Do you want to delete the stamp template(s)?",
            removeCategoryConfirmation: "Do you want to delete the stamp category?",
            removeCategoryConfirmationWithStamps: "Do you want to delete the stamp category and {count} stamp template(s)?",
            noStamps: "The category does not contain any stamp templates.",
            createStamp: "Create stamp template",
            newStampTemplate: "New stamp template",
            copyStampTemplate: "Duplicate stamp template",
            abbreviation: "Abbreviation",
            assignee: "Assignee",
            color: "Color",
            deadline: "Deadline",
            priority: "Priority",
            customType: "Type",
            public: "Public",
            tags: "Tags",
            title: "Title",
            watchers: "Watchers",
            disableRegularIssues: "Disable creation of regular issues",
            newCategory: "New category",
            newCategoryPlaceholder: "Enter category name",
            duplicateCategory: "Duplicate category",
            copiedCategoryName: "{name} (copy)",
            brokenStampTitle: "Invalid stamp template: \"{stamp}\"",
            brokenStampText: "The stamp template contains references to the entities that are not found in the project:",
            brokenStampInformation: "To fix the template, either add the missing entities, or click the \"Clean up\" button to remove the references.",
            cleanUp: "Clean up",
            categoryName: "Category name",
            categoryInfo: "Category info",
            stampInfo: "Template info",
            regularIssuesEnable: "Do you want to allow creation of regular issues in the project?",
            regularIssuesDisable: "Do you want to deny creation of regular issues in the project?",
            existingCategory: "Select category",
            createCategory: "Create category",
            moveStampsTitle: "Move stamp templates",
            st: "ST"
        }
    },
    Login: {
        tabInternal: "Revizto login",
        tabSso: "SSO",
        ssoService: "Authentication service",
        serviceLoading: "Loading service list",
        services: {
            google: "Google (Business)",
            saml: "SAML"
        },
        invalidSSOCode: "Invalid one-time password.",
        noLicensesInRegion: "You have no active licenses in the selected region.",
        pleaseEnterPassword: "Please enter your Revizto password.",
        welcome: "Welcome!",
        ssoRegion: "Revizto license region",
        selectLicense: "Select license",
        didntGetEmail: "I didn't get the email",
        passwordMustContain: "The password must contain:",
        passwordContains: "The password contains:",
        atLeastMCharsNotMoreThanNChars: "{m}–{n} characters.",
        oneLowercase: "A lowercase English letter.",
        oneUppercase: "An uppercase English letter.",
        aNumber: "A number.",
        licenseLogin: "Sign in",
        requiresAdditionalAuth: "The license <b>\"{licenseName}\"</b> requires additional authorization.",
        requiresAdditionalAuthReviztoAccount: "The license <b>\"{licenseName}\"</b> requires additional authorization on your Revizto account.",
        requiresAdditionalAuthCorporateGoogle: "The license <b>\"{licenseName}\"</b> requires additional authorization on your corporate Google account.",
        forgotPasswordCorporate: "Please ask your company system administrator to restore password",
        continue: "Continue",
        return: "Return",
        returnToLogin: "Return to login",
        selectLicenseToContinue: "Select license to continue",
        pleaseReviztoPhrase: "Please enter your Revizto account data",
        companyDomain: "Company domain",
        companyDomainNotFound: "Invalid company domain.",
        loginIntoYourDomain: "Login into your domain",
        youWillNeedToCorporate: "You will need to log into your corporate account",
        appAuthorized: {
            success: "You have successfully signed in",
            closeWindow: "Please close this browser window and return to the Revizto application."
        },
        wsAuthorized: {
            success: "You are successfully authorized",
            youWillBeRedirected: "Now you will be automatically redirected to the required license",
            goManually: "Go manually to the license",
            wrongGoogleAccount: "The Google account does not match the email that you entered at the previous step.",
            selectRightGoogleAccount: "At the next step, select the Google account with the email address that you enter at this step."
        },
        noLicenses: "You have no Revizto licenses in the specified region",
        forgotPassword: {
            successReset: "You have reset your password."
        },
        methodInfoModal: {
            connectionInformation: "Connection information",
            forTheConnection: "Your identity provider might require the following parameters:",
            toSignIn: "To sign in using this method, a user might be required to enter the following:",
            loginReplyUrl: "・ Login response URL: <span class=\"info-url amp-mask\">{replyUrl}</span>",
            metadataUrl: "・ Metadata URL: <span class=\"info-url amp-mask\">{metadataUrl}</span>",
            logoutUrl: "・ Logout response URL: <span class=\"info-url amp-mask\">{logoutUrl}</span>",
            entityId: "・ Service provider entity ID: <span class=\"info-url amp-mask\">{entityId}</span>"
        },
        yourEntityId: "Your service provider entity ID",
        checking: "Waiting for response",
        authError: "Authentication error.",
        setAsDefault: "Set as default",
        confirm: "Confirm",
        accessCodeNoLicenses: "You have no Revizto licenses with API access in the {region} region.",
        noAvaliableLicenses: "You have no Revizto licences with appropriate auth rule. Please try to login from workspace page first."
    },
    Table: {
        selectCurrentPage: "Select items on this page",
        selectAllPages: "Select all items",
        unselectCurrentPage: "Clear items on this page",
        unselectAllPages: "Clear all items",
        showAll: "Show all ({count})",
        selectAllNRows: "Select all {n} rows",
        nRowsSelected: "{n} rows selected",
        showSelected: {
            users: "Selected users ({count})",
            projects: "Selected projects ({count})",
            tasks: "Selected tasks ({count)}"
        },
        shownSelected: {
            users: "Only previously selected users displayed",
            projects: "Only previously selected projects displayed",
            tasks: "Only previously selected tasks displayed"
        },
        shownCount: "{from}–{to} of {total}"
    },
    Redirect: {
        toActiveLicense: "Go to valid license",
        toMyProjects: "Go to My Projects",
        toDashboards: "Go to Dashboards",
        toUserManagement: "Go to User Management"
    },
    MyProjects: {
        allIssues: "All issues",
        myOpenIssues: "My open issues",
        reportedByMe: "Reported by me",
        closedIssues: "Completed issues",
        unreadIssues: "Unread issues",
        project: "Project",
        projectRole: "Project role",
        abbreviation: "Abbreviation",
        notifications: "Email notifications",
        lastModified: "Last modified",
        versions: "Revizto version",
        actions: "Actions",
        label: {
            projectRole: "Project role:",
            notifications: "Notifications:"
        },
        category: {
            active: "Active",
            archived: "Archived",
            suspended: "Suspended"
        }
    },
    ImportExportDashboard: {
        selectProject: "Select source project",
        selectDashboard: "Select dashboard",
        selectCharts: "Select charts",
        configureImport: "Configure import settings",
        configureExport: "Configure export settings",
        completeExportDashboards: "Your dashboard export is completed",
        completeImportDashboards: "Your dashboard import is completed",
        completeImportCharts: "Your charts import is completed",
        noDashboardsFound: "No dashboards found.",
        dashboardName: "Dashboard name: {name}",
        dashboardExported: "Dashboard exported into {count} project(s)",
        dashboardNotExported: "Dashboard not exported into {count} project(s)",
        chartsImported: "{count} chart(s) imported",
        chartsNotImported: "{count} chart(s) not imported",
        currentProject: "Current project",
        noChartsFound: "No charts found.",
        noCharts: "The dashboard has no charts."
    },
    ColorPicker: {
        buttonOpen: "Dashboard color",
        colors: "Colors",
        custom: "Custom",
        recent: "Recent",
        palette: {
            revizto: "Revizto colors",
            stamps: "Revizto stamps",
            status: "Revizto statuses",
            priority: "Revizto priorities",
            random: "More colors",
            dashboard: "Dashboard colors"
        }
    },
    Colors: {
        white: "White",
        red: "Red",
        orange: "Orange",
        yellow: "Yellow",
        lime: "Light green",
        green: "Green",
        sky: "Light blue",
        blue: "Blue",
        purple: "Purple",
        violet: "Pink",
        black: "Black"
    },
    changePassword: {
        errors: {
            weakPassword: "Your password is too simple.",
            repeatingPassword: "Your password cannot match your 10 previous passwords.",
            linkExpired: "This password reset link has expired."
        },
        expired: {
            info: "The password reset link has expired.",
            link: "Please click here to get a new link."
        }
    },
    Notification: {
        success: "Success",
        warning: "Warning",
        error: "Error",
        notifications: "Notifications",
        issueUpdates: "Issue updates",
        reports: "Reports",
        other: "Other",
        noNotifications: "You don't have any notifications yet.",
        allNotificationsRead: "You have read all your notifications.",
        totalIssues: "Total issues: {count}",
        markAllAsRead: "Mark all as read",
        showUnreadOnly: "Show unread only",
        processing: "Processing...",
        issues: "{count} issue",
        issues_plural: "{count} issues",
        issues_plural2: "",
        issuesAdded: "{count} issue created",
        issuesAdded_plural: "{count} issues created",
        issuesDeleted: "{count} issue deleted",
        issuesDeleted_plural: "{count} issues deleted",
        issuesUpdated: "{count} issue updated",
        issuesUpdated_plural: "{count} issues updated",
        issuesRestored: "{count} issue restored",
        issuesRestored_plural: "{count} issues restored",
        nErrors: "{count} issue(s) not updated",
        openIssues: "View issues",
        done: "Generated",
        projectMembersAdded: "{count} user invited to the project",
        projectMembersAdded_pl: "{count} users invited to the project",
        projectMembersDeleted: "{count} user deleted from the project",
        projectMembersDeleted_pl: "{count} users deleted from the project",
        licenseMembersAdded: "{count} user invited to the license",
        licenseMembersAdded_pl: "{count} users invited to the license",
        licenseMembersDeleted: "{count} user deleted from the license",
        licenseMembersDeleted_pl: "{count} users deleted from the license",
        licenseMembersAddedPending: "{count} user added to \"Waiting for approval\" list",
        licenseMembersAddedPending_pl: "{count} users added to \"Waiting for approval\" list",
        viewApprovalList: "View list",
        revisionAdded: "New version added.",
        viewVersions: "View versions",
        downloadFile: "Download file",
        report: "Report:",
        reportError: "Cannot send the report due to an error",
        dashboard: "Dashboard:",
        dashboardError: "Cannot send the dashboard due to an error",
        dashboardCanceled: "Cannot send the dashboard because it has no data",
        reportCanceled: "Cannot send the report because it has no data",
        chart: "Chart:",
        chartError: "Cannot send the chart due to an error",
        chartCanceled: "Cannot send the chart because it has no data",
        licenseDashboard: "Cross-project dashboard:",
        licenseChart: "Cross-project chart:",
        author: "Author:",
        bellTooltip: "My notifications"
    },
    MemberSelect: {
        several: "You entered several email addresses. Press <kbd>Enter</kbd> to add the users.",
        noResults: "The user \"<strong>{search}</strong>\" is not found. Press <kbd>Enter</kbd> to add the user.",
        noResults2: "All license members are selected. To add more people, type their emails and press <kbd>Enter</kbd>.",
        invalid: "Invalid email"
    },
    GroupSendEmail: {
        recipients: {
            collapsedString: {
                before: "",
                before_plural: "",
                after: "",
                after_plural: "",
                additional: "To:"
            }
        }
    },
    CollapsedString: {
        andMore: "and {othersCount} more"
    },
    TopMenu: {
        iconsAndText: "Icons and text",
        iconsOnly: "Icons only",
        textOnly: "Text only"
    },
    ProjectSettings: {
        archived: "Archived",
        fieldLabel: {
            thumbnail: "Thumbnail:",
            title: "Title:",
            owner: "Owner:",
            license: "License:",
            abbreviation: "Abbreviation:",
            created: "Created:",
            updated: "Updated:",
            tags: "Tags:"
        }
    },
    WStestTranslatorLvl1: "Qwerty123456",
    WStestTranslator: {
        lvl2: "Qwerty1234567",
        newLvl: {
            lvl3: [
                "level 3",
                "тестовая строка",
                "тестовая строка"
            ],
            anotherString: "тестовая строка"
        }
    },
    LicenseDeferred: {
        licenseWillBeChanged: "The license terms will be updated in {count} day",
        licenseWillBeChanged_plural: "The license terms will be updated in {count} days",
        showDetails: "Show details",
        licenseChangesDetails: "License terms to be changed",
        type: "Subscription type:",
        userSlots: "User capacity:",
        allowCloud: "Cloud projects:",
        projectSlots: "Project capacity:",
        reviztoPlus: "Revizto+ subscription:",
        allowShared: "Shared location projects:",
        clashAutomation: "Clash automation:",
        allowBCFExport: "BCF export:",
        allowApiAccess: "API access:",
        enabled: "Enabled",
        disabled: "Disabled",
        yes: "Yes",
        no: "No",
        unlimited: "Unlimited",
        regular: "User-based",
        trial: "POC",
        nfr: "NFR",
        educational: "Educational",
        internal: "Revizto team",
        singleProject: "Project-based",
        close: "Close"
    },
    DiskUsage: {
        "B": "{count} B",
        KB: "{count} KB",
        MB: "{count} MB",
        GB: "{count} GB",
        TB: "{count} TB"
    },
    Unsubscribe: {
        title: "Unsubscribe",
        question: "You are about to unsubscribe from messages of this type",
        confirmationMessage: "To unsubscribe, click \"Confirm\".",
        confirm: "Confirm",
        success: "You have been unsubscribed",
        notFound: "You have already unsubscribed"
    },
    Version: {
        v5: "Revizto 5",
        v4: "Revizto 4",
        "-": "",
        short: {
            v5: "v5",
            v4: "v4"
        }
    },
    DateRange: {
        Last24Hours: "Last 24 hours",
        Last7Days: "Last 7 days",
        Last30Days: "Last 30 days",
        Last90Days: "Last 90 days",
        Last180Days: "Last 180 days",
        Last360Days: "Last 360 days"
    },
    Error: {
        invalidFields: "Invalid field(s): {fieldsString}.",
        failedFederationMetadata: "Cannot get federation metadata from the federation metadata URL."
    },
    Timezone: {
        autoTimeZoneUpdate: "Automatic time zone update",
        dialog: {
            title: "Time zone is not specified",
            content: "Did we correctly identify your region as <br/><b>{region}</b>?",
            correct: "Correct",
            changeIt: "No, I want to change it"
        },
        list: {
            africaAbidjan: "Africa - Ivory Coast, Abidjan <span>(UTC {offset})</span>",
            africaAccra: "Africa - Ghana, Accra <span>(UTC {offset})</span>",
            africaAddisAbaba: "Africa - Ethiopia, Addis Ababa <span>(UTC {offset})</span>",
            africaAlgiers: "Africa - Algeria, Algiers <span>(UTC {offset})</span>",
            africaAsmara: "Africa - Eritrea, Asmara <span>(UTC {offset})</span>",
            africaBamako: "Africa - Mali, Bamako <span>(UTC {offset})</span>",
            africaBangui: "Africa - Central African Republic, Bangui <span>(UTC {offset})</span>",
            africaBanjul: "Africa - Gambia, Banjul <span>(UTC {offset})</span>",
            africaBissau: "Africa - Guinea-Bissau, Bissau <span>(UTC {offset})</span>",
            africaBlantyre: "Africa - Malawi, Blantyre <span>(UTC {offset})</span>",
            africaBrazzaville: "Africa - Republic of the Congo, Brazzaville <span>(UTC {offset})</span>",
            africaBujumbura: "Africa - Burundi, Bujumbura <span>(UTC {offset})</span>",
            africaCairo: "Africa - Egypt, Cairo <span>(UTC {offset})</span>",
            africaCasablanca: "Africa - Morocco, Casablanca <span>(UTC {offset})</span>",
            africaCeuta: "Africa - Spain, Ceuta <span>(UTC {offset})</span>",
            africaConakry: "Africa - Guinea, Conakry <span>(UTC {offset})</span>",
            africaDakar: "Africa - Senegal, Dakar <span>(UTC {offset})</span>",
            africaDarEsSalaam: "Africa - Tanzania, Dar es Salaam <span>(UTC {offset})</span>",
            africaDjibouti: "Africa - Djibouti, Djibouti <span>(UTC {offset})</span>",
            africaDouala: "Africa - Cameroon, Douala <span>(UTC {offset})</span>",
            africaElAaiun: "Africa - Morocco, El Aaiun <span>(UTC {offset})</span>",
            africaFreetown: "Africa - Sierra Leone, Freetown <span>(UTC {offset})</span>",
            africaGaborone: "Africa - Botswana, Gaborone <span>(UTC {offset})</span>",
            africaHarare: "Africa - Zimbabwe, Harare <span>(UTC {offset})</span>",
            africaJohannesburg: "Africa - South Africa, Johannesburg <span>(UTC {offset})</span>",
            africaJuba: "Africa - South Sudan, Juba <span>(UTC {offset})</span>",
            africaKampala: "Africa - Uganda, Kampala <span>(UTC {offset})</span>",
            africaKhartoum: "Africa - Sudan, Khartoum <span>(UTC {offset})</span>",
            africaKigali: "Africa - Rwanda, Kigali <span>(UTC {offset})</span>",
            africaKinshasa: "Africa - Democratic Republic of the Congo, Kinshasa <span>(UTC {offset})</span>",
            africaLagos: "Africa - Nigeria, Lagos <span>(UTC {offset})</span>",
            africaLibreville: "Africa - Gabon, Libreville <span>(UTC {offset})</span>",
            africaLome: "Africa - Togo, Lome <span>(UTC {offset})</span>",
            africaLuanda: "Africa - Angola, Luanda <span>(UTC {offset})</span>",
            africaLubumbashi: "Africa - Democratic Republic of the Congo, Lubumbashi <span>(UTC {offset})</span>",
            africaLusaka: "Africa - Zambia, Lusaka <span>(UTC {offset})</span>",
            africaMalabo: "Africa - Equatorial Guinea, Malabo <span>(UTC {offset})</span>",
            africaMaputo: "Africa - Mozambique, Maputo <span>(UTC {offset})</span>",
            africaMaseru: "Africa - Lesotho, Maseru <span>(UTC {offset})</span>",
            africaMbabane: "Africa - Eswatini, Mbabane <span>(UTC {offset})</span>",
            africaMogadishu: "Africa - Somalia, Mogadishu <span>(UTC {offset})</span>",
            africaMonrovia: "Africa - Liberia, Monrovia <span>(UTC {offset})</span>",
            africaNairobi: "Africa - Kenya, Nairobi <span>(UTC {offset})</span>",
            africaNdjamena: "Africa - Chad, Ndjamena <span>(UTC {offset})</span>",
            africaNiamey: "Africa - Niger, Niamey <span>(UTC {offset})</span>",
            africaNouakchott: "Africa - Mauritania, Nouakchott <span>(UTC {offset})</span>",
            africaOuagadougou: "Africa - Burkina Faso, Ouagadougou <span>(UTC {offset})</span>",
            africaPortoNovo: "Africa - Benin, Porto-Novo <span>(UTC {offset})</span>",
            africaSaoTome: "Africa - Sao Tome and Principe, Sao Tome <span>(UTC {offset})</span>",
            africaTripoli: "Africa - Libya, Tripoli <span>(UTC {offset})</span>",
            africaTunis: "Africa - Tunisia, Tunis <span>(UTC {offset})</span>",
            africaWindhoek: "Africa - Namibia, Windhoek <span>(UTC {offset})</span>",
            americaAdak: "America - United States, Adak <span>(UTC {offset})</span>",
            americaAnchorage: "America - United States, Anchorage <span>(UTC {offset})</span>",
            americaAnguilla: "America - Anguilla, Anguilla <span>(UTC {offset})</span>",
            americaAntigua: "America - Antigua and Barbuda, Antigua <span>(UTC {offset})</span>",
            americaAraguaina: "America - Brazil, Araguaina <span>(UTC {offset})</span>",
            americaArgentinaBuenosAires: "America - Argentina, Argentina Buenos Aires <span>(UTC {offset})</span>",
            americaArgentinaCatamarca: "America - Argentina, Argentina Catamarca <span>(UTC {offset})</span>",
            americaArgentinaCordoba: "America - Argentina, Argentina Cordoba <span>(UTC {offset})</span>",
            americaArgentinaJujuy: "America - Argentina, Argentina Jujuy <span>(UTC {offset})</span>",
            americaArgentinaLaRioja: "America - Argentina, Argentina La Rioja <span>(UTC {offset})</span>",
            americaArgentinaMendoza: "America - Argentina, Argentina Mendoza <span>(UTC {offset})</span>",
            americaArgentinaRioGallegos: "America - Argentina, Argentina Rio Gallegos <span>(UTC {offset})</span>",
            americaArgentinaSalta: "America - Argentina, Argentina Salta <span>(UTC {offset})</span>",
            americaArgentinaSanJuan: "America - Argentina, Argentina San Juan <span>(UTC {offset})</span>",
            americaArgentinaSanLuis: "America - Argentina, Argentina San Luis <span>(UTC {offset})</span>",
            americaArgentinaTucuman: "America - Argentina, Argentina Tucuman <span>(UTC {offset})</span>",
            americaArgentinaUshuaia: "America - Argentina, Argentina Ushuaia <span>(UTC {offset})</span>",
            americaAruba: "America - Aruba, Aruba <span>(UTC {offset})</span>",
            americaAsuncion: "America - Paraguay, Asuncion <span>(UTC {offset})</span>",
            americaAtikokan: "America - Canada, Atikokan <span>(UTC {offset})</span>",
            americaBahia: "America - Brazil, Bahia <span>(UTC {offset})</span>",
            americaBahiaBanderas: "America - Mexico, Bahia Banderas <span>(UTC {offset})</span>",
            americaBarbados: "America - Barbados, Barbados <span>(UTC {offset})</span>",
            americaBelem: "America - Brazil, Belem <span>(UTC {offset})</span>",
            americaBelize: "America - Belize, Belize <span>(UTC {offset})</span>",
            americaBlancSablon: "America - Canada, Blanc-Sablon <span>(UTC {offset})</span>",
            americaBoaVista: "America - Brazil, Boa Vista <span>(UTC {offset})</span>",
            americaBogota: "America - Colombia, Bogota <span>(UTC {offset})</span>",
            americaBoise: "America - United States, Boise <span>(UTC {offset})</span>",
            americaCambridgeBay: "America - Canada, Cambridge Bay <span>(UTC {offset})</span>",
            americaCampoGrande: "America - Brazil, Campo Grande <span>(UTC {offset})</span>",
            americaCancun: "America - Mexico, Cancun <span>(UTC {offset})</span>",
            americaCaracas: "America - Venezuela, Caracas <span>(UTC {offset})</span>",
            americaCayenne: "America - French Guiana, Cayenne <span>(UTC {offset})</span>",
            americaCayman: "America - Cayman Islands, Cayman <span>(UTC {offset})</span>",
            americaChicago: "America - United States, Chicago <span>(UTC {offset})</span>",
            americaChihuahua: "America - Mexico, Chihuahua <span>(UTC {offset})</span>",
            americaCiudadJuarez: "America - Mexico, Ciudad Juarez <span>(UTC {offset})</span>",
            americaCostaRica: "America - Costa Rica, Costa Rica <span>(UTC {offset})</span>",
            americaCreston: "America - Canada, Creston <span>(UTC {offset})</span>",
            americaCuiaba: "America - Brazil, Cuiaba <span>(UTC {offset})</span>",
            americaCuracao: "America - Curacao, Curacao <span>(UTC {offset})</span>",
            americaDanmarkshavn: "America - Greenland, Danmarkshavn <span>(UTC {offset})</span>",
            americaDawson: "America - Canada, Dawson <span>(UTC {offset})</span>",
            americaDawsonCreek: "America - Canada, Dawson Creek <span>(UTC {offset})</span>",
            americaDenver: "America - United States, Denver <span>(UTC {offset})</span>",
            americaDetroit: "America - United States, Detroit <span>(UTC {offset})</span>",
            americaDominica: "America - Dominica, Dominica <span>(UTC {offset})</span>",
            americaEdmonton: "America - Canada, Edmonton <span>(UTC {offset})</span>",
            americaEirunepe: "America - Brazil, Eirunepe <span>(UTC {offset})</span>",
            americaElSalvador: "America - El Salvador, El Salvador <span>(UTC {offset})</span>",
            americaFortNelson: "America - Canada, Fort Nelson <span>(UTC {offset})</span>",
            americaFortaleza: "America - Brazil, Fortaleza <span>(UTC {offset})</span>",
            americaGlaceBay: "America - Canada, Glace Bay <span>(UTC {offset})</span>",
            americaGooseBay: "America - Canada, Goose Bay <span>(UTC {offset})</span>",
            americaGrandTurk: "America - Turks and Caicos Islands, Grand Turk <span>(UTC {offset})</span>",
            americaGrenada: "America - Grenada, Grenada <span>(UTC {offset})</span>",
            americaGuadeloupe: "America - Guadeloupe, Guadeloupe <span>(UTC {offset})</span>",
            americaGuatemala: "America - Guatemala, Guatemala <span>(UTC {offset})</span>",
            americaGuayaquil: "America - Ecuador, Guayaquil <span>(UTC {offset})</span>",
            americaGuyana: "America - Guyana, Guyana <span>(UTC {offset})</span>",
            americaHalifax: "America - Canada, Halifax <span>(UTC {offset})</span>",
            americaHavana: "America - Cuba, Havana <span>(UTC {offset})</span>",
            americaHermosillo: "America - Mexico, Hermosillo <span>(UTC {offset})</span>",
            americaIndianaIndianapolis: "America - United States, Indiana Indianapolis <span>(UTC {offset})</span>",
            americaIndianaKnox: "America - United States, Indiana Knox <span>(UTC {offset})</span>",
            americaIndianaMarengo: "America - United States, Indiana Marengo <span>(UTC {offset})</span>",
            americaIndianaPetersburg: "America - United States, Indiana Petersburg <span>(UTC {offset})</span>",
            americaIndianaTellCity: "America - United States, Indiana Tell City <span>(UTC {offset})</span>",
            americaIndianaVevay: "America - United States, Indiana Vevay <span>(UTC {offset})</span>",
            americaIndianaVincennes: "America - United States, Indiana Vincennes <span>(UTC {offset})</span>",
            americaIndianaWinamac: "America - United States, Indiana Winamac <span>(UTC {offset})</span>",
            americaInuvik: "America - Canada, Inuvik <span>(UTC {offset})</span>",
            americaIqaluit: "America - Canada, Iqaluit <span>(UTC {offset})</span>",
            americaJamaica: "America - Jamaica, Jamaica <span>(UTC {offset})</span>",
            americaJuneau: "America - United States, Juneau <span>(UTC {offset})</span>",
            americaKentuckyLouisville: "America - United States, Kentucky Louisville <span>(UTC {offset})</span>",
            americaKentuckyMonticello: "America - United States, Kentucky Monticello <span>(UTC {offset})</span>",
            americaKralendijk: "America - Bonaire, Saint Eustatius and Saba, Kralendijk <span>(UTC {offset})</span>",
            americaLaPaz: "America - Bolivia, La Paz <span>(UTC {offset})</span>",
            americaLima: "America - Peru, Lima <span>(UTC {offset})</span>",
            americaLosAngeles: "America - United States, Los Angeles <span>(UTC {offset})</span>",
            americaLowerPrinces: "America - Sint Maarten, Lower Princes <span>(UTC {offset})</span>",
            americaMaceio: "America - Brazil, Maceio <span>(UTC {offset})</span>",
            americaManagua: "America - Nicaragua, Managua <span>(UTC {offset})</span>",
            americaManaus: "America - Brazil, Manaus <span>(UTC {offset})</span>",
            americaMarigot: "America - Saint Martin, Marigot <span>(UTC {offset})</span>",
            americaMartinique: "America - Martinique, Martinique <span>(UTC {offset})</span>",
            americaMatamoros: "America - Mexico, Matamoros <span>(UTC {offset})</span>",
            americaMazatlan: "America - Mexico, Mazatlan <span>(UTC {offset})</span>",
            americaMenominee: "America - United States, Menominee <span>(UTC {offset})</span>",
            americaMerida: "America - Mexico, Merida <span>(UTC {offset})</span>",
            americaMetlakatla: "America - United States, Metlakatla <span>(UTC {offset})</span>",
            americaMexicoCity: "America - Mexico, Mexico City <span>(UTC {offset})</span>",
            americaMiquelon: "America - Saint Pierre and Miquelon, Miquelon <span>(UTC {offset})</span>",
            americaMoncton: "America - Canada, Moncton <span>(UTC {offset})</span>",
            americaMonterrey: "America - Mexico, Monterrey <span>(UTC {offset})</span>",
            americaMontevideo: "America - Uruguay, Montevideo <span>(UTC {offset})</span>",
            americaMontserrat: "America - Montserrat, Montserrat <span>(UTC {offset})</span>",
            americaNassau: "America - Bahamas, Nassau <span>(UTC {offset})</span>",
            americaNewYork: "America - United States, New York <span>(UTC {offset})</span>",
            americaNome: "America - United States, Nome <span>(UTC {offset})</span>",
            americaNoronha: "America - Brazil, Noronha <span>(UTC {offset})</span>",
            americaNorthDakotaBeulah: "America - United States, North Dakota Beulah <span>(UTC {offset})</span>",
            americaNorthDakotaCenter: "America - United States, North Dakota Center <span>(UTC {offset})</span>",
            americaNorthDakotaNewSalem: "America - United States, North Dakota New Salem <span>(UTC {offset})</span>",
            americaNuuk: "America - Greenland, Nuuk <span>(UTC {offset})</span>",
            americaOjinaga: "America - Mexico, Ojinaga <span>(UTC {offset})</span>",
            americaPanama: "America - Panama, Panama <span>(UTC {offset})</span>",
            americaParamaribo: "America - Suriname, Paramaribo <span>(UTC {offset})</span>",
            americaPhoenix: "America - United States, Phoenix <span>(UTC {offset})</span>",
            americaPortAuPrince: "America - Haiti, Port-au-Prince <span>(UTC {offset})</span>",
            americaPortOfSpain: "America - Trinidad and Tobago, Port of Spain <span>(UTC {offset})</span>",
            americaPortoVelho: "America - Brazil, Porto Velho <span>(UTC {offset})</span>",
            americaPuertoRico: "America - Puerto Rico, Puerto Rico <span>(UTC {offset})</span>",
            americaPuntaArenas: "America - Chile, Punta Arenas <span>(UTC {offset})</span>",
            americaRankinInlet: "America - Canada, Rankin Inlet <span>(UTC {offset})</span>",
            americaRecife: "America - Brazil, Recife <span>(UTC {offset})</span>",
            americaRegina: "America - Canada, Regina <span>(UTC {offset})</span>",
            americaResolute: "America - Canada, Resolute <span>(UTC {offset})</span>",
            americaRioBranco: "America - Brazil, Rio Branco <span>(UTC {offset})</span>",
            americaSantarem: "America - Brazil, Santarem <span>(UTC {offset})</span>",
            americaSantiago: "America - Chile, Santiago <span>(UTC {offset})</span>",
            americaSantoDomingo: "America - Dominican Republic, Santo Domingo <span>(UTC {offset})</span>",
            americaSaoPaulo: "America - Brazil, Sao Paulo <span>(UTC {offset})</span>",
            americaScoresbysund: "America - Greenland, Scoresbysund <span>(UTC {offset})</span>",
            americaSitka: "America - United States, Sitka <span>(UTC {offset})</span>",
            americaStBarthelemy: "America - Saint Barthelemy, St Barthelemy <span>(UTC {offset})</span>",
            americaStJohns: "America - Canada, St Johns <span>(UTC {offset})</span>",
            americaStKitts: "America - Saint Kitts and Nevis, St Kitts <span>(UTC {offset})</span>",
            americaStLucia: "America - Saint Lucia, St Lucia <span>(UTC {offset})</span>",
            americaStThomas: "America - U.S. Virgin Islands, St Thomas <span>(UTC {offset})</span>",
            americaStVincent: "America - Saint Vincent and the Grenadines, St Vincent <span>(UTC {offset})</span>",
            americaSwiftCurrent: "America - Canada, Swift Current <span>(UTC {offset})</span>",
            americaTegucigalpa: "America - Honduras, Tegucigalpa <span>(UTC {offset})</span>",
            americaThule: "America - Greenland, Thule <span>(UTC {offset})</span>",
            americaTijuana: "America - Mexico, Tijuana <span>(UTC {offset})</span>",
            americaToronto: "America - Canada, Toronto <span>(UTC {offset})</span>",
            americaTortola: "America - British Virgin Islands, Tortola <span>(UTC {offset})</span>",
            americaVancouver: "America - Canada, Vancouver <span>(UTC {offset})</span>",
            americaWhitehorse: "America - Canada, Whitehorse <span>(UTC {offset})</span>",
            americaWinnipeg: "America - Canada, Winnipeg <span>(UTC {offset})</span>",
            americaYakutat: "America - United States, Yakutat <span>(UTC {offset})</span>",
            antarcticaCasey: "Antarctica - Antarctica, Casey <span>(UTC {offset})</span>",
            antarcticaDavis: "Antarctica - Antarctica, Davis <span>(UTC {offset})</span>",
            antarcticaDumontDUrville: "Antarctica - Antarctica, DumontDUrville <span>(UTC {offset})</span>",
            antarcticaMacquarie: "Antarctica - Australia, Macquarie <span>(UTC {offset})</span>",
            antarcticaMawson: "Antarctica - Antarctica, Mawson <span>(UTC {offset})</span>",
            antarcticaMcMurdo: "Antarctica - Antarctica, McMurdo <span>(UTC {offset})</span>",
            antarcticaPalmer: "Antarctica - Antarctica, Palmer <span>(UTC {offset})</span>",
            antarcticaRothera: "Antarctica - Antarctica, Rothera <span>(UTC {offset})</span>",
            antarcticaSyowa: "Antarctica - Antarctica, Syowa <span>(UTC {offset})</span>",
            antarcticaTroll: "Antarctica - Antarctica, Troll <span>(UTC {offset})</span>",
            antarcticaVostok: "Antarctica - Antarctica, Vostok <span>(UTC {offset})</span>",
            arcticLongyearbyen: "Arctic - Svalbard and Jan Mayen, Longyearbyen <span>(UTC {offset})</span>",
            asiaAden: "Asia - Yemen, Aden <span>(UTC {offset})</span>",
            asiaAlmaty: "Asia - Kazakhstan, Almaty <span>(UTC {offset})</span>",
            asiaAmman: "Asia - Jordan, Amman <span>(UTC {offset})</span>",
            asiaAnadyr: "Asia - Russia, Anadyr <span>(UTC {offset})</span>",
            asiaAqtau: "Asia - Kazakhstan, Aqtau <span>(UTC {offset})</span>",
            asiaAqtobe: "Asia - Kazakhstan, Aqtobe <span>(UTC {offset})</span>",
            asiaAshgabat: "Asia - Turkmenistan, Ashgabat <span>(UTC {offset})</span>",
            asiaAtyrau: "Asia - Kazakhstan, Atyrau <span>(UTC {offset})</span>",
            asiaBaghdad: "Asia - Iraq, Baghdad <span>(UTC {offset})</span>",
            asiaBahrain: "Asia - Bahrain, Bahrain <span>(UTC {offset})</span>",
            asiaBaku: "Asia - Azerbaijan, Baku <span>(UTC {offset})</span>",
            asiaBangkok: "Asia - Thailand, Bangkok <span>(UTC {offset})</span>",
            asiaBarnaul: "Asia - Russia, Barnaul <span>(UTC {offset})</span>",
            asiaBeirut: "Asia - Lebanon, Beirut <span>(UTC {offset})</span>",
            asiaBishkek: "Asia - Kyrgyzstan, Bishkek <span>(UTC {offset})</span>",
            asiaBrunei: "Asia - Brunei, Brunei <span>(UTC {offset})</span>",
            asiaChita: "Asia - Russia, Chita <span>(UTC {offset})</span>",
            asiaChoibalsan: "Asia - Mongolia, Choibalsan <span>(UTC {offset})</span>",
            asiaColombo: "Asia - Sri Lanka, Colombo <span>(UTC {offset})</span>",
            asiaDamascus: "Asia - Syria, Damascus <span>(UTC {offset})</span>",
            asiaDhaka: "Asia - Bangladesh, Dhaka <span>(UTC {offset})</span>",
            asiaDili: "Asia - Timor Leste, Dili <span>(UTC {offset})</span>",
            asiaDubai: "Asia - United Arab Emirates, Dubai <span>(UTC {offset})</span>",
            asiaDushanbe: "Asia - Tajikistan, Dushanbe <span>(UTC {offset})</span>",
            asiaFamagusta: "Asia - Cyprus, Famagusta <span>(UTC {offset})</span>",
            asiaGaza: "Asia - Palestinian Territory, Gaza <span>(UTC {offset})</span>",
            asiaHebron: "Asia - Palestinian Territory, Hebron <span>(UTC {offset})</span>",
            asiaHoChiMinh: "Asia - Vietnam, Ho Chi Minh <span>(UTC {offset})</span>",
            asiaHongKong: "Asia - Hong Kong, Hong Kong <span>(UTC {offset})</span>",
            asiaHovd: "Asia - Mongolia, Hovd <span>(UTC {offset})</span>",
            asiaIrkutsk: "Asia - Russia, Irkutsk <span>(UTC {offset})</span>",
            asiaJakarta: "Asia - Indonesia, Jakarta <span>(UTC {offset})</span>",
            asiaJayapura: "Asia - Indonesia, Jayapura <span>(UTC {offset})</span>",
            asiaJerusalem: "Asia - Israel, Jerusalem <span>(UTC {offset})</span>",
            asiaKabul: "Asia - Afghanistan, Kabul <span>(UTC {offset})</span>",
            asiaKamchatka: "Asia - Russia, Kamchatka <span>(UTC {offset})</span>",
            asiaKarachi: "Asia - Pakistan, Karachi <span>(UTC {offset})</span>",
            asiaKathmandu: "Asia - Nepal, Kathmandu <span>(UTC {offset})</span>",
            asiaKhandyga: "Asia - Russia, Khandyga <span>(UTC {offset})</span>",
            asiaKolkata: "Asia - India, Kolkata <span>(UTC {offset})</span>",
            asiaKrasnoyarsk: "Asia - Russia, Krasnoyarsk <span>(UTC {offset})</span>",
            asiaKualaLumpur: "Asia - Malaysia, Kuala Lumpur <span>(UTC {offset})</span>",
            asiaKuching: "Asia - Malaysia, Kuching <span>(UTC {offset})</span>",
            asiaKuwait: "Asia - Kuwait, Kuwait <span>(UTC {offset})</span>",
            asiaMacau: "Asia - Macao, Macau <span>(UTC {offset})</span>",
            asiaMagadan: "Asia - Russia, Magadan <span>(UTC {offset})</span>",
            asiaMakassar: "Asia - Indonesia, Makassar <span>(UTC {offset})</span>",
            asiaManila: "Asia - Philippines, Manila <span>(UTC {offset})</span>",
            asiaMuscat: "Asia - Oman, Muscat <span>(UTC {offset})</span>",
            asiaNicosia: "Asia - Cyprus, Nicosia <span>(UTC {offset})</span>",
            asiaNovokuznetsk: "Asia - Russia, Novokuznetsk <span>(UTC {offset})</span>",
            asiaNovosibirsk: "Asia - Russia, Novosibirsk <span>(UTC {offset})</span>",
            asiaOmsk: "Asia - Russia, Omsk <span>(UTC {offset})</span>",
            asiaOral: "Asia - Kazakhstan, Oral <span>(UTC {offset})</span>",
            asiaPhnomPenh: "Asia - Cambodia, Phnom Penh <span>(UTC {offset})</span>",
            asiaPontianak: "Asia - Indonesia, Pontianak <span>(UTC {offset})</span>",
            asiaPyongyang: "Asia - North Korea, Pyongyang <span>(UTC {offset})</span>",
            asiaQatar: "Asia - Qatar, Qatar <span>(UTC {offset})</span>",
            asiaQostanay: "Asia - Kazakhstan, Qostanay <span>(UTC {offset})</span>",
            asiaQyzylorda: "Asia - Kazakhstan, Qyzylorda <span>(UTC {offset})</span>",
            asiaRiyadh: "Asia - Saudi Arabia, Riyadh <span>(UTC {offset})</span>",
            asiaSakhalin: "Asia - Russia, Sakhalin <span>(UTC {offset})</span>",
            asiaSamarkand: "Asia - Uzbekistan, Samarkand <span>(UTC {offset})</span>",
            asiaSeoul: "Asia - South Korea, Seoul <span>(UTC {offset})</span>",
            asiaShanghai: "Asia - China, Shanghai <span>(UTC {offset})</span>",
            asiaSingapore: "Asia - Singapore, Singapore <span>(UTC {offset})</span>",
            asiaSrednekolymsk: "Asia - Russia, Srednekolymsk <span>(UTC {offset})</span>",
            asiaTaipei: "Asia - Taiwan, Taipei <span>(UTC {offset})</span>",
            asiaTashkent: "Asia - Uzbekistan, Tashkent <span>(UTC {offset})</span>",
            asiaTbilisi: "Asia - Georgia, Tbilisi <span>(UTC {offset})</span>",
            asiaTehran: "Asia - Iran, Tehran <span>(UTC {offset})</span>",
            asiaThimphu: "Asia - Bhutan, Thimphu <span>(UTC {offset})</span>",
            asiaTokyo: "Asia - Japan, Tokyo <span>(UTC {offset})</span>",
            asiaTomsk: "Asia - Russia, Tomsk <span>(UTC {offset})</span>",
            asiaUlaanbaatar: "Asia - Mongolia, Ulaanbaatar <span>(UTC {offset})</span>",
            asiaUrumqi: "Asia - China, Urumqi <span>(UTC {offset})</span>",
            asiaUstNera: "Asia - Russia, Ust-Nera <span>(UTC {offset})</span>",
            asiaVientiane: "Asia - Laos, Vientiane <span>(UTC {offset})</span>",
            asiaVladivostok: "Asia - Russia, Vladivostok <span>(UTC {offset})</span>",
            asiaYakutsk: "Asia - Russia, Yakutsk <span>(UTC {offset})</span>",
            asiaYangon: "Asia - Myanmar, Yangon <span>(UTC {offset})</span>",
            asiaYekaterinburg: "Asia - Russia, Yekaterinburg <span>(UTC {offset})</span>",
            asiaYerevan: "Asia - Armenia, Yerevan <span>(UTC {offset})</span>",
            atlanticAzores: "Atlantic - Portugal, Azores <span>(UTC {offset})</span>",
            atlanticBermuda: "Atlantic - Bermuda, Bermuda <span>(UTC {offset})</span>",
            atlanticCanary: "Atlantic - Spain, Canary <span>(UTC {offset})</span>",
            atlanticCapeVerde: "Atlantic - Cabo Verde, Cape Verde <span>(UTC {offset})</span>",
            atlanticFaroe: "Atlantic - Faroe Islands, Faroe <span>(UTC {offset})</span>",
            atlanticMadeira: "Atlantic - Portugal, Madeira <span>(UTC {offset})</span>",
            atlanticReykjavik: "Atlantic - Iceland, Reykjavik <span>(UTC {offset})</span>",
            atlanticSouthGeorgia: "Atlantic - South Georgia and the South Sandwich Islands, South Georgia <span>(UTC {offset})</span>",
            atlanticStHelena: "Atlantic - Saint Helena, St Helena <span>(UTC {offset})</span>",
            atlanticStanley: "Atlantic - Falkland Islands, Stanley <span>(UTC {offset})</span>",
            australiaAdelaide: "Australia - Australia, Adelaide <span>(UTC {offset})</span>",
            australiaBrisbane: "Australia - Australia, Brisbane <span>(UTC {offset})</span>",
            australiaBrokenHill: "Australia - Australia, Broken Hill <span>(UTC {offset})</span>",
            australiaDarwin: "Australia - Australia, Darwin <span>(UTC {offset})</span>",
            australiaEucla: "Australia - Australia, Eucla <span>(UTC {offset})</span>",
            australiaHobart: "Australia - Australia, Hobart <span>(UTC {offset})</span>",
            australiaLindeman: "Australia - Australia, Lindeman <span>(UTC {offset})</span>",
            australiaLordHowe: "Australia - Australia, Lord Howe <span>(UTC {offset})</span>",
            australiaMelbourne: "Australia - Australia, Melbourne <span>(UTC {offset})</span>",
            australiaPerth: "Australia - Australia, Perth <span>(UTC {offset})</span>",
            australiaSydney: "Australia - Australia, Sydney <span>(UTC {offset})</span>",
            europeAmsterdam: "Europe - Netherlands, Amsterdam <span>(UTC {offset})</span>",
            europeAndorra: "Europe - Andorra, Andorra <span>(UTC {offset})</span>",
            europeAstrakhan: "Europe - Russia, Astrakhan <span>(UTC {offset})</span>",
            europeAthens: "Europe - Greece, Athens <span>(UTC {offset})</span>",
            europeBelgrade: "Europe - Serbia, Belgrade <span>(UTC {offset})</span>",
            europeBerlin: "Europe - Germany, Berlin <span>(UTC {offset})</span>",
            europeBratislava: "Europe - Slovakia, Bratislava <span>(UTC {offset})</span>",
            europeBrussels: "Europe - Belgium, Brussels <span>(UTC {offset})</span>",
            europeBucharest: "Europe - Romania, Bucharest <span>(UTC {offset})</span>",
            europeBudapest: "Europe - Hungary, Budapest <span>(UTC {offset})</span>",
            europeBusingen: "Europe - Germany, Busingen <span>(UTC {offset})</span>",
            europeChisinau: "Europe - Moldova, Chisinau <span>(UTC {offset})</span>",
            europeCopenhagen: "Europe - Denmark, Copenhagen <span>(UTC {offset})</span>",
            europeDublin: "Europe - Ireland, Dublin <span>(UTC {offset})</span>",
            europeGibraltar: "Europe - Gibraltar, Gibraltar <span>(UTC {offset})</span>",
            europeGuernsey: "Europe - Guernsey, Guernsey <span>(UTC {offset})</span>",
            europeHelsinki: "Europe - Finland, Helsinki <span>(UTC {offset})</span>",
            europeIsleOfMan: "Europe - Isle of Man, Isle of Man <span>(UTC {offset})</span>",
            europeIstanbul: "Europe - Turkey, Istanbul <span>(UTC {offset})</span>",
            europeJersey: "Europe - Jersey, Jersey <span>(UTC {offset})</span>",
            europeKaliningrad: "Europe - Russia, Kaliningrad <span>(UTC {offset})</span>",
            europeKirov: "Europe - Russia, Kirov <span>(UTC {offset})</span>",
            europeKyiv: "Europe - Ukraine, Kyiv <span>(UTC {offset})</span>",
            europeLisbon: "Europe - Portugal, Lisbon <span>(UTC {offset})</span>",
            europeLjubljana: "Europe - Slovenia, Ljubljana <span>(UTC {offset})</span>",
            europeLondon: "Europe - United Kingdom, London <span>(UTC {offset})</span>",
            europeLuxembourg: "Europe - Luxembourg, Luxembourg <span>(UTC {offset})</span>",
            europeMadrid: "Europe - Spain, Madrid <span>(UTC {offset})</span>",
            europeMalta: "Europe - Malta, Malta <span>(UTC {offset})</span>",
            europeMariehamn: "Europe - Aland Islands, Mariehamn <span>(UTC {offset})</span>",
            europeMinsk: "Europe - Belarus, Minsk <span>(UTC {offset})</span>",
            europeMonaco: "Europe - Monaco, Monaco <span>(UTC {offset})</span>",
            europeMoscow: "Europe - Russia, Moscow <span>(UTC {offset})</span>",
            europeOslo: "Europe - Norway, Oslo <span>(UTC {offset})</span>",
            europeParis: "Europe - France, Paris <span>(UTC {offset})</span>",
            europePodgorica: "Europe - Montenegro, Podgorica <span>(UTC {offset})</span>",
            europePrague: "Europe - Czechia, Prague <span>(UTC {offset})</span>",
            europeRiga: "Europe - Latvia, Riga <span>(UTC {offset})</span>",
            europeRome: "Europe - Italy, Rome <span>(UTC {offset})</span>",
            europeSamara: "Europe - Russia, Samara <span>(UTC {offset})</span>",
            europeSanMarino: "Europe - San Marino, San Marino <span>(UTC {offset})</span>",
            europeSarajevo: "Europe - Bosnia and Herzegovina, Sarajevo <span>(UTC {offset})</span>",
            europeSaratov: "Europe - Russia, Saratov <span>(UTC {offset})</span>",
            europeSimferopol: "Europe - Ukraine, Simferopol <span>(UTC {offset})</span>",
            europeSkopje: "Europe - North Macedonia, Skopje <span>(UTC {offset})</span>",
            europeSofia: "Europe - Bulgaria, Sofia <span>(UTC {offset})</span>",
            europeStockholm: "Europe - Sweden, Stockholm <span>(UTC {offset})</span>",
            europeTallinn: "Europe - Estonia, Tallinn <span>(UTC {offset})</span>",
            europeTirane: "Europe - Albania, Tirane <span>(UTC {offset})</span>",
            europeUlyanovsk: "Europe - Russia, Ulyanovsk <span>(UTC {offset})</span>",
            europeVaduz: "Europe - Liechtenstein, Vaduz <span>(UTC {offset})</span>",
            europeVatican: "Europe - Vatican, Vatican <span>(UTC {offset})</span>",
            europeVienna: "Europe - Austria, Vienna <span>(UTC {offset})</span>",
            europeVilnius: "Europe - Lithuania, Vilnius <span>(UTC {offset})</span>",
            europeVolgograd: "Europe - Russia, Volgograd <span>(UTC {offset})</span>",
            europeWarsaw: "Europe - Poland, Warsaw <span>(UTC {offset})</span>",
            europeZagreb: "Europe - Croatia, Zagreb <span>(UTC {offset})</span>",
            europeZurich: "Europe - Switzerland, Zurich <span>(UTC {offset})</span>",
            indianAntananarivo: "Indian - Madagascar, Antananarivo <span>(UTC {offset})</span>",
            indianChagos: "Indian - British Indian Ocean Territory, Chagos <span>(UTC {offset})</span>",
            indianChristmas: "Indian - Christmas Island, Christmas <span>(UTC {offset})</span>",
            indianCocos: "Indian - Cocos Islands, Cocos <span>(UTC {offset})</span>",
            indianComoro: "Indian - Comoros, Comoro <span>(UTC {offset})</span>",
            indianKerguelen: "Indian - French Southern Territories, Kerguelen <span>(UTC {offset})</span>",
            indianMahe: "Indian - Seychelles, Mahe <span>(UTC {offset})</span>",
            indianMaldives: "Indian - Maldives, Maldives <span>(UTC {offset})</span>",
            indianMauritius: "Indian - Mauritius, Mauritius <span>(UTC {offset})</span>",
            indianMayotte: "Indian - Mayotte, Mayotte <span>(UTC {offset})</span>",
            indianReunion: "Indian - Reunion, Reunion <span>(UTC {offset})</span>",
            pacificApia: "Pacific - Samoa, Apia <span>(UTC {offset})</span>",
            pacificAuckland: "Pacific - New Zealand, Auckland <span>(UTC {offset})</span>",
            pacificBougainville: "Pacific - Papua New Guinea, Bougainville <span>(UTC {offset})</span>",
            pacificChatham: "Pacific - New Zealand, Chatham <span>(UTC {offset})</span>",
            pacificChuuk: "Pacific - Micronesia, Chuuk <span>(UTC {offset})</span>",
            pacificEaster: "Pacific - Chile, Easter <span>(UTC {offset})</span>",
            pacificEfate: "Pacific - Vanuatu, Efate <span>(UTC {offset})</span>",
            pacificFakaofo: "Pacific - Tokelau, Fakaofo <span>(UTC {offset})</span>",
            pacificFiji: "Pacific - Fiji, Fiji <span>(UTC {offset})</span>",
            pacificFunafuti: "Pacific - Tuvalu, Funafuti <span>(UTC {offset})</span>",
            pacificGalapagos: "Pacific - Ecuador, Galapagos <span>(UTC {offset})</span>",
            pacificGambier: "Pacific - French Polynesia, Gambier <span>(UTC {offset})</span>",
            pacificGuadalcanal: "Pacific - Solomon Islands, Guadalcanal <span>(UTC {offset})</span>",
            pacificGuam: "Pacific - Guam, Guam <span>(UTC {offset})</span>",
            pacificHonolulu: "Pacific - United States, Honolulu <span>(UTC {offset})</span>",
            pacificKanton: "Pacific - Kiribati, Kanton <span>(UTC {offset})</span>",
            pacificKiritimati: "Pacific - Kiribati, Kiritimati <span>(UTC {offset})</span>",
            pacificKosrae: "Pacific - Micronesia, Kosrae <span>(UTC {offset})</span>",
            pacificKwajalein: "Pacific - Marshall Islands, Kwajalein <span>(UTC {offset})</span>",
            pacificMajuro: "Pacific - Marshall Islands, Majuro <span>(UTC {offset})</span>",
            pacificMarquesas: "Pacific - French Polynesia, Marquesas <span>(UTC {offset})</span>",
            pacificMidway: "Pacific - United States Minor Outlying Islands, Midway <span>(UTC {offset})</span>",
            pacificNauru: "Pacific - Nauru, Nauru <span>(UTC {offset})</span>",
            pacificNiue: "Pacific - Niue, Niue <span>(UTC {offset})</span>",
            pacificNorfolk: "Pacific - Norfolk Island, Norfolk <span>(UTC {offset})</span>",
            pacificNoumea: "Pacific - New Caledonia, Noumea <span>(UTC {offset})</span>",
            pacificPagoPago: "Pacific - American Samoa, Pago Pago <span>(UTC {offset})</span>",
            pacificPalau: "Pacific - Palau, Palau <span>(UTC {offset})</span>",
            pacificPitcairn: "Pacific - Pitcairn, Pitcairn <span>(UTC {offset})</span>",
            pacificPohnpei: "Pacific - Micronesia, Pohnpei <span>(UTC {offset})</span>",
            pacificPortMoresby: "Pacific - Papua New Guinea, Port Moresby <span>(UTC {offset})</span>",
            pacificRarotonga: "Pacific - Cook Islands, Rarotonga <span>(UTC {offset})</span>",
            pacificSaipan: "Pacific - Northern Mariana Islands, Saipan <span>(UTC {offset})</span>",
            pacificTahiti: "Pacific - French Polynesia, Tahiti <span>(UTC {offset})</span>",
            pacificTarawa: "Pacific - Kiribati, Tarawa <span>(UTC {offset})</span>",
            pacificTongatapu: "Pacific - Tonga, Tongatapu <span>(UTC {offset})</span>",
            pacificWake: "Pacific - United States Minor Outlying Islands, Wake <span>(UTC {offset})</span>",
            pacificWallis: "Pacific - Wallis and Futuna, Wallis <span>(UTC {offset})</span>",
            utc: "UTC"
        }
    },
    Page404: {
        default: "The requested resource doesn’t exist..."
    }
};