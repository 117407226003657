import { IssueFilterExpr, TimeUnit } from '@/constants';
import { IssuesFilterType } from '@/constants';
import { IssueTrackerFilterByDateBase } from '@/services/issueTracker/IssueTrackerFilterByDateBase';
import { TimeUnits } from '@/services/DateUtils'; // DateFilterRuleType

export enum DurationFilterValue {
    MORE_THAN = 17,
    LESS_THAN = 15,
    EQUALS = 14,
}

export class IssueTrackerFilterByDurationValue extends IssueTrackerFilterByDateBase {
    constructor(dateFilterType: number, duration: number, units: number) {
        super(IssuesFilterType.duration, dateFilterType, {
            value: duration,
            units,
        });
    }

    get daysValue() {
        switch (this.date.units) {
            case TimeUnits.days:
                return this.date.value;
            case TimeUnits.weeks:
                return this.date.value * 7;
            case TimeUnits.months:
                return this.date.value * 31;
            case TimeUnits.years:
                return this.date.value * 365;
        }
        return 0;
    }

    get dateFilter() {
        let expr: number = IssueFilterExpr.MORE_THAN;
        if (this.dateFilterType === DurationFilterValue.MORE_THAN) {
            expr = IssueFilterExpr.MORE_THAN;
        }
        if (this.dateFilterType === DurationFilterValue.LESS_THAN) {
            expr = IssueFilterExpr.LESS_THAN;
        }
        if (this.dateFilterType === DurationFilterValue.EQUALS) {
            expr = IssueFilterExpr.EQUALS;
        }

        return {
            expr,
            value: this.date.value,
            timeUnit: TimeUnit[String(this.date.units).toUpperCase()],
        };
    }
}
