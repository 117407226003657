















































































































import { Component, Prop } from 'vue-property-decorator';
import { TranslateResult } from 'vue-i18n';
import { TRulesObj } from '@/types/TRulesObj';
// @ts-ignore
import { Dict } from '@/types/Dict';
import { AuthMethodType, RESPONSE, RouterNames, SAMLCookie, TwoFAMethod } from '@/constants';
import { License, ProtoLicense } from '@/models';
import { CookieHelper, sanitizeHtml, startTimer, truncateString } from '@/services';
import { autofocusMixin } from '@/mixins';
import OTPInput from '@/components/common/OTPInput.vue';
import WsInput from '@/components/common/WsInput.vue';
import WsButton from '@/components/common/WsButton.vue';
import WsTooltip from '@/components/common/WsTooltip.vue';

const Empty = {
    get twoFAData() {
        return {
            key: '',
            secret: '',
            twoFactorMethod: '',
            code: '',
        };
    },
    get credentials() {
        return {
            email: '',
            login: '',
            password: '',
        };
    },
};

@Component({
    components: {
        OTPInput,
        WsButton,
        WsInput,
        WsTooltip,
    },
})
export default class LoginMergeForm extends autofocusMixin {
    @Prop({ type: Boolean }) public hasTitle!: boolean;
    @Prop({ required: true }) public license!: License | ProtoLicense;
    @Prop({ type: Boolean, default: true }) public hasReturnArrow!: boolean;
    @Prop({ type: Boolean, default: true }) public hasForgotPassword!: boolean;
    @Prop() public pleasePhrase!: TranslateResult;
    @Prop({ type: String, default: '' }) public pathnameToRedirect!: string;
    // These two callbacks are intentionally passed into props, not executed on event, because
    // events aren't fired on components inside slots (LoginMergeForm is a slot inside WsDialog in parent components)
    @Prop({ default: () => () => null }) public close!: () => void;
    @Prop({ default: () => () => null }) public returnToPrevious!: () => void;

    public readonly sanitizeHtml = sanitizeHtml;

    public credentials = Empty.credentials;
    public isTwoFAStep = false;
    public isInvalidCode = false;
    public isDisabledResend = false;
    public secondsLeft: number = 0;
    public twoFAData = Empty.twoFAData;

    public authError: TranslateResult = '';
    public isAuthorizing = false;
    public appSSO = this.$store.getters.appSSO;
    public otpTriggerKey = 1;

    get isEmailTwoFactorMethod() {
        return this.twoFAData.twoFactorMethod === TwoFAMethod.email;
    }

    get isGoogleTwoFactorMethod() {
        return this.twoFAData.twoFactorMethod === TwoFAMethod.google;
    }

    get loginFieldName() {
        return this.isReviztoInternal ? 'email' : 'login';
    }

    get rules(): TRulesObj {
        return {
            email: [
                (value: string) => Boolean(value) || this.$t('errors.empty_email'),
            ],
            login: [
                (value: string) => Boolean(value) || this.$t('errors.empty_login'),
            ],
            password: [
                (value: string) => Boolean(value) || this.$t('errors.empty_pass'),
            ],
        };
    }

    get licenseName() {
        return truncateString(this.license.name);
    }

    get authType() {
        return this.license.authMethodType;
    }

    get isShowForgotPassword() {
        return this.hasForgotPassword && ![AuthMethodType.google, AuthMethodType.SAML].includes(this.authType);
    }

    get isCredentialsInputs() {
        return this.authType === AuthMethodType.revizto_internal;
    }

    get isSAML() {
        return this.authType === AuthMethodType.SAML;
    }

    get isAppAuth() {
        return Boolean(this.appSSO.key);
    }

    get isReviztoInternal() {
        return this.authType === AuthMethodType.revizto_internal;
    }

    get isGoogle() {
        return this.authType === AuthMethodType.google;
    }

    get explanation() {
        const licenseName = this.licenseName;
        if (this.isReviztoInternal) {
            return this.$t('Login.requiresAdditionalAuthReviztoAccount', { licenseName });
        }
        if (this.isGoogle) {
            return this.$t('Login.requiresAdditionalAuthCorporateGoogle', { licenseName });
        }
        return this.$t('Login.requiresAdditionalAuth', { licenseName });
    }

    public async continueLogin() {
        const form = this.$refs.credentials as HTMLFormElement;
        const isValid = form.validate();
        if (!isValid) {
            return;
        }

        try {
            if (this.isGoogle) {
                await this.continueWithGoogle();
            }
            if (this.isReviztoInternal) {
                await this.continueWithPassword();
            }
            if (this.isSAML) {
                await this.continueWithSAML();
            }
        } catch {
            return;
        }

        this.close();
    }

    public async continueWithGoogle() {
        this.memorizeLicenseIdAndLanguage();
        const service = AuthMethodType.google;
        const landing = this.isAppAuth ? 'app-authorized' : 'ws-authorized';
        this.isAuthorizing = true;
        try {
            await this.$store.dispatch('loadOAuthServicesUrls', { service, landing });
        } catch (error) {
            this.authError = error.message;
            this.isAuthorizing = false;
            return;
        }
        const oauth = this.$store.getters.oauth;
        location.href = oauth[service].url;
    }

    public async continueWithPassword() {
        const merge = 1;
        const { email, password } = this.credentials;
        const authPassword = this.appSSO.isEmpty ? this.authorizePassword : this.authorizeAppPassword;
        const params = this.appSSO.isEmpty
            ? { email, password, merge }
            : { email, password, merge, app_sso_key: this.appSSO.key, app_sso_region: this.appSSO.region };
        this.isAuthorizing = true;
        try {
            await authPassword(params);
        } catch (error) {
            this.handleAuthError(error);
        } finally {
            this.isAuthorizing = false;
        }
    }

    public handleAuthError(error: Dict | undefined) {
        switch (error?.result) {
            case RESPONSE.TWO_FA_REQUIRED:
                this.isTwoFAStep = true;
                this.twoFAData = { ...this.twoFAData, ...error?.data };
                if (this.isEmailTwoFactorMethod) {
                    this.startTimerResendCode();
                }
                throw error;
            case RESPONSE.BANNED_PERMANENTLY:
                this.authError = this.$t('errors.bannedPermanently');
                break;
            case RESPONSE.USER_AUTH_BANNED:
                this.authError = this.$t('errors.authorizationBanned');
                break;
            case RESPONSE.INVALID_DATA:
                this.authError = this.$t('errors.incorrectCredentials');
                break;
            case RESPONSE.INVALID_PARAMS:
                this.authError = this.$t('errors.invalidEmail');
                break;
            default:
                this.authError = error?.message || JSON.stringify(error);
        }
        throw error;
    }

    public async authorizePassword(params: any): Promise<void> {
        await this.$store.dispatch('authorize', params);
        this.switchLicenseInWs();
    }

    public async authorizeAppPassword(params: any): Promise<void> {
        await this.$store.dispatch('authorizeApp', params);
        this.$router.push({ name: RouterNames.AppAuthorized });
    }

    public async loginTwoFA() {
        this.isAuthorizing = true;
        try {
            if (this.appSSO.isEmpty) {
                await this.$store.dispatch('authorizeTwoFA', this.twoFAData).then(() => {
                    this.isInvalidCode = false;
                    this.switchLicenseInWs();
                });
            } else {
                await this.$store.dispatch('authorizeTwoFAApp', {
                    app_sso_key: this.appSSO.key,
                    ...this.twoFAData,
                }).then(() => {
                    this.isInvalidCode = false;
                    this.$router.push({ name: RouterNames.AppAuthorized });
                });
            }
        } catch {
            this.isInvalidCode = true;
            this.isAuthorizing = false;
            this.otpTriggerKey++;
        }
    }

    public resendCode() {
        this.startTimerResendCode();
        this.$store.dispatch('resendCodeTwoFA', { key: this.twoFAData.key });
    }

    public startTimerResendCode() {
        startTimer(
            60,
            () => this.isDisabledResend = true,
            () => this.isDisabledResend = false,
            (secondsLeft) => this.secondsLeft = secondsLeft,
        );
    }

    public changeTwoFAMethodToEmailForLogin() {
        this.$store.dispatch('changeTwoFAMethodToEmail', {
            login: this.credentials.email,
            password: this.credentials.password,
            merge: 1,
        }).then((response) => {
            this.twoFAData = Object.assign({}, this.twoFAData, response);
            this.startTimerResendCode();
        });
    }

    public navigateToLoginForm() {
        this.isTwoFAStep = false;
        this.isInvalidCode = false;
        this.twoFAData = Empty.twoFAData;
    }

    public async continueWithSAML() {
        const data = {
            spEntityId: this.license.authMethodEntityId,
            device_id: this.$store.getters.deviceId,
            merge: 1,
        };

        this.isAuthorizing = true;
        const response = await this.$store.dispatch('postSAMLInitData', data);
        if (response.result !== RESPONSE.SUCCESS) {
            switch (response.result) {
                case RESPONSE.OBJECT_NOT_EXISTS:
                    this.authError = String(this.$t('Login.companyDomainNotFound'));
                    break;
                default:
                    this.authError = String(this.$t('Collocation.internalError'));
            }
            return;
        }
        const { url, authNRequestId, transitionalAuth } = response.data;
        CookieHelper.set(SAMLCookie.authNRequestId, authNRequestId);
        CookieHelper.set(SAMLCookie.transitionalAuth, transitionalAuth);
        location.href = url;
    }

    public async switchLicenseInWs() {
        await this.$store.dispatch('loadLicenses', true);

        if (this.pathnameToRedirect) {
            this.$router.replace({ path: this.pathnameToRedirect });
            location.reload();
        } else {
            this.$router.replace({
                name: RouterNames.StartPage,
                params: { licenseId: String((this.license as License).id), language: this.$route.params.language },
            });
        }

        this.close();
    }

    public onForgotPassword() {
        if (this.authType === AuthMethodType.revizto_internal) {
            this.$router.replace({ name: RouterNames.ForgotPassword });
        }
    }

    public returnBack() {
        this.returnToPrevious();
        this.close();
    }

    public memorizeLicenseIdAndLanguage() {
        const licenseId = (this.license as License).id;
        const language = this.$store.getters.currentLanguage;
        const json = JSON.stringify({ licenseId, language });
        CookieHelper.set('switchLicenseAuth', json);
    }
}
