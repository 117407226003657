import { IProjectStamp } from '@/types/project/ProjectStamp';
import { IFilterSendValue } from '@/types/project/IFilterSendValue';
import { IWorkflowSettings } from '@/types/workflow';
import { ICustomStatus } from '@/types/customStatus';
import { IIssueTypeSettings } from '@/types/issueType';
import { StatusReplacementMap } from '@/types/statusReplacementMap';
import { TUuid } from '@/types/common';
import { NotifierUserID } from '@/constants';
import { IMemberUpdateParams, IMemberUpdateResponse, ISetChartColorsParams } from '@/api/license.api';
import {
    BulkResult,
    CachedRawChartData,
    DashboardChartParams,
    IChangeFavoriteDashboardParams,
    IssueFilterPreset,
    ProjectPendingApproval,
    ProjectRevision,
    RawChartData,
    RawChartDataLine,
} from '@/models';
import http from './http';

export interface IProjectDashboardChartViewParams {
    projectId: number;
    dashboardUuid: string;
    chartUuid: string;
    isCachedData?: boolean;
    isForce?: boolean;
}

export default class ProjectApi {
    /**
     * Получение проектов по заданной категории
     * @param options - параметры запрашиваемых проектов
     * @param options.category - (обязательно) категория запрашиваемых проектов
     * @param options.page - (опционально) страница запрашиваемых проектов
     * @param options.notifications - (опционально) флаг, нужна ли информация о поле notifications
     */
    public static getProjects(options: { licenseUuid: string, category: string, page?: number, notifications?: boolean, issueCounters?: boolean }): Promise<any> {
        const params = {
            type: options.category,
            page: options.page || 0,
            notifications: options.notifications || false,
            screenshots: false,
            avatars: true,
        } as any;

        if (options.issueCounters) {
            params.issueCounters = {
                all: true,
                assignee: true,
                reporter: true,
                closed: true,
                unread: true,
            };
        }

        return http.get(`project/list/${options.licenseUuid}/paged`, params).then((response) => response?.data);
    }

    /**
     * Получение данных конкретного проекта
     */
    public static getProjectById(projectId: number): Promise<any> {
        return http.get(`project/${projectId}`).then((response) => response.data);
    }

    /**
     * Изменение данных проекта
     */
    public static postProjectSettings(projectId: number, params: any): Promise<any> {
        return http.post(`project/${projectId}/edit`, Object.assign(params, {
            createRevision: 1,
        })).then((response) => response.data);
    }

    /**
     * Изменение превью проекта
     */
    public static postProjectPreview(projectId: number, image: File): Promise<any> {
        return http.post(`screenshot/upload/${projectId}/preview`, {
            pic: image,
            title: image.name,
            createRevision: 1,
        }, true, true).then((response) => response.data);
    }

    /**
     * Изменение владельца проекта
     */
    public static postProjectOwner(projectId: number, newOwnerUuid: string, newRoleUuid: string, operationId: string): Promise<any> {
        return http.post(`project/${projectId}/change-owner`, {
            team_member_uuid: newOwnerUuid,
            newOwnerRole: newRoleUuid,
            operationId,
        }).then((response) => response.data);
    }

    /**
     * Архивация проекта
     */
    public static postArchiveProject(projectId: number): Promise<any> {
        return http.post(`project/${projectId}/archive`).then((response) => response.data);
    }

    /**
     * Разархивация проекта
     */
    public static postRestoreProject(projectId: number): Promise<any> {
        return http.post(`project/${projectId}/unarchive`).then((response) => response.data);
    }

    /**
     * Отписка от проекта
     */
    public static postUnsubscribeProject(projectId: number, operationId: string): Promise<any> {
        return http.post(`project/${projectId}/unsubscribe`, { operationId }).then((response) => response.data);
    }

    /**
     * Удаление проекта
     */
    public static postDeleteProject(projectId: number): Promise<any> {
        return http.post(`project/${projectId}/remove`).then((response) => response.data);
    }

    /**
     * Перемещение проекта в другую лицензию
     */
    public static postProjectLicense(projectId: number, licenseId: number): Promise<any> {
        return http.post(`project/${projectId}/move-to/${licenseId}`).then((response) => response.data);
    }

    /**
     * Получение списка участников проекта
     */
    public static getProjectMembers(projectId: number): Promise<{count: number; entities: any[]}> {
        return http.get(`project/${projectId}/team`).then((response) => response.data);
    }

    /**
     * Получение информации об активности участников проекта
     */
    public static postProjectMembersActivity(licenseId: number, projectId: number, params: any): Promise<any> {
        return http.post(`events/${licenseId}/project-events/${projectId}`, params).then((response) => response.data);
    }

    /**
     * Отправка на почту информации об активности в проекте
     */
    public static postExportProjectMembersActivity(params: any): Promise<any> {
        return http.post('events/by-project-to-email', params);
    }

    /**
     * Получение списка ревизий проекта
     */
    public static getProjectRevisions(projectId: number, params: any): Promise<any> {
        return http.get(`project/${projectId}/revisions`, params).then((response) => response.data);
    }

    /**
     * Получение списка дашбордов
     */
    public static getProjectDashboards(projectId: number): Promise<{ entities: any[]; favorite: string[]; selected: string }> {
        return http.get(`project/${projectId}/dashboard/list`).then((response) => response.data);
    }

    /**
     * Создание дашборда
     */
    public static postCreateProjectDashboard(projectId: number, params: any) {
        return http.post(`project/${projectId}/dashboard/add`, params).then((response) => response.data);
    }

    /**
     * Создание дашборда
     */
    public static postCreateProjectDashboardWithPreset(projectId: number, presetUuid: string, params: any) {
        return http.post(`project/${projectId}/dashboard/${presetUuid}/use-preset`, params).then((response) => response.data);
    }

    /**
     * Сохранение параметров дашборда
     */
    public static postUpdateProjectDashboard(projectId: number, dashboardUuid: string, params: any) {
        return http.post(`project/${projectId}/dashboard/${dashboardUuid}/edit`, params).then((response) => response.data);
    }

    /**
     * Удаление дашборда
     */
    public static postDeleteProjectDashboard(projectId: number, dashboardUuid: string) {
        return http.post(`project/${projectId}/dashboard/${dashboardUuid}/remove`).then((response) => response.data);
    }

    /**
     * Изменение сортировки графиков в дашборде
     */
    public static postChangeOrderProjectDashboardCharts(projectId: number, dashboardUuid: string, params: any) {
        return http.post(`project/${projectId}/dashboard/${dashboardUuid}/reorder`, params).then((response) => response.data);
    }

    /**
     * Получение списка графиков дашборда
     */
    public static getProjectDashboardCharts(projectId: number, dashboardUuid: string) {
        return http.get(`project/${projectId}/dashboard/${dashboardUuid}/graphs`).then((response) => response.data);
    }

    /**
     * Получение всех данных графика
     */
    public static getProjectDashboardChartSettings(projectId: number, dashboardUuid: string, chartUuid: string) {
        return http.get(`project/${projectId}/dashboard/${dashboardUuid}/graph/${chartUuid}/view`).then((response) => response.data);
    }

    /**
     * Создание нового графика проектного дашборда
     */
    public static postCreateProjectDashboardChart(projectId: number, dashboardUuid: string, params: any): Promise<{ entity: DashboardChartParams }> {
        return http.post(`project/${projectId}/dashboard/${dashboardUuid}/add-graph`, params).then((response) => response.data);
    }

    /**
     * Сохранение параметров графика проектного дашборда
     */
    public static postUpdateProjectDashboardChart(projectId: number, dashboardUuid: string, chartUuid: string, params: any): Promise<{ entity: DashboardChartParams }> {
        return http.post(`project/${projectId}/dashboard/${dashboardUuid}/graph/${chartUuid}/edit`, params).then((response) => response.data);
    }

    /**
     * Клонирование графика проектного дашборда
     */
    public static postCloneProjectDashboardChart(projectId: number, dashboardUuid: string, chartUuid: string) {
        return http.post(`project/${projectId}/dashboard/${dashboardUuid}/graph/${chartUuid}/clone`).then((response) => response.data);
    }

    /**
     * Удаление графика проектного дашборда
     */
    public static postDeleteProjectDashboardChart(projectId: number, dashboardUuid: string, chartUuid: string) {
        return http.post(`project/${projectId}/dashboard/${dashboardUuid}/graph/${chartUuid}/delete`).then((response) => response.data);
    }

    /**
     * Получение данных графика необходимых для его отрисовки
     */
    public static getProjectDashboardChartView(
        { projectId, dashboardUuid, chartUuid, isCachedData }: IProjectDashboardChartViewParams,
    ): Promise<CachedRawChartData> {
        const params = { force: Number(!isCachedData) };
        return http.get(`project/${projectId}/dashboard/${dashboardUuid}/graph/${chartUuid}/data`, params).then((response) => response.data);
    }

    /**
     * Получение данных графика необходимых для его отрисовки на основе заданных параметров
     */
    public static getProjectDashboardChartPreview(
        { projectId, dashboardUuid, params }: { projectId: number, dashboardUuid: string, params: any },
    ): Promise<RawChartData | RawChartDataLine> {
        return http.post(`project/${projectId}/dashboard/${dashboardUuid}/graph/preview`, params).then((response) => response.data);
    }

    /**
     * Единоразовая отправка графика на любое количество email
     */
    public static postSendChartToEmail(projectId: number, dashboardUuid: string, chartUuid: string, params: any) {
        return http.post(`project/${projectId}/dashboard/${dashboardUuid}/graph/${chartUuid}/send-to-email`, params).then((response) => response.data);
    }

    /**
     * Получение списка пресетов дашбордов проекта
     */
    public static getProjectDashboardPresets(projectId: number): Promise<any> {
        return http.get(`project/${projectId}/dashboard-presets`).then((response) => response.data);
    }

    /**
     * Удаление участника проекта
     */
    public static postProjectMemberDelete(projectId: number, memberId: number, operationId: string): Promise<any> {
        return http.post(`project/${projectId}/role/delete`, {
            member_id: memberId,
            operationId,
        });
    }

    /**
     * Удаление участников проекта
     */
    public static postProjectMembersDelete(projectId: number, memberUuids: string[], operationId: string): Promise<IMemberUpdateResponse> {
        return http.post(`project/${projectId}/role/bulk-delete`, {
            member_uuids: memberUuids,
            operationId,
        }).then((response) => response.data);
    }

    /**
     * Изменение роли участника проекта
     */
    public static postUpdateProjectMembersAccessRole(projectId: number, memberUuids: string[], roleId: number): Promise<any> {
        return http.post(`project/${projectId}/role/bulk-edit`, {
            member_uuids: memberUuids,
            role_id: roleId,
        }).then((response) => response.data);
    }

    /**
     * Приглашение нового участника проекта
     */
    public static postProjectMemberInvite({ projectId, invitations, roleId, operationId }: { projectId: number, invitations: string[], roleId: number, operationId: string }): Promise<any> {
        return http.post(`project/${projectId}/role/invite`, {
            invitations,
            role_id: roleId,
            operationId,
        });
    }

    /** Массовая отправка email участникам проекта */
    public static postGroupSendEmail(projectId: number, params: any): Promise<BulkResult> {
        return http.post(`project/${projectId}/bulk-send-email`, params, true, true).then((response) => response.data);
    }

    /**
     * Получение списков email'ов assignees и reporters, каждый из которых содержит полный список assignees и reporters,
     * как участников проекта, так и тех кто участниками проекта не является
     */
    public static getFieldVariants(projectId: number) {
        return http.get(`project/${projectId}/issue/field-variants`).then((response) => response.data);
    }

    /**
     * Обновление данных периодической доставки графика
     */
    public static postUpdateChartDeliverySettings(projectId: number, dashboardUuid: string, chartUuid: string, params: any): Promise<any> {
        return http.post(`project/${projectId}/dashboard/${dashboardUuid}/graph/${chartUuid}/update-delivery-settings`, params).then((response) => response.data);
    }

    /** Сохранение цветов чарта */
    public static postProjectChartColors(projectId: number, dashboardUuid: string, chartUuid: string, params: ISetChartColorsParams) {
        return http.post(`project/${projectId}/dashboard/${dashboardUuid}/graph/${chartUuid}/colors`, params).then((response) => response.data);
    }

    /** Сохранение размера чарта */
    public static postProjectChartSize(projectId: number, dashboardUuid: string, chartUuid: string, params: { size: number; }) {
        return http.post(`project/${projectId}/dashboard/${dashboardUuid}/graph/${chartUuid}/size`, params).then((response) => response.data);
    }

    /**
     * Единоразовая отправка дашборда на любое количество email
     */
    public static postDashboardSendToEmail(projectId: number, dashboardUuid: string, params: any) {
        return http.post(`project/${projectId}/dashboard/${dashboardUuid}/send-to-email`, params).then((response) => response.data);
    }

    /**
     * Настройки отправки дашборда
     */
    public static postDashboardSetDelivery(projectId: number, dashboardUuid: string, params: any) {
        params.includeCompanyLogo = Number(params.includeCompanyLogo); // так как в апишку нужны инты, а в модели булы
        return http.post(`project/${projectId}/dashboard/${dashboardUuid}/update-delivery-settings`, params).then((response) => response.data);
    }

    /**
     * Создание пресета дашборда
     */
    public static postAddPreset(projectId: number, dashboardUuid: string, title: string) {
        return http.post(`project/${projectId}/dashboard/${dashboardUuid}/create-preset`, {
            title,
        }).then((response) => response.data);
    }

    /**
     * Обновление пресета дашборда
     */
    public static postUpdatePreset(projectId: number, dashboardUuid: string, presetUuid: string) {
        return http.post(`project/${projectId}/dashboard/${dashboardUuid}/update-preset/${presetUuid}`)
            .then((response) => response.data);
    }

    /**
     * Получение списка пресетов фильтров проекта
     */
    public static getIssueFilterPresets(projectId: number): Promise<any> {
        return http.get(`project/${projectId}/issue-filter-set`).then((response) => response.data);
    }

    /**
     * Save filters as a new preset
     */
    public static postAddIssuesFilterSet(projectId: number, entities: any[]): Promise<any> {
        return http.post(`project/${projectId}/issue-filter-set/add`, {
            entities: JSON.stringify(entities),
        }).then((response) => response.data);
    }

    /**
     * Edit a preset
     */
    public static postEditIssuesFilterSet(projectId: number, entities: any[]): Promise<any> {
        return http.post(`project/${projectId}/issue-filter-set/edit`, {
            entities: JSON.stringify(entities),
        }).then((response) => response.data);
    }

    /**
     * Edit preset's visibility
     */
    public static postEditIssuesFilterSetVisibility(projectId: number, entities: any[]): Promise<any> {
        return http.post(`project/${projectId}/issue-filter-set/visibility`, {
            entities: JSON.stringify(entities),
        }).then((response) => response.data);
    }

    /**
     * Remove presets
     */
    public static postRemoveIssueFilterSet(projectId: number, entities: string[]): Promise<any> {
        return http.post(`project/${projectId}/issue-filter-set/remove`, {
            entities: JSON.stringify(entities),
        }).then((response) => response.data);
    }

    /**
     * Reorder presets
     */
    public static postReorderIssueFilterSets(projectId: number, order: string) {
        return http.post(`project/${projectId}/issue-filter-set/re-order`, { order })
            .then((response) => response.data);
    }

    /**
     * Получение списка ишьюсов по пресету
     * Временный метод
     */
    public static postGetIssues(projectUuid: string, params: any): Promise<any> {
        return http.post(`project/${projectUuid}/issue-filter/filter`, params).then((response) => response.data);
    }

    /**
     * Получение списка удаленных ишьюсов по проекту
     */
    public static getDeletedIssues(projectId: number, params: any): Promise<any> {
        return http.post(`project/${projectId}/issue-filter/filter_deleted`, params).then((response) => response.data);
    }

    /**
     * Получение списка комментов для ишьюсов
     * Временный метод
     */
    public static getComments(issueUuid: string, params: any): Promise<any> {
        return http.get(`issue/${issueUuid}/comments`, params).then((response) => response.data);
    }

    public static postComments(issueUuid: string, params: any): Promise<any[]> {
        return http.post('comment/add', params, true, true).then((response) => response.data);
    }

    /**
     * Метод для resend приглашения в проект
     */
    public static postResendProjectInvitations(projectId: number, params: any) {
        return http.post(`project/${projectId}/role/invite/resend`, params).then((response) => response.data);
    }

    /**
     * Метод для получения списка ждунов в проекте
     */
    public static getPendingMembers(projectId: number): Promise<ProjectPendingApproval[]> {
        return http.get(`project/${projectId}/pending`).then((response) => response.data);
    }

    /**
     * Создание проекта из веба
     */
    public static createProject(params: any): Promise<any> {
        return http.post('project/create', params).then((response) => response.data);
    }

    /**
     * создаём ревизию проекта
     */
    public static addProjectRevision({ projectId, revision }: { projectId: number, revision: ProjectRevision }) {
        return http.post(`project/${projectId}/revision/add`, revision.apiParams).then((response) => response.data);
    }

    /**
     * коммитим ревизию проекта
     */
    public static commitProjectRevision(uuid: string) {
        return http.post(`project/revision/${uuid}/commit`).then((response) => response.data);
    }

    /**
     * Узнаём список возможных пермишнов при трансфере настроек из проекта:
     * проект-донор, лицензия-акцептор
     */
    public static postProjectTransferPermissions({ projectId, licenseUuid }: { projectId: number, licenseUuid: string }) {
        return http.post(`project/${projectId}/transfer/settings/new_project`, {
            license_uuid: licenseUuid,
        }).then((response) => response.data);
    }

    /**
     * Переносит настройки из проекта-донора projectId в проекты-акцепторы, uuid-шники которых зашиты
     * в массив объектов requests
     */
    public static postRequestProjectTransferSettings({ projectId, requests }: { projectId: string, requests: any }) {
        return http.post(`project/${projectId}/transfer/settings/request`, {
            projects: JSON.stringify(requests),
        });
    }

    /**
     * Экспорт дашборда
     */
    public static postExportProjectDashboard({ projectId, dashboardUuid, params }: { projectId: string, dashboardUuid: string, params: any }) {
        return http.post(`project/${projectId}/dashboard/${dashboardUuid}/export`, params).then((response) => response.data);
    }

    /**
     * Импорт дашборда
     */
    public static postImportProjectDashboard({ projectId, params }: { projectId: string, params: any }) {
        return http.post(`project/${projectId}/dashboard/import`, params).then((response) => response.data);
    }

    /**
     * Импорт графиков для дашборда
     */
    public static postImportProjectDashboardCharts({ projectId, dashboardUuid, params }: { projectId: string, dashboardUuid: string, params: any }) {
        return http.post(`project/${projectId}/dashboard/${dashboardUuid}/import-graphs`, params).then((response) => response.data);
    }

    /**
     * Добавление дашбордов в фаворитные
     */
    public static postAddFavoriteProjectDashboards({ projectId, params }: { projectId: string, params: IChangeFavoriteDashboardParams }) {
        return http.post(`project/${projectId}/favorite-dashboard/add`, params).then((response) => response.data);
    }

    /**
     * Удаление дашбордов в фаворитные
     */
    public static postRemoveFavoriteProjectDashboards({ projectId, params }: { projectId: string, params: IChangeFavoriteDashboardParams }) {
        return http.post(`project/${projectId}/favorite-dashboard/remove`, params).then((response) => response.data);
    }

    /**
     * Получение фаворитных дашбордов
     */
    public static getFavoriteProjectDashboards({ projectId }: { projectId: string }) {
        return http.get(`project/${projectId}/favorite-dashboard`).then((response) => response.data);
    }

    /**
     * Subscribe project dashboard
     */
    public static subscribeOnDashboard({ projectId, dashboardUuid }: { projectId: string, dashboardUuid: string }) {
        return http.post(`project/${projectId}/dashboard/${dashboardUuid}/selfsubscribe`).then((response) => response.data);
    }

    /**
     * Unsubscribe project dashboard
     */
    public static unsubscribeFromDashboard({ projectId, dashboardUuid }: { projectId: string, dashboardUuid: string }) {
        return http.post(`project/${projectId}/dashboard/${dashboardUuid}/selfunsubscribe`).then((response) => response.data);
    }

    /**
     * Subscribe license dashboard
     */
    public static subscribeOnLicenseDashboard({ licenseId, dashboardUuid }: { licenseId: string, dashboardUuid: string }) {
        return http.post(`license/${licenseId}/dashboard/${dashboardUuid}/selfsubscribe`).then((response) => response.data);
    }

    /**
     * Unsubscribe license dashboard
     */
    public static unsubscribeFromLicenseDashboard({ licenseId, dashboardUuid }: { licenseId: string, dashboardUuid: string }) {
        return http.post(`license/${licenseId}/dashboard/${dashboardUuid}/selfunsubscribe`).then((response) => response.data);
    }

    /**
     * Получение данных пресетов
     */
    public static getProjectPresetUnread({ projectUuid, filters }: { projectUuid: string, filters: any }) {
        return http.post(`project/${projectUuid}/issue-filter/unread`, { filters }).then((response) => response.data);
    }

    /**
     * Read All Unseen
     */
    public static readAllUnseenIssues({ projectUuid, filters, alwaysFiltersDTO }: { projectUuid: string, filters: IssueFilterPreset[], alwaysFiltersDTO: IFilterSendValue[] }) {
        return http.post(`project/${projectUuid}/issue-filter/mark-read`, { filters, alwaysFiltersDTO }).then((response) => response.data);
    }

    /**
     * Read one issue
     */
    public static readIssue({ projectId, issueUuid }: { projectId: number, issueUuid: string }) {
        return http.post(`issue/${issueUuid}/read`, { project_id: projectId }).then((response) => response.data);
    }

    /**
     * Read comments for one issue
     */
    public static readIssueComments({ projectId, issueUuid, count }: { projectId: number, issueUuid: string, count: number }) {
        return http.post(`issue/${issueUuid}/comments/read`, { project_id: projectId, count }).then((response) => response.data);
    }

    /**
     * Undelete issue
     */
    public static undeleteIssue(payload: { projectId: number, issueUuid: string, operationId: string, notifierUserId: number }) {
        const { projectId, issueUuid, operationId } = payload;

        return http.post(`issue/${issueUuid}/undelete`, {
            project_id: projectId,
            operationId,
            [NotifierUserID]: payload.notifierUserId,
        }).then((response) => response);
    }

    /**
     * Stamp list
     */
    public static getStampList({ projectId, page }: { projectId: number, page: number }) {
        return http.get(`project/${projectId}/issue-preset/list`, { project_id: projectId, page }).then((response) => response.data);
    }

    /**
     * Create issues tags
     */
    public static createIssuesTags(params: { projectId: number, tags: string[], notifierUserID: number }) {
        return http.post(`project/${params.projectId}/tags/add`, {
            tags: JSON.stringify(params.tags),
            notifierUserID: params.notifierUserID,
        });
    }

    /**
     * Edit issues tag
     */
    public static editIssuesTag(params: {
        projectId: number,
        oldTag: string,
        newTag: string,
        notifierUserID: number,
    }) {
        return http.post(`project/${params.projectId}/tags/edit`, {
            old: params.oldTag,
            new: params.newTag,
            [NotifierUserID]: params.notifierUserID,
        });
    }

    /**
     * Delete issues tags and create new a tag instead of deleted
     */
    public static deleteIssuesTags(params: {
        projectId: number,
        oldTags: string[],
        newTag?: string,
        notifierUserID: number,
    }) {
        return http.post(`project/${params.projectId}/tags/delete`, {
            old: params.oldTags,
            new: params.newTag,
            [NotifierUserID]: params.notifierUserID,
        });
    }

    /**
     * Send filters and sorting (and dummy deleted), receive short hash
     */
    public static postAddTemporary({ projectId, filters, sorting }: { projectId: number, filters: string, sorting: string }) {
        return http.post(`project/${projectId}/issue-filter-set/add-temporary`, { projectId, filters, sorting, deleted: 0 })
            .then((response) => response.data);
    }

    /**
     * Send hash, get filters and sorting
     */
    public static getTemporary({ projectId, hash }: { projectId: number, hash: string }) {
        return http.get(`project/${projectId}/issue-filter-set/get-temporary/${hash}`).then((response) => response.data);
    }

    /**
     * Send issues to receive pdf
     */
    public static postIssuesToPdf({ projectId, params }: { projectId: number, params: any }) {
        return http.post(`project/${projectId}/issues/export`, params);
    }

    /**
     * Delete issue
     */
    public static postDeleteIssue(payload: { projectId: number, issueUuid: string, operationId: string, notifierUserID: number }) {
        const { projectId, issueUuid, operationId } = payload;
        return http.post(`issue/${issueUuid}/delete`, {
            project_id: projectId,
            operationId,
            [NotifierUserID]: payload.notifierUserID,
        }).then((response) => response);
    }

    /**
     * Получение списка ишьюсов по пресету
     * Временный метод
     */
    public static postGetIssueOrder(projectId: number, params: any): Promise<any> {
        return http.post(`project/${projectId}/issue-filter/filter_all`, params).then((response) => response.data);
    }

    /**
     * Pin chat comment
     */
    public static pinChatComment(projectId: number, issueUuid: string, commentUuid: string): Promise<any> {
        return http.post(`project/${projectId}/issue/${issueUuid}/pin-comment`, { commentid: commentUuid }).then((response) => response.data);
    }

    /**
     * Pin chat comment
     */
    public static unpinChatComment(projectId: number, issueUuid: string, commentUuid: string): Promise<any> {
        return http.post(`project/${projectId}/issue/${issueUuid}/unpin-comment`, { commentid: commentUuid  }).then((response) => response.data);
    }

    /**
     * Send event open project
     */
    public static postEventOpenProject(projectId: number, params: any): Promise<any> {
        return http.post(`events/${projectId}`, params).then((response) => response.data);
    }

    /**
     * Save markup stage 1
     */
    public static postMarkupSync(issueId: string, params: any): Promise<any> {
        return http.post(`issue/${issueId}/sync`, params, true, true).then((response) => response.data);
    }

    /**
     * add stamp
     */
    public static addStamp({ projectId, entities }: { projectId: number, entities: IProjectStamp[] }) {
        return http.post(`project/${projectId}/issue-preset/add`, { project_id: projectId, entities: JSON.stringify(entities) }).then((response) => response.data);
    }

    /**
     * edit stamp
     */
    public static editStamp({ projectId, entities }: { projectId: number, entities: IProjectStamp[] }) {
        return http.post(`project/${projectId}/issue-preset/edit`, { project_id: projectId, entities: JSON.stringify(entities) }).then((response) => response.data);
    }

    /**
     * remove stamp
     */
    public static removeStamp({ projectId, uuids }: { projectId: number, uuids: string[] }) {
        return http.post(`project/${projectId}/issue-preset/remove`, {
            project_id: projectId,
            entities: JSON.stringify(uuids.map((uuid: string) => {
                return {
                    uuid,
                };
            })),
        }).then((response) => response.data);
    }

    public static postGroupUpdateAdditionalFields(
        projectUuid: string,
        params: IMemberUpdateParams,
    ): Promise<IMemberUpdateResponse> {
        const data = JSON.stringify(params.data);
        return http.post(`project/${projectUuid}/update-license-members`, { data })
            .then((response) => response.data);
    }

    /**
     * Get project access keys
     */
    public static getAccessKeys({ projectId }: { projectId: number }) {
        return http.get(`project/${projectId}/access`).then((response) => response.data);
    }

    /**
     * Getting task definition list
     */
    public static getSchedulerTasks({ projectUuid, append, filters, sort, limit, query, page = 1 }:
        { projectUuid: string, append?: string[], filters?: any, sort: any, limit: number, query: string, page: number },
    ) {
        return http.get('task-definition', { projectUuid, append, ...filters, ...sort, query, limit, page }).then((response) => response.data);
    }

    /**
     * Getting task definition
     */
    public static getSchedulerTask({ projectUuid, uuid }: { projectUuid: string, uuid: string }) {
        return http.get(`task-definition/${uuid}`, { projectUuid, uuid }).then((response) => response.data);
    }

    /**
     * Getting task run list
     */
    public static getSchedulerTaskHistory({ projectUuid, sort, limit, filters, query, page = 1, taskDefinitionUuids = [] }: { projectUuid: string, page: number, sort: any, query: string, limit: number, filters: any, taskDefinitionUuids: TUuid[] }) {
        return http.get('task-run', { projectUuid, page, query, limit, ...filters, ...sort, taskDefinitionUuids, append: ['appendMessages', 'appendDeviceData', 'appendLastOnline'] }).then((response) => response.data); //  appendLastOnline
    }

    /**
     * Getting futur tasks
     */
    public static getSchedulerFutureTasks({ projectUuid, page = 1 }: { projectUuid: string, page: number }) {
        return http.get('future-task-run', { projectUuid, page }).then((response) => response.data);
    }

    /**
     * Create task definition
     */
    public static addSchedulerTaskDefinition(params: any) {
        return http.postJson('task-definition', params).then((response) => response.data);
    }

    /**
     * Create task run
     */
    public static addSchedulerTaskHistory(params: any) {
        return http.postJson('task-run', params).then((response) => response.data);
    }

    /**
     * Create device
     */
    public static addDevice(params: any) {
        return http.postJson('device', params).then((response) => response.data);
    }

    /**
     * Delete task definition
     */
    public static deleteSchedulerTaskDefinition(uuid: string) {
        return http.postJson(`task-definition/${uuid}/delete`).then((response) => response);
    }

    /**
     * add stamp
     */
    public static getDevices() {
        return http.get('device').then((response) => response.data);
    }

    /**
     * schedule filter params
     */

    public static getSchedulerFilterParams({ projectUuid }: { projectUuid: string }) {
        return http.get(`scheduler-filters/${projectUuid}/scheduler-filter-params`).then((response) => response.data);
    }

    /**
     * scheduled (upcoming) tasks
     */
    public static getScheduledTasks(options: {
        projectUuid: string,
        types?: string[],
        userEmails?: string[],
        deviceIds?: string[],
        orderBy?: string[],
        orderDirection?: string[],
        page?: number,
        limit?: number,
        dateStart?: number,
        dateEnd?: number,
        query?: string,
        append: string[]
    }) {
        return http.get('scheduled-task-run', options).then((response) => response.data);
    }

    /**
     * Get workflow data
     */
    public static getWorkflowSettings(projectUuid: string): Promise<any> {
        return http.get(`project/${projectUuid}/issue-workflow/settings`).then((response) => response.data);
    }

    /**
     * save workflow data
     */
    public static setWorkflowSettings(projectUuid: string, workflowUuid: string, params: IWorkflowSettings): Promise<any> {
        return http.post(
            `project/${projectUuid}/issue-workflow/update`,
            { entities: JSON.stringify([{ uuid: workflowUuid, ...params }]) },
            true,
            true,
        ).then((response) => response.data);
    }

    /**
     * add workflow
     */
    public static createWorkflow(projectUuid: string, params: IWorkflowSettings): Promise<any> {
        return http.post(
            `project/${projectUuid}/issue-workflow/add`,
            { entities: JSON.stringify([params]), statusesUuids: '[]' },
            true,
            true,
        ).then((response) => response.data);
    }

    /**
     * Remove workflow
     */
    public static removeWorkflow(
            projectUuid: TUuid,
            workflowUuid: TUuid,
            params: {
                newWorkflowUuid: TUuid,
                statusReplacementMap: StatusReplacementMap[]
            }): Promise<any> {
        return http.post(
            `project/${projectUuid}/issue-workflow/${workflowUuid}/delete`,
            params,
            true,
            true,
        ).then((response) => response.data);
    }

    /**
     * add status to workflow
     */
    public static addStatusToWorkflow(projectUuid: string, workflowUuid: string, statusesUuids: TUuid[]): Promise<any> {
        return http.post(
            `project/${projectUuid}/issue-workflow/${workflowUuid}/add_statuses`,
            { statusesUuids },
            true,
            true,
        ).then((response) => response.data);
    }

    /**
     * reorder statuses
     */
    public static reorderStatusesInWorkflow(projectUuid: TUuid, workflowUuid: TUuid, statusesUuids: TUuid[]): Promise<any> {
        return http.post(
            `project/${projectUuid}/issue-workflow/${workflowUuid}/reorder_statuses`,
            { statusesUuids },
            true,
            true,
        ).then((response) => response.data);
    }

    /**
     * create custom status
     */
    public static addCustomStatus(projectUuid: TUuid, status: ICustomStatus): Promise<any> {
        return http.post(
            `project/${projectUuid}/issue-status/add`,
            { entities: JSON.stringify([status]) },
            true,
            true,
        ).then((response) => response.data);
    }

    /**
     * edit custom status
     */
    public static editCustomStatus(projectUuid: TUuid, status: ICustomStatus): Promise<any> {
        return http.post(
            `project/${projectUuid}/issue-status/update`,
            { entities: JSON.stringify([status]) },
            true,
            true,
        ).then((response) => response.data);
    }

    /**
     * create custom status
     */
    public static removeCustomStatusFromWorkflow(projectUuid: TUuid, workflowUuid: TUuid, statusReplacementMap: StatusReplacementMap[]): Promise<any> {
        return http.post(
            `project/${projectUuid}/issue-workflow/${workflowUuid}/delete_status`,
            { statusReplacementMap },
            true,
            true,
        ).then((response) => response.data);
    }

    /**
     * add custom issue type
     */
    public static createIssueType(projectUuid: string, payload: IIssueTypeSettings): Promise<any> {
        const params = {
            entities: JSON.stringify([{
                name: payload.name,
                description: payload.description,
                icon: payload.icon,
                isDefault: payload.isDefault,
                workflowUuid: payload.workflowUuid,
                iconColor: payload.iconColor,
            }]),
        };

        return http.post(`project/${projectUuid}/issue-type/add`, params, true, true)
            .then((response) => response.data);
    }

    /**
     * restore custom issue type
     */
    public static restoreIssueType(projectUuid: string, typeUuid: string): Promise<any> {
        return http.post(`project/${projectUuid}/issue-type/${typeUuid}/restore`).then((response) => response.data);
    }

    /**
     * update custom issue type
     */
    public static updateIssueType(projectUuid: string, payload: IIssueTypeSettings[]): Promise<any> {
        return http.post(`project/${projectUuid}/issue-type/update`,  { entities: JSON.stringify(payload) }, true, true).then((response) => response.data);
    }

    public static deleteIssueType(projectUuid: string, payload: {
        typeUuid: string,
        newTypeUuid: string,
        statusReplacementMap: StatusReplacementMap[],
    }): Promise<any> {
        const params = {
            newTypeUuid:  payload.newTypeUuid,
            statusReplacementMap: payload.statusReplacementMap,
        };

        return http.post(`project/${projectUuid}/issue-type/${payload.typeUuid}/delete`, params, true, true);
    }

    public static editIssueType(projectUuid: string, issueTypeUuid: string, payload: {
        name: string;
        description: string;
        icon: string;
        isDefault: boolean;
        workflowUuid: string;
        iconColor: string;
        statusReplacementMap: StatusReplacementMap[];
        deletedAt: string | null;
    }): Promise<any> {
        const params = {
            entities: JSON.stringify([{
                uuid: issueTypeUuid,
                name: payload.name,
                description: payload.description,
                icon: payload.icon,
                isDefault: payload.isDefault,
                workflowUuid: payload.workflowUuid,
                iconColor: payload.iconColor,
                statusReplacementMap: payload.statusReplacementMap,
                deletedAt: payload.deletedAt,
            }]),
        };

        return http.post(`project/${projectUuid}/issue-type/update`, params, true, true)
            .then((result) => result.data);
    }
}
