import _ from 'lodash';
import { IFilterSendValue } from '@/types/project/IFilterSendValue';
import {
    currentUserKey,
    IssueFilterExprT,
    IssuesFilterType,
    TrackerFilterTypeT,
} from '@/constants';
import { IIssueTrackerFilterValue } from '@/models/ProjectIssuesFilters';
import { IssueTrackerFilterByDateValue } from '@/services/issueTracker/IssueTrackerFilterByDateValue';
import { IssueTrackerFilterByDeadlineValue } from '@/services/issueTracker/IssueTrackerFilterByDeadlineValue';
import { IssueTrackerFilterByDurationValue } from '@/services/issueTracker/IssueTrackerFilterByDurationValue';

// Don't export by index.ts in services dir for avoid dependency of Store in all services imports
export class IssueTrackerFilterValue implements IIssueTrackerFilterValue {
    public type: TrackerFilterTypeT;
    public modifier: IssueFilterExprT;
    public selections: Array<string | number> | string;

    constructor(type: string, modifier: IssueFilterExprT, selections: Array<string | number>) {
        this.type = type;
        this.modifier = modifier;
        this.selections = Array.isArray(selections) ? _.uniq(selections) : selections;
    }

    public static instantiate(filterObj: any) {
        switch (filterObj.type) {
            case IssuesFilterType.deadline: {
                const { type, dateFilterType, date, date2, days, includeNow }: any = filterObj;
                return new IssueTrackerFilterByDeadlineValue(type, dateFilterType, date, date2, days, includeNow);
            }
            case IssuesFilterType.created:
            case IssuesFilterType.closed: {
                const { type, dateFilterType, date, date2, days }: any = filterObj;
                return new IssueTrackerFilterByDateValue(type, dateFilterType, date, date2, days);
            }
            case IssuesFilterType.duration: {
                return new IssueTrackerFilterByDurationValue(filterObj.dateFilterType, filterObj.date.value, filterObj.date.units);
            }
            default: {
                return new IssueTrackerFilterValue(filterObj.type, filterObj.modifier, filterObj.selections);
            }
        }
    }

    get isActive() {
        return this.selections.length > 0;
    }

    public getApiParams(userEmail?: string): IFilterSendValue {
        let selections = this.selections;
        if ([IssuesFilterType.assignee, IssuesFilterType.watchers, IssuesFilterType.reporter].includes(this.type)) {
            selections = this.transformSelections(selections as Array<string | number>, userEmail || '');
        }

        return { type: this.type, expr: this.modifier, value: selections };
    }

    private transformSelections(selections: Array<(string | number)>, userEmail: string) {
        return selections.map((selectedValue) => (
            selectedValue === currentUserKey ? userEmail : selectedValue
        ));
    }
}
