




























import { Component, Vue, Watch } from 'vue-property-decorator';
// @ts-ignore
import VueCookies from 'vue-cookies/vue-cookies.js';
import { User } from '@/types/User';
import Router from '@/router';
import { BusEvent, ButtonVariant, RouterNames, TimezoneLocalStorageKey } from '@/constants';
import authHttp from '@/api/authHttp';
import http from '@/api/http';
import { License } from '@/models';
import { eventBus } from '@/services/eventBus';
import { getTimezoneOffset, notificationInfo, toastError, transformTimezoneToTranslation } from '@/services';
import { IBrowserTimezone } from '@/storage/auth.storage';
import Header from '@/components/header/Header.vue';
import LoginMergeForm from '@/components/user/LoginMergeForm.vue';
import WsDialog from '@/components/common/WsDialog.vue';
import StepsDialog from '@/components/user/boarding/StepsDialog.vue';
import WelcomeDialog from '@/components/user/boarding/WelcomeDialog.vue';
import WsNotifier from '@/components/common/Notifier/WsNotifier.vue';

@Component({
    components: {
        Header,
        LoginMergeForm,
        WsDialog,
        WelcomeDialog,
        StepsDialog,
        WsNotifier,
    },
})
export default class App extends Vue {
    public isLoginToLicenseNeeded = false;
    public pathnameToRedirect = '';

    get isLoadingRegions() {
        return this.$store.getters.isLoadingRegions;
    }

    get license(): License {
        return this.$store.getters.currentLicense;
    }

    get licenses(): License[] {
        return this.$store.getters.licenses;
    }

    get isShowBoardingWelcome(): boolean {
        return this.$store.getters.isShowBoardingWelcome;
    }

    get isShowBoardingSteps(): boolean {
        return this.$store.getters.isShowBoardingSteps;
    }

    get userData(): User {
        return this.$store.getters.userData;
    }

    get browserTimeZone(): IBrowserTimezone {
        return this.$store.getters.browserTimezone;
    }

    @Watch('license')
    public onNewLicense(license: License, oldLicense: License) {
        if (oldLicense.id && license.id !== oldLicense.id) {
            this.$store.commit('setPreviousLicenseId', oldLicense.id);
        }
        if (!license.isValidAuth && license.id) {
            this.pathnameToRedirect = location.pathname;
            VueCookies.set('pathnameToRedirect', this.pathnameToRedirect);
            VueCookies.set('pathnameLicenseId', String(license.id));
            this.isLoginToLicenseNeeded = true;
        } else {
            this.isLoginToLicenseNeeded = false;
        }
    }

    @Watch('userData')
    public onUserDataChange(user: User, oldUser: User) {
        if (user.uuid !== oldUser.uuid && !this.userData.autoUpdateTimezone) {
            this.checkTimeZone();
        }
    }

    public created() {
        http.setLogoutCallback(() => {
            this.$store.dispatch('logout', { isGoToLogin: true });
        });
        authHttp.setLogoutCallback(() => {
            this.$store.dispatch('logout', { isGoToLogin: true });
        });
        
        this.handle2FaRequired();
        eventBus.$on(BusEvent.requestCopyToBuffer, this.copyToBuffer);
        this.setBrowserTimezone();
    }

    public checkTimeZone() {
        const userTimezone =  this.userData.timezone.timezone;
        const localStorageTime = localStorage.getItem(TimezoneLocalStorageKey);

        const browserTimezoneTranslation = transformTimezoneToTranslation(this.browserTimeZone.timezone, this.browserTimeZone.offset);

        if (this.browserTimeZone.timezone !== userTimezone && this.browserTimeZone.timezone !== localStorageTime) {
            notificationInfo({
                title: this.$t('Timezone.dialog.title'),
                text: this.$t('Timezone.dialog.content', { region: browserTimezoneTranslation }),
                duration: Infinity,
                actions: [
                    {
                        text: this.$t('Timezone.dialog.correct'),
                        callback: () => {
                            const payload = {
                                firstname: this.userData.firstname,
                                lastname: this.userData.lastname,
                                email: this.userData.email,
                                timeFormat: this.userData.timeFormat,
                                dateFormat: this.userData.dateFormat,
                                timezone: this.browserTimeZone.timezone,
                            };

                            this.$store.dispatch('uploadUserData', payload).then(() => {
                                this.$store.dispatch('downloadUserData', true);
                            }).catch((error) => {
                               toastError(error || this.$t('errors.server_error'));
                            });
                        },
                    },
                    {
                        text: this.$t('Timezone.dialog.changeIt'),
                        variant: ButtonVariant.SECONDARY,
                        callback: () => {
                            this.$router.push({ name: RouterNames.UserSettings });
                        },
                    },
                ],
                onCloseAction: () => {
                    localStorage.setItem(TimezoneLocalStorageKey, this.browserTimeZone.timezone);
                },
            });
        }
    }

    public setBrowserTimezone() {
        const browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const browserTimeZoneOffset = getTimezoneOffset();
        
        this.$store.commit('setBrowserTimezone', {
            timezone: browserTimeZone,
            offset: browserTimeZoneOffset,
        });
    }

    public handle2FaRequired() {
        eventBus.$on(BusEvent.faRequired, () => {
            Router.push({
                name: RouterNames.UserSecurity,
                params: {
                    show2FAPopup: 'true',
                },
            });
        });
    }

    public copyToBuffer(content: string) {
        // Use textarea for copy to buffer for avoid problems with copy from code and from modals
        const textarea = document.createElement('textarea');
        textarea.value = content;
        document.body.appendChild(textarea);
        textarea.select();
        textarea.focus(); // It's necessary for case when you try copy to buffer from code when page have modal with focus
        document.execCommand('copy');
        document.body.removeChild(textarea);
    }
}
