import _ from 'lodash';
import Vue from 'vue';
import Vuex from 'vuex';

import projectReportsStorage from '../domain/report/storage/projectReports.storage';
import httpStorage from './http.storage';
import authStorage from './auth.storage';
import regionStorage from './region.storage';
import forgotPasswordStorage from './forgotPassword.storage';
import commonStorage from './common.storage';
import myProjectsStorage from './myProjects.storage';
import projectsStorage from './projects.storage';
import projectDashboardsStorage from './projectDashboards.storage';
import projectDashboardPresetsStorage from './projectDashboardPresets.storage';
import projectMembersStorage from './projectMembers.storage';
import licensesStorage from './licenses.storage';
import licenseDashboardsStorage from './licenseDashboards.storage';
import licenseProjectsStorage from './licenseProjects.storage';
import licenseMembersStorage from './licenseMembers.storage';
import authMethodsStorage from './authMethods.storage';
import notificationsStorage from './notifications.storage';
import notificationStorage from './notification.storage';
import integrationsStorage from './integrations.storage';
import unsubscribeStorage from './unsubscribe.storage';
import websocketStorage from './websocket.storage';
import recentProjectsStorage from './recentProjects.storage';
import supportStorage from './support.storage';
import releaseStorage from './release.storage';
import projectMembersActivityStorage from './projectMembersActivity.storage';
import licenseMembersActivityStorage from './licenseMembersActivity.storage';
import sentEmailsStorage from './sentEmails.storage';
import actionLogsStorage from './actionLogs.storage';
import membersApprovalStorage from './membersApproval.storage';
import tableControlsStorage from './tableControls.storage';
import tableSortStorage from './tableSort.storage';
import headerStorage from './header.storage';
import projectIssueFilterPresetStorage from './projectIssueFilterPreset.storage';
import projectIssuesStorage from './projectIssues.storage';
import issueCommentsStorage from './issueComments.storage';
import issueTrackerStorage from './issueTracker.storage';
import issueTrackerMultiSelect from './issueTrackerMultiSelect.storage';
import amazonStorage from './amazon.storage';
import schedulerStorage from './scheduler.storage';
import projectWorkflowStorage from './projectWorkflow.storage';

Vue.use(Vuex);

const initialState = {
    actionLogsStorage: _.cloneDeep(actionLogsStorage.state),
    authMethodsStorage: _.cloneDeep(authMethodsStorage.state),
    commonStorage: _.cloneDeep(commonStorage.state),
    forgotPasswordStorage: _.cloneDeep(forgotPasswordStorage.state),
    headerStorage: _.cloneDeep(headerStorage.state),
    integrationsStorage: _.cloneDeep(integrationsStorage.state),
    issueCommentsStorage: _.cloneDeep(issueCommentsStorage.state),
    issueTrackerStorage: _.cloneDeep(issueTrackerStorage.state),
    licenseDashboardsStorage: _.cloneDeep(licenseDashboardsStorage.state),
    licenseMembersStorage: _.cloneDeep(licenseMembersStorage.state),
    licenseMembersActivityStorage: _.cloneDeep(licenseMembersActivityStorage.state),
    licenseProjectsStorage: _.cloneDeep(licenseProjectsStorage.state),
    licensesStorage: _.cloneDeep(licensesStorage.state),
    membersApprovalStorage: _.cloneDeep(membersApprovalStorage.state),
    myProjectsStorage: _.cloneDeep(myProjectsStorage.state),
    notificationsStorage: _.cloneDeep(notificationsStorage.state),
    notificationStorage: _.cloneDeep(notificationStorage.state),
    projectDashboardPresetsStorage: _.cloneDeep(projectDashboardPresetsStorage.state),
    projectDashboardsStorage: _.cloneDeep(projectDashboardsStorage.state),
    projectIssueFilterPresetStorage: _.cloneDeep(projectIssueFilterPresetStorage.state),
    projectIssuesStorage: _.cloneDeep(projectIssuesStorage.state),
    projectMembersStorage: _.cloneDeep(projectMembersStorage.state),
    projectMembersActivityStorage: _.cloneDeep(projectMembersActivityStorage.state),
    projectReportsStorage: _.cloneDeep(projectReportsStorage.state),
    projectsStorage: _.cloneDeep(projectsStorage.state),
    recentProjectsStorage: _.cloneDeep(recentProjectsStorage.state),
    releaseStorage: _.cloneDeep(releaseStorage.state),
    sentEmailsStorage: _.cloneDeep(sentEmailsStorage.state),
    supportStorage: _.cloneDeep(supportStorage.state),
    unsubscribeStorage: _.cloneDeep(unsubscribeStorage.state),
    websocketStorage: _.cloneDeep(websocketStorage.state),
    issueTrackerMultiSelect: _.cloneDeep(issueTrackerMultiSelect.state),
    amazonStorage: _.cloneDeep(amazonStorage.state),
    schedulerStorage: _.cloneDeep(schedulerStorage.state),
    projectWorkflowStorage: _.cloneDeep(projectWorkflowStorage.state),
} as any;

export default new Vuex.Store({
    modules: {
        actionLogsStorage,
        authMethodsStorage,
        commonStorage,
        forgotPasswordStorage,
        headerStorage,
        integrationsStorage,
        issueCommentsStorage,
        issueTrackerStorage,
        licenseDashboardsStorage,
        licenseMembersStorage,
        licenseMembersActivityStorage,
        licenseProjectsStorage,
        licensesStorage,
        membersApprovalStorage,
        myProjectsStorage,
        notificationsStorage,
        notificationStorage,
        projectDashboardPresetsStorage,
        projectDashboardsStorage,
        projectIssueFilterPresetStorage,
        projectIssuesStorage,
        projectMembersStorage,
        projectMembersActivityStorage,
        projectReportsStorage,
        projectsStorage,
        schedulerStorage,
        recentProjectsStorage,
        releaseStorage,
        sentEmailsStorage,
        supportStorage,
        unsubscribeStorage,
        websocketStorage,
        amazonStorage,
        projectWorkflowStorage,
        // not resettable storages
        authStorage,
        httpStorage,
        regionStorage,
        tableControlsStorage,
        tableSortStorage,
        issueTrackerMultiSelect,

    },
    mutations: {
        resetRootStore(state: any) {
            _.forEach(initialState, (value, key) => {
                state[key] = _.cloneDeep(initialState[key]);
            });
        },
    },
});
