










































































































import _ from 'lodash';
import { Component, Emit, Prop, Watch } from 'vue-property-decorator';
import { TranslateResult } from 'vue-i18n';
import { MULTIPLE } from '@/constants/Common';
import VuetifyElement from '@/components/common/VuetifyElement.vue';
import WsTruncateAuto from '@/components/common/WsTruncateAuto.vue';

// https://vuetifyjs.com/en/api/v-select/#props

@Component({
    components: {
        WsTruncateAuto,
    },
})
export default class WsSelect extends VuetifyElement {
    @Prop({ required: true, default: () => [] }) public items!: any[] | { [key: string]: any }; // можно закидывать объекты, обработаются только значения, без ключей
    @Prop({ default: () => [] }) public disabledValues!: any[];
    @Prop() public translationKey!: string;
    @Prop({ default: 'text' }) public itemText!: string | ((item: any) => TranslateResult);
    @Prop({ default: 'value' }) public itemValue!: string;
    @Prop({ default: 'disabled' }) public itemDisabled!: any;
    @Prop({ type: Boolean, default: false }) public multiple!: boolean;
    @Prop({ type: Boolean, default: false }) public rounded!: boolean;
    @Prop({ type: Boolean, default: false }) public returnObject!: boolean;
    @Prop({ type: Boolean, default: false }) public hideSelected!: boolean;
    @Prop({ type: Boolean, default: false }) public searchable!: boolean;
    @Prop({ type: Boolean, default: false }) public allowSelectAll!: boolean;
    @Prop({ type: Boolean, default: false }) public showSelectionSlot!: boolean;
    @Prop({ type: Boolean, default: false }) public autofocus!: boolean;
    @Prop({ type: String }) public selectDataTest!: string;
    @Prop({ type: String }) public selectedItemDataTest!: string;
    @Prop({ type: String }) public labelDataTest!: string;
    @Prop({ type: String }) public itemsListDataTest!: string;
    @Prop({ type: String }) public clearButtonDataTest!: string;
    @Prop({ type: Number }) public truncateLength!: number;
    @Prop({ type: Number, default: 200 }) public selectLimit!: number;
    @Prop({ type: Boolean, default: false }) public new!: boolean;
    @Prop({ type: Boolean, default: false }) public small!: boolean;
    @Prop({ type: Boolean, default: false }) public addMultipleOption!: boolean;
    @Prop() public appendIcon!: string;
    @Prop({ required: false }) public attach!: boolean;

    public searchString: string = '';
    public headerKey: string = 'header';
    public isItemsListDataTestAdded: boolean = false;
    public isSelectDataTestAdded: boolean = false;

    get isNew() {
        return this.new;
    }

    get spacing() {
        if (this.small) {
            return 'small';
        }
        
        return 'medium';
    }

    get icon() {
        if (this.appendIcon) {
            return this.appendIcon;
        }
        
        return 'mdi-menu-down';
    }

    get localItems() {
        const items = _.isPlainObject(this.items) ? Object.values(this.items) : this.items;
        const multipleItem = { text: this.$t('Collocation.multiple'), value: MULTIPLE };

        if (this.translationKey) {
            const optionsWithTranslations = items.map((item: any) => {
                const value = (typeof item === 'object') ? item[this.itemValue] : item;
                const partOfKey = (typeof item === 'object') ? this.getItemText(item) : item;
                const text = item.value === MULTIPLE ? item.text : this.$t(`${this.translationKey}.${partOfKey }`);
                const disabled = item?.disabled ?? this.disabledValues.includes(value);

                return {
                    disabled,
                    text,
                    value,
                };
            });

            if (this.addMultipleOption) {
                return [...optionsWithTranslations, multipleItem];
            } else {
                return optionsWithTranslations;
            }
        }

        if (this.addMultipleOption) {
            return [...items as any[], multipleItem];
        }
        
        return items;
    }

    get localItemsFiltered() {
        if (!this.searchable || !this.searchString) {
            return this.localItems;
        }
        const toLowerTrim = (str: string) => {
            return str.toLowerCase().trim();
        };
        const normalizedSearch = toLowerTrim(this.searchString);
        return this.localItems.filter((item: any) => {
            const normalizedItemText = toLowerTrim(this.getItemText(item) || item[this.headerKey]);
            return normalizedItemText.includes(normalizedSearch);
        });
    }

    get allSelected() {
        return (this.model?.length === this.localItemsFiltered?.length) && (this.localItemsFiltered.length > 0);
    }

    get someSelected() {
        return this.model?.length > 0 && !this.allSelected;
    }

    @Emit()
    public click() {}

    @Watch('model')
    public onChangeModel(value: any) {
        if (value) {
            this.addDataTestToFirstSelectedItem();
            this.addDataTestToClearButton();
        }
    }

    public mounted() {
        if (this.selectDataTest && !this.isSelectDataTestAdded) {
            this.addDataTestToSelect();
        }

        if (this.itemsListDataTest && !this.isItemsListDataTestAdded) {
            this.addDataTestToItemsList();
        }

        if (this.labelDataTest) {
            this.addDataTestToLabel();
        }

        if (this.selectedItemDataTest) {
            this.addDataTestToFirstSelectedItem();
        }
    }

    public updateTestAttributes() {
        this.click();
        if (this.itemsListDataTest && !this.isItemsListDataTestAdded) {
            this.addDataTestToItemsList();
        }
    }

    public toggleSelectAll() {
        this.$nextTick(() => {
            if (this.allSelected) {
                this.model = [];
            } else {
                this.model = this.localItemsFiltered.map((item: any) => item[this.itemValue]);
            }
        });
    }

    public async blurSearchString() {
        await this.$nextTick();
        this.searchString = '';
    }

    public addDataTestToItemsList() {
        this.$nextTick(() => {
            const vSelectEl = this.$refs.vselect as Vue;
            const menuEl = vSelectEl?.$refs?.menu as Vue;
            const itemsListEl = menuEl?.$refs?.content as HTMLDivElement;
            const listBoxEl = itemsListEl?.firstChild as HTMLDivElement;
            if (!listBoxEl) {
                return;
            }

            listBoxEl.dataset.test = this.itemsListDataTest;
            this.isItemsListDataTestAdded = true;
        });
    }

    public addDataTestToSelect() {
        this.$nextTick(() => {
            const vSelectButtonEl = this.$el.querySelector('[role=button]') as HTMLDivElement;
            if (!vSelectButtonEl) {
                return;
            }

            vSelectButtonEl.dataset.test = this.selectDataTest;
            this.isSelectDataTestAdded = true;
        });
    }

    public addDataTestToLabel() {
        const vSelectEl = this.$refs.vselect as Vue;
        const labelEl = vSelectEl.$refs.label as HTMLDivElement;

        if (!labelEl) {
            return;
        }

        labelEl.dataset.test = this.labelDataTest;
    }

    public addDataTestToFirstSelectedItem() {
        this.$nextTick(() => {
            const selectionEl = this.$el.querySelector('.v-select__selection') as HTMLDivElement;
            if (!selectionEl) {
                return;
            }

            selectionEl.dataset.test = this.selectedItemDataTest;
        });
    }

    public addDataTestToClearButton() {
        this.$nextTick(() => {
            const clearButtonDivEl = this.$el.querySelector('.v-input__icon--clear ') as HTMLDivElement;
            if (!clearButtonDivEl) {
                return;
            }

            const buttonEl = clearButtonDivEl.firstChild as HTMLButtonElement;
            buttonEl.dataset.test = this.clearButtonDataTest;
        });
    }

    public getItemText(item: any) {
        if (typeof this.itemText === 'function') {
            return this.itemText(item);
        }

        if (_.isString(item)) {
            return item;
        }

        return item[this.itemText];
    }
}
