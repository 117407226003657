import { AxiosPromise } from 'axios';
import { IntegratedServices } from '@/types/User';
import { CookieName } from '@/constants';
import { IActiveSessions, INotificationsParams, IUserSettings } from '@/models';
import { CookieHelper } from '@/services';
import http from './http';
export default class UserApi {
    /**
     * Получение данных о пользователе
     */
    public static getUserSettings(): Promise<any> {
        return http.get('user/data', { source: 'web' }).then((response: any) => response.data);
    }

    /**
     * Сохранение данных о пользователе
     */
    public static postUserSettings(params: IUserSettings): Promise<any> {
        return http.post('user/edit', params).then((response) => response.data);
    }

    /**
     * Сохранение аватара пользователя
     */
    public static postUserAvatar(file: File): Promise<any> {
        return http.post('user/upload/avatar', { file }, true, true).then((response) => response.data);
    }

    /**
     * Сброс пароля
     */
    public static getForgotPassword(email: string): Promise<any> {
        return http.get('user/reset', { email }).then((response) => response.data);
    }

    /**
     * Проверка авторизован ли пользователь
     */
    public static getAuthCheck(): Promise<any> {
        return http.get('auth/check').then((response) => response.data);
    }

    public static postUserLicenses(params: any) {
        return http.post('user/forgot-licenses', params, false).then((response) => response.data);
    }

    public static postChangePassword(params: any) {
        return http.post('user/snp', params, false, true).then((response) => response.data);
    }

    public static postCheckSNPKey(params: any) {
        return http.post('user/snp/check-key', params, false).then((response) => response.data);
    }

    public static postLogout() {
        return http.post('auth/withdraw');
    }

    /**
     * Получение глобальных настроек уведомлений для проектов
     */
    public static getGlobalNotifications() {
        return http.get('user/issueNotifications').then((response) => response.data);
    }

    /**
     * Сохранение глобальных настроек уведомлений для проектов
     */
    public static postGlobalNotifications(params: INotificationsParams) {
        const stringParams = JSON.stringify(params);
        return http.post('user/issueNotifications', { fields: stringParams }).then((response) => response.data);
    }

    /**
     * Получение пользовательских настроек уведомлений для конкретного проекта
     */
    public static getProjectNotifications(projectId: number) {
        return http.get(`user/issueNotifications/${projectId}`).then((response) => response.data);
    }

    /**
     * Сохранение пользовательских настроек уведомлений для конкретного проекта
     */
    public static postProjectNotifications(projectId: number, params: INotificationsParams) {
        const stringParams = JSON.stringify(params);
        return http.post(`user/issueNotifications/${projectId}`, { fields: stringParams }).then((response) => response.data);
    }

    /**
     * Удаление пользовательских настроек уведомлений для конкретного проекта
     */
    public static deleteProjectNotifications(projectId: number) {
        return http.post(`user/issueNotifications/${projectId}/delete`);
    }

    /**
     * Изменение/установка двухфакторной аутентификации
     */
    public static postChangeTwoFA(params: any) {
        return http.post('user/2fa/change', params).then((response) => response.data);
    }

    /**
     * Подтверждение изменения двухфакторной аутентификации
     */
    public static postConfirmTwoFA(params: any) {
        return http.post('user/2fa/confirm', params).then((response) => response.data);
    }

    /** Переотправка кода двухфакторной аутентификации */
    public static postResendCodeTwoFA(params: any) {
        return http.post('auth/2fa/resend', params);
    }

    /** Изменение метода двухфакторной аутентификации на емейл */
    public static postChangeTwoFAMethodToEmail(params: any) {
        return http.post('auth/2fa/one_time', { ...params, source: 'web' })
            .then((response) => response.data);
    }

    /** Проверка данных регистрируемого пользователя */
    public static postCheckInvite(params: any) {
        return http.post('user/invite/check', params, false).then((response) => response.data);
    }

    /** Регистрация нового пользователя */
    public static postRegister(params: any, token: string) {
        return http.post('user/register' + (token ? '/' + token : ''), params).then((response) => response.data);
    }

    /** Загрузка новостей компании Revizto */
    public static getCompanyNews() {
        return http.get('news');
    }

    /** Getting data on active sessions */
    public static getActiveSessions(): Promise<IActiveSessions> {
        return http.get('sessions/data').then((response) => response.data);
    }

    /** Deleting an active session */
    public static postDeleteSession(params: any): Promise<any> {
        return http.post('sessions/delete', params);
    }

    /** Getting SSO code via email */
    public static getSendSSOCode(params: { email: string; language: string }, appRegionId?: string | undefined): AxiosPromise {
        return http.get('auth/send-sso-code', params, undefined, appRegionId);
    }

    /** Getting SSO licenses to log in to */
    public static getSendSSOLicenses(params: { email: string; code: string }, appRegionId?: string | undefined): Promise<any> {
        return http
            .get('auth/sso-licenses', params, undefined, appRegionId)
            .then((response) => response.data);
    }

    public static getUserAccessCode(
        proxyQuery: any,
        deviceId: string,
    ): Promise<any> {
        const data = {
            device_id: deviceId,
            ...proxyQuery,
        };

        return http.get('user/access-code', data).then(({ data }) => {
            CookieHelper.remove(CookieName.loginAccessCodeData);
            const link = data?.link;

            if (link) {
                location.href = link;
            }
            return data;
        });
    }

    /** Get lits of user connections */
    public static getUserConnection(): Promise<any> {
        return http.get('user/oauth-connections').then((response) => response.data);
    }

    /** logout user from service */
    public static logoutUserFromService(service: IntegratedServices, params?: any): Promise<any> {
        return http.post(`user/oauth-connections/${service}/delete`, params).then((response) => response.data);
    }

    /** get list of asite regions */
    public static getAsiteRegions(): Promise<any> {
        return http.get('asite/regions').then((response) => response.data);
    }

    /** get list of timezones*/
    public static getTimezones(): Promise<any> {
        return http.get('user/timezones').then((response) => response.data);
    }
}
