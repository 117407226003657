
import _ from 'lodash';
import { Component, Emit, Prop } from 'vue-property-decorator';
import VueI18n from 'vue-i18n';
import EventListenersBase from '@/components/common/EventListenersBase.vue';

// базовый класс для наследования вуетифайных элементов

@Component
export default class VuetifyElement extends EventListenersBase {
    @Prop({ required: true }) public value!: any;
    @Prop({ default: 2 }) public maxMessages!: number;
    @Prop({ type: Boolean, default: true }) public outlined!: boolean;
    @Prop({ type: Boolean, default: true }) public dense!: boolean;
    @Prop({ type: Boolean, default: false }) public error!: boolean;
    @Prop({ type: Boolean, default: false }) public required!: boolean;
    @Prop({ type: Boolean, default: false }) public clearable!: boolean;
    @Prop({ type: Boolean, default: false }) public disabled!: boolean;
    @Prop({ type: Boolean, default: false }) public persistentHint!: boolean;
    @Prop({ type: Boolean, default: false }) public hideDetails!: boolean | string;
    @Prop() public label!: VueI18n.TranslateResult;
    @Prop() public placeholder!: VueI18n.TranslateResult;
    @Prop() public messages!: VueI18n.TranslateResult[] | VueI18n.TranslateResult;
    @Prop() public hint!: VueI18n.TranslateResult[] | VueI18n.TranslateResult;
    @Prop() public errorMessages!: VueI18n.TranslateResult[] | VueI18n.TranslateResult;
    @Prop() public successMessages!: VueI18n.TranslateResult[] | VueI18n.TranslateResult;
    @Prop() public rules!: any[];

    get model() {
        return this.value;
    }
    set model(value: any) {
        if (_.isNil(value)) {
            value = '';
        }
        this.input(value);
    }

    @Emit()
    public input(value: any) {
        return value;
    }

    @Emit()
    public change(value: any) {
        return value;
    }

    @Emit()
    public focus(value: any): void {
        return value;
    }

    @Emit()
    public blur(value: any) {
        return value;
    }
}
